import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import BackIcon from './back.svg';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './header.scss';
var Header = function (_a) {
    var dataHook = _a.dataHook, onBackButtonClick = _a.onBackButtonClick;
    return (React.createElement("div", { "data-hook": dataHook, className: styles.title, dir: "ltr" },
        React.createElement("a", { href: "#", "data-hook": "back-button", className: styles['back-button'], onClick: onBackButtonClick, "aria-label": i18n.t('widget.accessibility.close-embed-form') },
            React.createElement(BackIcon, null)),
        React.createElement("span", { className: styles['title-text'] }, i18n.t('share-overlay.embed.video.title'))));
};
Header.propTypes = {
    dataHook: PropTypes.string,
    onBackButtonClick: PropTypes.func,
};
export default withStyles(styles)(Header);
