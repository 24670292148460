import { isCommentsEnabled, isCommentsEnabledForLive, } from '../../shared/selectors/app-settings';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { isDemoChannel } from '../../shared/utils/channel-helpers';
import { isExternalVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import { isStreamingLive, isScheduledLive, } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';
export var canShowComments = function (state, videoItem, channelData, currentSiteUser) {
    return ((!isStreamingLive(videoItem) && isCommentsEnabled(state)) ||
        (isStreamingLive(videoItem) && isCommentsEnabledForLive(state))) &&
        !isDemoChannel(channelData.id) &&
        !isExternalVideo(videoItem) &&
        !isScheduledLive(videoItem) &&
        canAccessFullVideo(channelData, videoItem, currentSiteUser);
};
export var isCommentsOpen = function (state) { return state.comments.isOpen; };
export var shouldOpenCommentsOnLoad = function (state) { return state.comments.openOnLoad; };
export var hasNewComments = function (state) { return state.comments.hasNewComments; };
