import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PAYMENT_MESSAGE_PROXY from '../../constants/payment-message-proxy';
import EVENTS from '../../constants/events';
import { getCompId } from '../../../widget/redux/client/hydrated-data/hydrated-data';
import { consumePubSubEvent, withPubSubEvents, } from '../../../widget/containers/pub-sub-events';
var PaymentEvents = /** @class */ (function (_super) {
    __extends(PaymentEvents, _super);
    function PaymentEvents() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handlePaymentMessage = function (_a) {
            var _b;
            var data = _a.data;
            var type = _.get(data, 'message.type');
            var isSubscriptionCancel = type === PAYMENT_MESSAGE_PROXY.TYPES.SUBSCRIPTION_CANCEL;
            if (_.get(data, 'compId') !== _this.props.compId && !isSubscriptionCancel) {
                return;
            }
            var paymentMessage = data.message;
            var handler = (_b = {},
                _b[PAYMENT_MESSAGE_PROXY.TYPES.SALE] = _this.handleSale,
                _b[PAYMENT_MESSAGE_PROXY.TYPES.RENT] = _this.handleRent,
                _b[PAYMENT_MESSAGE_PROXY.TYPES.SUBSCRIPTION] = _this.handleSubscription,
                _b[PAYMENT_MESSAGE_PROXY.TYPES.SUBSCRIPTION_CANCEL] = _this
                    .handleSubscriptionCancel,
                _b)[paymentMessage.type];
            if (handler) {
                handler(paymentMessage);
            }
        };
        _this.handleSale = function (message) {
            var _a = _this.props, onSale = _a.onSale, onAnyEvent = _a.onAnyEvent;
            if (message.completed) {
                onAnyEvent(message);
                onSale(message);
            }
        };
        _this.handleRent = function (message) {
            var _a = _this.props, onRent = _a.onRent, onAnyEvent = _a.onAnyEvent;
            if (message.completed) {
                onAnyEvent(message);
                onRent(message);
            }
        };
        _this.handleSubscription = function (message) {
            var _a = _this.props, onSubscription = _a.onSubscription, onAnyEvent = _a.onAnyEvent;
            if (message.completed) {
                onAnyEvent(message);
                onSubscription(message);
            }
        };
        _this.handleSubscriptionCancel = function (message) {
            var _a = _this.props, onSubscriptionCancel = _a.onSubscriptionCancel, onAnyEvent = _a.onAnyEvent;
            if (message.completed) {
                onAnyEvent(message);
                onSubscriptionCancel(message);
            }
        };
        _this.handleUserLogin = function () {
            var _a = _this.props, onAnyEvent = _a.onAnyEvent, onLogin = _a.onLogin;
            onAnyEvent();
            onLogin();
        };
        return _this;
    }
    PaymentEvents.prototype.componentDidUpdate = function (prevProps) {
        consumePubSubEvent(EVENTS.PAYMENT.PAYMENT_EVENT, this.handlePaymentMessage, {
            prevProps: prevProps,
            nextProps: this.props,
        });
        consumePubSubEvent(EVENTS.SESSION.LOGIN_USER, this.handleUserLogin, {
            prevProps: prevProps,
            nextProps: this.props,
        });
    };
    PaymentEvents.prototype.render = function () {
        return null;
    };
    PaymentEvents.propTypes = {
        onSale: PropTypes.func,
        onRent: PropTypes.func,
        onSubscription: PropTypes.func,
        onSubscriptionCancel: PropTypes.func,
        onAnyEvent: PropTypes.func,
        onLogin: PropTypes.func,
    };
    PaymentEvents.defaultProps = {
        onSale: _.noop,
        onRent: _.noop,
        onSubscription: _.noop,
        onSubscriptionCancel: _.noop,
        onAnyEvent: _.noop,
        onLogin: _.noop,
    };
    PaymentEvents = __decorate([
        withPubSubEvents,
        connect(function (state) { return ({ compId: getCompId(state) }); })
    ], PaymentEvents);
    return PaymentEvents;
}(React.Component));
export default PaymentEvents;
