import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MobileCarousel from './mobile-carousel/mobile-carousel';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import firstTimeTrackAnimation from './first-time-track-animation/first-time-track-animation';
import { getVideosGroupedByIds } from '../../../../shared/selectors/videos';
import { isRTL } from '../../../../shared/selectors/app-settings';
import { getCurrentSiteUser } from '../../../../shared/selectors/current-site-user';
import { shouldFetchUrls } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/fetch-urls';
import { getVideoIds } from '../../../../widget/redux/client/lazy-channel-videos/selectors';
import { fetchVideoUrls } from '../../../../widget/redux/client/actions/video-urls/fetch';
var SlidableListWithLazyLoad = /** @class */ (function (_super) {
    __extends(SlidableListWithLazyLoad, _super);
    function SlidableListWithLazyLoad() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.afterChange = function (leftVisibleSlideIndex) {
            _this.props.onCurrentSlideChanged(leftVisibleSlideIndex);
        };
        return _this;
    }
    SlidableListWithLazyLoad.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        this.preloadUrls(nextProps.videoByIds);
    };
    SlidableListWithLazyLoad.prototype.preloadUrls = function (videos) {
        var _this = this;
        _.forEach(videos, function (videoItem) { return _this.fetchUrlsForVideo(videoItem); });
    };
    SlidableListWithLazyLoad.prototype.fetchUrlsForVideo = function (videoItem) {
        var _a = this.props, fetchVideoUrls = _a.fetchVideoUrls, currentSiteUser = _a.currentSiteUser, channelData = _a.channelData;
        if (shouldFetchUrls({ channelData: channelData, videoItem: videoItem, currentSiteUser: currentSiteUser })) {
            fetchVideoUrls(videoItem);
        }
    };
    SlidableListWithLazyLoad.prototype.getInitialIndex = function () {
        var _a = this.props, videoIds = _a.videoIds, initialVideoId = _a.initialVideoId;
        var selectedVideoIndex = _.indexOf(videoIds, initialVideoId);
        return selectedVideoIndex < 0 ? 0 : selectedVideoIndex;
    };
    SlidableListWithLazyLoad.prototype.render = function () {
        var _a = this.props, className = _a.className, itemHeight = _a.itemHeight, trackClassName = _a.trackClassName, onUserStartedTracking = _a.onUserStartedTracking, children = _a.children, windowSize = _a.windowSize, isRTL = _a.isRTL;
        return (React.createElement(MobileCarousel, { width: windowSize.width, afterChange: this.afterChange, beforeChange: this.props.beforeCurrentSlideChanged, getHeight: _.constant(itemHeight), onUserStartedTracking: onUserStartedTracking, slidesToShow: 1, trackClassName: trackClassName, className: className, initialSlide: this.getInitialIndex(), isRTL: isRTL }, children));
    };
    SlidableListWithLazyLoad.propTypes = {
        videoIds: PropTypes.array,
        videoByIds: PropTypes.object,
        channelData: PropTypes.object,
        currentSiteUser: PropTypes.object,
        initialVideoId: PropTypes.string,
        className: PropTypes.string,
        trackClassName: PropTypes.string,
        itemHeight: PropTypes.number,
        windowSize: PropTypes.object,
        onUserStartedTracking: PropTypes.func,
        onCurrentSlideChanged: PropTypes.func,
        fetchVideoUrls: PropTypes.func,
        beforeCurrentSlideChanged: PropTypes.func,
        isRTL: PropTypes.bool,
        children: PropTypes.arrayOf(PropTypes.element),
    };
    SlidableListWithLazyLoad.defaultProps = {
        onCurrentSlideChanged: _.noop,
    };
    SlidableListWithLazyLoad = __decorate([
        connect(function (state) { return ({
            videoIds: getVideoIds(state),
            videoByIds: getVideosGroupedByIds(state),
            currentSiteUser: getCurrentSiteUser(state),
            channelData: getChannelForWidget(state),
            windowSize: state.windowSize,
            isRTL: isRTL(state),
        }); }, {
            fetchVideoUrls: fetchVideoUrls,
        }),
        firstTimeTrackAnimation
    ], SlidableListWithLazyLoad);
    return SlidableListWithLazyLoad;
}(React.Component));
export default SlidableListWithLazyLoad;
