import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestPause } from '../../../shared/redux/client/reducers/playback';
import { setHydratedData } from '../../../widget/redux/client/hydrated-data/hydrated-data';
import { ViewModeObserver } from '../../../widget/containers/view-mode-observer';
import * as viewModeSelectors from '../../../widget/selectors/view-mode';
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
var EditorObserver = /** @class */ (function (_super) {
    __extends(EditorObserver, _super);
    function EditorObserver() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleViewModeChange = function (_a) {
            var prevViewMode = _a.prevViewMode, viewMode = _a.viewMode;
            var _b = _this.props, requestPause = _b.requestPause, selectedVideoId = _b.selectedVideoId;
            if (prevViewMode === VIEW_MODES.PREVIEW && viewMode !== prevViewMode) {
                requestPause(selectedVideoId);
            }
        };
        return _this;
    }
    EditorObserver.prototype.render = function () {
        return React.createElement(ViewModeObserver, { onChange: this.handleViewModeChange });
    };
    EditorObserver.propTypes = {
        setHydratedData: PropTypes.func.isRequired,
        requestPause: PropTypes.func.isRequired,
        viewMode: PropTypes.string.isRequired,
        selectedVideoId: PropTypes.string,
    };
    EditorObserver = __decorate([
        connect(function (state) { return ({
            viewMode: viewModeSelectors.getViewMode(state),
            selectedVideoId: state.selectedVideoId,
        }); }, {
            requestPause: requestPause,
            setHydratedData: setHydratedData,
        })
    ], EditorObserver);
    return EditorObserver;
}(React.Component));
export default EditorObserver;
