var _a;
/* eslint-disable react/prop-types */
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { getAvailableVideoType, AVAILABLE_VIDEO_TYPES, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
export var DEFAULT_TRANSLATIONS = (_a = {},
    _a[AVAILABLE_VIDEO_TYPES.FULL] = 'widget.overlay-buttons.play-video',
    _a[AVAILABLE_VIDEO_TYPES.TRAILER] = 'widget.overlay-buttons.watch-trailer',
    _a[AVAILABLE_VIDEO_TYPES.FIRST_20_SEC] = 'widget.overlay-buttons.watch-preview',
    _a[AVAILABLE_VIDEO_TYPES.LIVE_STREAMING] = 'widget.overlay-buttons.watch-live',
    _a);
/**
 * Is channel play button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.currentSiteUser {Object}
 * @return {Boolean}
 */
export function isVisible(_a) {
    var channel = _a.channel, video = _a.video, currentSiteUser = _a.currentSiteUser;
    return Boolean(getAvailableVideoType(channel, video, currentSiteUser));
}
/**
 * Channel play button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.currentSiteUser {Object}
 * @param props.translations {Object} see DEFAULT_TRANSLATIONS
 * @return {React.Component}
 */
export default function renderPlayButtonText(_a) {
    var channel = _a.channel, video = _a.video, currentSiteUser = _a.currentSiteUser, _b = _a.translations, translations = _b === void 0 ? DEFAULT_TRANSLATIONS : _b;
    var type = getAvailableVideoType(channel, video, currentSiteUser);
    if (!type) {
        return null;
    }
    return i18n.t(translations[type]);
}
