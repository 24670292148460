import { __decorate, __extends, __read, __spreadArray } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { logBi } from '../../../shared/worker/actions/bi';
import { connect } from 'react-redux';
import { WidgetDropdown } from '../../../shared/components/dropdown/dropdown';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from './categories.scss';
var Categories = /** @class */ (function (_super) {
    __extends(Categories, _super);
    function Categories() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getOptions = _.memoize(function (list) {
            var options = _.map(list, function (value) { return ({ label: value, value: value }); });
            return __spreadArray([
                {
                    label: i18n.t('widget.categories.all-videos'),
                    value: '',
                }
            ], __read(options), false);
        });
        _this.sendCategoriesClickedBI = function () {
            _this.props.logBi('widget.categories.clicked');
        };
        _this.handleChange = function (value) {
            _this.sendCategorySelectedBI();
            _this.props.onCategorySelect(value);
        };
        return _this;
    }
    Categories.prototype.sendCategorySelectedBI = function () {
        this.props.logBi('widget.categories.selected');
    };
    Categories.prototype.render = function () {
        var _a = this.props, className = _a.className, selectedCategory = _a.selectedCategory, DropdownClass = _a.DropdownClass, maxOptionsVisible = _a.maxOptionsVisible, height = _a.height, list = _a.list, isFocusable = _a.isFocusable;
        return (React.createElement(DropdownClass, { className: "".concat(className, " qa-widget-categories"), dataHook: "categories-dropdown", optionsClassName: styles.options, optionClassName: styles.option, height: height, maxOptionsVisible: maxOptionsVisible, isFocusable: isFocusable, onChange: this.handleChange, onOpen: this.sendCategoriesClickedBI, value: selectedCategory || '', options: this.getOptions(list), ariaLabel: i18n.t('widget.accessibility.select-category') }));
    };
    Categories.propTypes = {
        className: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.string),
        onCategorySelect: PropTypes.func,
        selectedCategory: PropTypes.string,
        height: PropTypes.number,
        isFocusable: PropTypes.bool,
        DropdownClass: PropTypes.func,
        maxOptionsVisible: PropTypes.number,
    };
    Categories.defaultProps = {
        selectedCategory: null,
        DropdownClass: WidgetDropdown,
    };
    Categories = __decorate([
        connect(null, { logBi: logBi }),
        withStyles(styles)
    ], Categories);
    return Categories;
}(React.Component));
export default Categories;
