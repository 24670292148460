import _ from 'lodash';
import { PaymentType } from '@wix/wix-vod-constants/dist/api-types/enums';
import CLOSE_REASONS from '../../full-screen-modals/constants/close-reasons';
import { openFullScreenOverlay, openMobileOverlay, } from '../worker/actions/openOverlay';
export { openFullScreenOverlay, openMobileOverlay };
export function openFullScreenSubscribeOverlay(channelId, onCloseByUser, isPricingPlan) {
    if (onCloseByUser === void 0) { onCloseByUser = _.noop; }
    var paymentType = isPricingPlan
        ? PaymentType.PLAN
        : PaymentType.SUBSCRIPTION;
    return openFullScreenOverlay("#/payment/".concat(paymentType, "/").concat(channelId), function (reason) {
        if (reason === CLOSE_REASONS.CLOSED_BY_USER) {
            onCloseByUser();
        }
    });
}
function openPaymentOverlay(channelId, videoId, paymentType) {
    var route = channelId
        ? "#/payment/".concat(paymentType, "/").concat(channelId, "/").concat(videoId) // channel video
        : "#/payment/".concat(paymentType, "/video/").concat(videoId); // single video
    return openFullScreenOverlay(route);
}
export function openFullScreenPurchaseOverlay(channelId, videoId) {
    return openPaymentOverlay(channelId, videoId, PaymentType.PURCHASE);
}
export function openFullScreenRentOverlay(channelId, videoId) {
    return openPaymentOverlay(channelId, videoId, PaymentType.RENT);
}
export function openFullScreenMemberOnlyOverlay(channelId, videoId) {
    return openPaymentOverlay(channelId, videoId, PaymentType.MEMBER_ONLY);
}
export function openFullScreenChannelOverlay(channelId) {
    return openFullScreenOverlay("#/channel/".concat(channelId, "/info"));
}
export function openFullScreenShareOverlay(channelId, videoId) {
    var baseUrl = "#/channel/".concat(channelId, "/share/");
    return openFullScreenOverlay(videoId ? "".concat(baseUrl).concat(videoId) : baseUrl);
}
