import { __awaiter, __generator } from "tslib";
import { PrivateSettings } from '@wix/wix-vod-gql-api/dist/src/private/settings/settings';
import { createClient } from '@wix/wix-vod-gql-api/dist/src/client/client';
import { PRIVATE_API_URL } from '@wix/wix-vod-gql-api/dist/src/constants';
import WixSDK from '../../../shared/utils/wix-sdk';
export function getSettings() {
    return __awaiter(this, void 0, void 0, function () {
        var privateClient, privateSettings;
        return __generator(this, function (_a) {
            privateClient = createClient(PRIVATE_API_URL, {
                get headers() {
                    return {
                        Authorization: WixSDK.Utils.getInstance(),
                    };
                },
            });
            privateSettings = new PrivateSettings(privateClient);
            return [2 /*return*/, privateSettings.get()];
        });
    });
}
