import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import decorate from '../../../../utils/decorate';
import { notForPreview } from '../../../../../shared/utils/not-for-preview';
import { selectVideo } from '../../../../redux/client/actions/select-video';
import { openFullScreenVideoOverlay, closeFullScreenVideoOverlay, } from '../../../../redux/client/actions/full-screen-modal';
import InFrameActions from './in-frame';
// In Expanded View mode click on a thumbnail should select video in widget, not show overlay.
// Overlay is only used to play videos in compact layout.
var InOverlayActionCallbacks = /** @class */ (function (_super) {
    __extends(InOverlayActionCallbacks, _super);
    function InOverlayActionCallbacks() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showVideoAtOverlay = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoId = _a.videoId, openFullScreenVideoOverlay = _a.openFullScreenVideoOverlay, closeFullScreenVideoOverlay = _a.closeFullScreenVideoOverlay;
            openFullScreenVideoOverlay(channelId, videoId, null, closeFullScreenVideoOverlay);
        });
        _this.playVideoAtOverlay = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoId = _a.videoId, openFullScreenVideoOverlay = _a.openFullScreenVideoOverlay, closeFullScreenVideoOverlay = _a.closeFullScreenVideoOverlay;
            _this.props.onPlayRequestedBi();
            openFullScreenVideoOverlay(channelId, videoId, true, closeFullScreenVideoOverlay);
        });
        return _this;
    }
    InOverlayActionCallbacks.prototype.render = function () {
        return React.cloneElement(this.props.children, __assign(__assign({}, this.props), { onPlayRequest: this.playVideoAtOverlay, onPlayWithoutPreviewRequest: this.showVideoAtOverlay }));
    };
    InOverlayActionCallbacks.propTypes = {
        children: PropTypes.element.isRequired,
        channelId: PropTypes.string,
        videoId: PropTypes.string,
        selectVideo: PropTypes.func,
        onSelectOrPlay: PropTypes.func,
        onPlayRequestedBi: PropTypes.func,
        openFullScreenVideoOverlay: PropTypes.func,
        closeFullScreenVideoOverlay: PropTypes.func,
        videoItem: PropTypes.object,
    };
    InOverlayActionCallbacks.defaultProps = {
        onPlayRequestedBi: _.noop,
    };
    InOverlayActionCallbacks = __decorate([
        connect(null, {
            selectVideo: selectVideo,
            openFullScreenVideoOverlay: openFullScreenVideoOverlay,
            closeFullScreenVideoOverlay: closeFullScreenVideoOverlay,
        }),
        decorate(InFrameActions)
    ], InOverlayActionCallbacks);
    return InOverlayActionCallbacks;
}(React.Component));
export default InOverlayActionCallbacks;
