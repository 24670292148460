var _a;
import _ from 'lodash';
import { REQUEST_LOGIN_MODES } from '../constants/request-login-modes';
import { logWidgetSystem } from '../worker/actions/bi';
import { getLocale } from '../../widget/redux/client/hydrated-data/hydrated-data';
import { requestLogin as requestLoginAction } from '../worker/actions/user/request-login';
import { logout } from '../worker/actions/user/logout';
import { createProxy, createAsyncProxy } from '../worker/lib';
var systemEventsPerLoginMode = (_a = {},
    _a[REQUEST_LOGIN_MODES.SIGN_UP] = {
        requested: 'user.signup.requested',
        succeeded: 'user.signup.succeeded',
        cancelled: 'user.signup.cancelled',
    },
    _a[REQUEST_LOGIN_MODES.LOGIN] = {
        requested: 'user.logIn.requested',
        succeeded: 'user.logIn.succeeded',
        cancelled: 'user.logIn.cancelled',
    },
    _a);
export var requestAuth = createAsyncProxy('requestAuth', function (isLogin) { return function (dispatch, getState) {
    var mode = isLogin
        ? REQUEST_LOGIN_MODES.LOGIN
        : REQUEST_LOGIN_MODES.SIGN_UP;
    var state = getState();
    var locale = getLocale(state);
    var systemEvents = systemEventsPerLoginMode[mode];
    dispatch(logWidgetSystem(systemEvents.requested));
    return new Promise(function (resolve, reject) {
        var onLogin = function (loggedInUserData) {
            loggedInUserData = loggedInUserData.data || loggedInUserData;
            if (loggedInUserData.id) {
                dispatch(logWidgetSystem(systemEvents.succeeded, {
                    previousEventName: systemEvents.requested,
                }));
                resolve(loggedInUserData);
            }
        };
        var onError = function (error) {
            if (_.get(error, 'wasCancelled')) {
                dispatch(logWidgetSystem(systemEvents.cancelled, {
                    previousEventName: systemEvents.requested,
                }));
                return;
            }
            reject(error);
        };
        dispatch(requestLoginAction({
            language: locale,
            mode: mode,
        }, onLogin, onError));
    });
}; });
export var requestLogin = function () { return requestAuth(true); };
export var requestSignUp = function () { return requestAuth(false); };
export var logOutCurrentMember = createProxy('logOutCurrentMember', function () { return function (dispatch) {
    dispatch(logWidgetSystem('user.logOut.requested'));
    dispatch(logout());
}; });
