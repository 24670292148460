import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PaymentEvents from '../../shared/components/payment-events/payment-events';
import { clearVideoUrlsCacheForAll, clearVideoUrlsCacheForItem, } from '../redux/client/actions/video-urls/clear';
var ClearVideoUrlsCacheOnPayment = /** @class */ (function (_super) {
    __extends(ClearVideoUrlsCacheOnPayment, _super);
    function ClearVideoUrlsCacheOnPayment() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clearVideoUrlsCacheForAll = _.debounce(function () {
            _this.props.clearVideoUrlsCacheForAll();
        }, 300);
        _this.clearVideoUrlsCacheForItem = function (data) {
            _this.props.clearVideoUrlsCacheForItem(data.itemId);
        };
        return _this;
    }
    ClearVideoUrlsCacheOnPayment.prototype.render = function () {
        return (React.createElement(PaymentEvents, { onSubscription: this.clearVideoUrlsCacheForAll, onSubscriptionCancel: this.clearVideoUrlsCacheForAll, onLogin: this.clearVideoUrlsCacheForAll, onRent: this.clearVideoUrlsCacheForItem, onSale: this.clearVideoUrlsCacheForItem }));
    };
    ClearVideoUrlsCacheOnPayment.propTypes = {
        clearVideoUrlsCacheForAll: PropTypes.func,
        clearVideoUrlsCacheForItem: PropTypes.func,
    };
    ClearVideoUrlsCacheOnPayment = __decorate([
        connect(null, {
            clearVideoUrlsCacheForAll: clearVideoUrlsCacheForAll,
            clearVideoUrlsCacheForItem: clearVideoUrlsCacheForItem,
        })
    ], ClearVideoUrlsCacheOnPayment);
    return ClearVideoUrlsCacheOnPayment;
}(React.Component));
export default ClearVideoUrlsCacheOnPayment;
