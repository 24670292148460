import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { VIDEO_EVENTS, LiveState } from 'playable/dist/src/constants';
import { logBi } from '../../../../../../../shared/worker/actions/bi';
import { updateLiveStatus } from '../../../../../../redux/client/actions/videos';
import { ItemType } from '@wix/wix-vod-constants/dist/api-types/enums/item-type';
var LiveVideoStatusUpdater = /** @class */ (function (_super) {
    __extends(LiveVideoStatusUpdater, _super);
    function LiveVideoStatusUpdater() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onLiveStatusChanged = function (_a) {
            var nextState = _a.nextState;
            var _b = _this.props, updateLiveStatus = _b.updateLiveStatus, videoItem = _b.videoItem, channelId = _b.channelId;
            if (nextState === LiveState.ENDED) {
                _this.props.logBi('widget.live.video.ended', {
                    channelID: channelId,
                    videoID: videoItem.id,
                });
                updateLiveStatus(__assign(__assign({}, videoItem), { itemType: ItemType.ItemLiveFinalize }));
            }
        };
        return _this;
    }
    LiveVideoStatusUpdater.prototype.componentDidMount = function () {
        var eventEmitter = this.props.eventEmitter;
        eventEmitter.on(VIDEO_EVENTS.LIVE_STATE_CHANGED, this.onLiveStatusChanged);
    };
    LiveVideoStatusUpdater.prototype.componentWillUnmount = function () {
        var eventEmitter = this.props.eventEmitter;
        eventEmitter.off(VIDEO_EVENTS.LIVE_STATE_CHANGED, this.onLiveStatusChanged);
    };
    LiveVideoStatusUpdater.prototype.render = function () {
        return null;
    };
    LiveVideoStatusUpdater.propTypes = {
        videoItem: PropTypes.object,
        updateLiveStatus: PropTypes.func,
        eventEmitter: PropTypes.object,
        channelId: PropTypes.string,
    };
    LiveVideoStatusUpdater = __decorate([
        connect(null, {
            updateLiveStatus: updateLiveStatus,
            logBi: logBi,
        })
    ], LiveVideoStatusUpdater);
    return LiveVideoStatusUpdater;
}(React.Component));
export default LiveVideoStatusUpdater;
