import _ from 'lodash';
import { createSelector } from 'reselect';
import { showVisitChannel, showSignIn, showSignOut } from './show-actions';
import { subscribeButtonText } from './buttons';
import { canShowChannelShare } from '../../../../shared/selectors/app-settings';
import { canShareVideo } from '../../../../shared/selectors/video-share';
import { isChannelCoverView } from './cover-with-overlay';
export var ALL_MENU_ITEMS = {
    VISIT_CHANNEL: 'VISIT_CHANNEL',
    SUBSCRIBE: 'SUBSCRIBE',
    SIGN_OUT: 'SIGN_OUT',
    SIGN_IN: 'SIGN_IN',
    SHARE_CHANNEL: 'SHARE_CHANNEL',
    SHARE_VIDEO: 'SHARE_VIDEO',
};
var showSubscribe = createSelector(subscribeButtonText, function (text) {
    return Boolean(text);
});
var showShareChannel = createSelector([isChannelCoverView, canShowChannelShare], function (isChannelCoverView, canShowChannelShare) {
    return isChannelCoverView && canShowChannelShare;
});
var showShareVideo = createSelector([isChannelCoverView, canShareVideo], function (isChannelCoverView, canShareVideo) { return !isChannelCoverView && canShareVideo; });
var ITEMS_VISIBILITY = createSelector([
    showVisitChannel,
    showSubscribe,
    showSignOut,
    showSignIn,
    showShareChannel,
    showShareVideo,
], function (showVisitChannel, showSubscribe, showSignOut, showSignIn, showShareChannel, showShareVideo) {
    var _a;
    return (_a = {},
        _a[ALL_MENU_ITEMS.VISIT_CHANNEL] = showVisitChannel,
        _a[ALL_MENU_ITEMS.SUBSCRIBE] = showSubscribe,
        _a[ALL_MENU_ITEMS.SIGN_OUT] = showSignOut,
        _a[ALL_MENU_ITEMS.SIGN_IN] = showSignIn,
        _a[ALL_MENU_ITEMS.SHARE_CHANNEL] = showShareChannel,
        _a[ALL_MENU_ITEMS.SHARE_VIDEO] = showShareVideo,
        _a);
});
export var getVisibleMenuItems = createSelector(ITEMS_VISIBILITY, function (items) {
    return _(items)
        .pickBy(Boolean)
        .keys()
        .value();
});
export var shouldShowMenuButton = createSelector(getVisibleMenuItems, function (visibleItems) { return !_.isEmpty(visibleItems); });
