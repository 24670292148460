import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './no-videos-overlay.scss';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
var NoVideosOverlay = /** @class */ (function (_super) {
    __extends(NoVideosOverlay, _super);
    function NoVideosOverlay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NoVideosOverlay.prototype.render = function () {
        var title = this.props.title;
        return (React.createElement("div", { className: styles.overlay, "data-hook": "no-videos-overlay" },
            React.createElement("div", null,
                React.createElement("div", { className: styles.title }, title),
                React.createElement("div", { className: styles.comingSoon }, i18n.t('widget.overlay-buttons.coming-soon')))));
    };
    NoVideosOverlay.propTypes = {
        title: PropTypes.string,
        dataHook: PropTypes.string,
    };
    NoVideosOverlay = __decorate([
        withStyles(styles)
    ], NoVideosOverlay);
    return NoVideosOverlay;
}(React.Component));
export default NoVideosOverlay;
