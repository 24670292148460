import { __awaiter, __decorate, __extends, __generator } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logBi } from '../../../shared/worker/actions/bi';
import { BI_ORIGIN, BI_VIDEO_TYPE, } from '@wix/wix-vod-shared/dist/src/common/constants/bi';
import { updateLiveStatus } from '../../redux/client/actions/videos';
import { fetchVideoUrls } from '../../redux/client/actions/video-urls/fetch';
import { getCurrentSiteUser } from '../../../shared/selectors/current-site-user';
import { selectVideo } from '../../redux/client/actions/select-video';
import { getChannelVideoById } from '@wix/wix-vod-api/dist/src/public/channel-videos/channel-videos';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isPendingLiveStream, isScheduledLive, } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { isVideoOverlayOpened } from '../../selectors/full-screen-modal';
import { getLiveScheduledVideos, getLiveStreamingVideos, getLivePendingVideos, } from '../../selectors/live-videos';
import LivePolling from '@wix/wix-vod-shared/dist/src/widget/components/live-polling/live-polling';
import * as viewModeSelectors from '../../selectors/view-mode';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import fedopsLogger from '../../../loggers/fedops/new-fedops-logger';
import { publicApiV3 } from '../../../api/v3/public';
var getVideoData = function (id) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var state, video, channel;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = getState();
                if (!isGraphAPIEnabled()) return [3 /*break*/, 2];
                return [4 /*yield*/, publicApiV3.channel.getVideo(id)];
            case 1:
                video = _a.sent();
                return [2 /*return*/, { data: video }];
            case 2:
                channel = getChannelForWidget(state);
                return [2 /*return*/, getChannelVideoById(channel.id, id)];
        }
    });
}); }; };
var LiveStartHandler = /** @class */ (function (_super) {
    __extends(LiveStartHandler, _super);
    function LiveStartHandler() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.autoplayVideo = function (video) {
            var _a = _this.props, playVideo = _a.playVideo, selectVideo = _a.selectVideo, channel = _a.channel, currentSiteUser = _a.currentSiteUser, isVideoOverlayOpened = _a.isVideoOverlayOpened;
            if (_this.isOtherVideoPlaying(video.id) || isVideoOverlayOpened) {
                return;
            }
            if (canAccessFullVideo(channel, video, currentSiteUser)) {
                _this.logAutoplayBi(video);
                playVideo(video);
            }
            else {
                selectVideo(video.id);
            }
        };
        _this.logAutoplayBi = function (video) {
            var _a = _this.props, channel = _a.channel, autoplayLiveInteractionStart = _a.autoplayLiveInteractionStart;
            _this.props.logBi('widget.video.autoplay.start', {
                videoType: BI_VIDEO_TYPE.LIVE,
                isEmbed: false,
                channelID: channel.id,
                videoID: video.id,
                origin: BI_ORIGIN.ON_SITE,
            });
            autoplayLiveInteractionStart();
        };
        _this.logPollBi = function (videoItem, error) {
            _this.props.logBi('widget.poll.request.sent', {
                videoType: videoItem && _this.getVideoType(videoItem),
                isError: Boolean(error),
                errorMessage: _.get(error, 'message', null),
                origin: BI_ORIGIN.ON_SITE,
            });
        };
        _this.isOtherVideoPlaying = function (videoId) {
            var _a = _this.props, isVideoPlaying = _a.isVideoPlaying, selectedVideoId = _a.selectedVideoId;
            return isVideoPlaying && selectedVideoId !== videoId;
        };
        _this.isOtherVideoSelected = function (videoId) {
            var selectedVideoId = _this.props.selectedVideoId;
            return selectedVideoId && selectedVideoId !== videoId;
        };
        _this.canInitiallyAutoplay = function (videoId) {
            return !_this.isOtherVideoSelected(videoId);
        };
        _this.onStreamChangePending = function (videoItem) {
            _this.props.updateLiveStatus(videoItem);
        };
        _this.onStreamChangeReady = function (videoItem) {
            _this.props.updateLiveStatus(videoItem);
            _this.props.fetchVideoUrls(videoItem, true);
        };
        return _this;
    }
    LiveStartHandler.prototype.getVideoType = function (_a) {
        var itemType = _a.itemType, liveVideoStatus = _a.liveVideoStatus;
        var videoType = null;
        if (isPendingLiveStream({ liveVideoStatus: liveVideoStatus })) {
            videoType = BI_VIDEO_TYPE.LIVE;
        }
        else if (isScheduledLive({ itemType: itemType })) {
            videoType = BI_VIDEO_TYPE.SCHEDULED;
        }
        return videoType;
    };
    LiveStartHandler.prototype.render = function () {
        var _a = this.props, scheduledVideos = _a.scheduledVideos, pendingVideos = _a.pendingVideos, streamingVideos = _a.streamingVideos, isPreviewOrEditorMode = _a.isPreviewOrEditorMode;
        if (isPreviewOrEditorMode) {
            return null;
        }
        return (React.createElement(LivePolling, { scheduledVideos: scheduledVideos, pendingVideos: pendingVideos, streamingVideos: streamingVideos, autoplayVideo: this.autoplayVideo, canInitiallyAutoplay: this.canInitiallyAutoplay, onStreamChangePending: this.onStreamChangePending, onStreamChangeReady: this.onStreamChangeReady, onPoll: this.logPollBi, getVideoData: this.props.getVideoData }));
    };
    LiveStartHandler.propTypes = {
        scheduledVideos: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            dateStartLive: PropTypes.number,
        })),
        pendingVideos: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
        })),
        streamingVideos: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
        })),
        channel: PropTypes.object.isRequired,
        currentSiteUser: PropTypes.object,
        updateLiveStatus: PropTypes.func,
        fetchVideoUrls: PropTypes.func,
        playVideo: PropTypes.func,
        selectVideo: PropTypes.func,
        isVideoPlaying: PropTypes.bool,
        isVideoOverlayOpened: PropTypes.bool,
        isPreviewOrEditorMode: PropTypes.bool,
        selectedVideoId: PropTypes.string,
    };
    LiveStartHandler = __decorate([
        connect(function (state) { return ({
            channel: getChannelForWidget(state),
            currentSiteUser: getCurrentSiteUser(state),
            isVideoOverlayOpened: isVideoOverlayOpened(state),
            scheduledVideos: getLiveScheduledVideos(state),
            pendingVideos: getLivePendingVideos(state),
            streamingVideos: getLiveStreamingVideos(state),
            isPreviewOrEditorMode: viewModeSelectors.isPreviewMode(state) ||
                viewModeSelectors.isEditorMode(state),
        }); }, {
            updateLiveStatus: updateLiveStatus,
            fetchVideoUrls: fetchVideoUrls,
            selectVideo: selectVideo,
            logBi: logBi,
            getVideoData: getVideoData,
            autoplayLiveInteractionStart: fedopsLogger.autoplayLive.start,
        })
    ], LiveStartHandler);
    return LiveStartHandler;
}(React.Component));
export default LiveStartHandler;
