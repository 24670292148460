import { __decorate, __extends } from "tslib";
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import formatMessageWithPrice from '../../utils/format-message-with-price';
import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
var ChannelSubscriptionLabel = /** @class */ (function (_super) {
    __extends(ChannelSubscriptionLabel, _super);
    function ChannelSubscriptionLabel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChannelSubscriptionLabel.prototype.render = function () {
        var _a = this.props, subscriptionPriceInfo = _a.subscriptionPriceInfo, isPricingPlan = _a.isPricingPlan;
        return isPricingPlan
            ? i18n.t('widget.mobile.overlay-button.subscribe.short')
            : formatMessageWithPrice('widget.channel-actions.subscribe', subscriptionPriceInfo);
    };
    ChannelSubscriptionLabel.propTypes = {
        channel: PropTypes.object,
        subscriptionPriceInfo: PropTypes.object,
        dealInfo: PropTypes.array,
    };
    ChannelSubscriptionLabel = __decorate([
        connect(function (state, ownProps) { return ({
            subscriptionPriceInfo: dealInfoHelpers.getSubscriptionPriceInfo(ownProps.dealInfo),
            isPricingPlan: isPricingPlanEnabled(ownProps),
        }); })
    ], ChannelSubscriptionLabel);
    return ChannelSubscriptionLabel;
}(React.Component));
export default ChannelSubscriptionLabel;
