import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import formatMessageWithPrice from '../../../utils/format-message-with-price';
import { getFullAccessActionName, FULL_ACCESS_ACTION_NAMES, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
/**
 * Is channel subscribe button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @return {Boolean}
 */
export function isVisible(_a) {
    var channel = _a.channel, video = _a.video;
    var access = getFullAccessActionName(channel, video);
    return (access === FULL_ACCESS_ACTION_NAMES.SUBSCRIBE ||
        access === FULL_ACCESS_ACTION_NAMES.PRICING_PLAN);
}
/**
 * Channel subscribe button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.translation {Object} default is 'widget.overlay-buttons.get-subscription'
 * @return {React.Component}
 */
export default function renderSubscribeButtonText(props) {
    if (!isVisible(props)) {
        return null;
    }
    return isPricingPlanEnabled(props.channel)
        ? i18n.t('widget.mobile.overlay-button.subscribe.short')
        : formatMessageWithPrice(props.translation || 'widget.overlay-buttons.get-subscription', dealInfoHelpers.getSubscriptionPriceInfo(props.channel.dealInfo));
}
