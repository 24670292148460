import _ from 'lodash';
import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { shouldShowChannelCover } from '@wix/wix-vod-shared/dist/src/common/utils/overlay-buttons-utils';
import { getAllSettings, canShowMainItemTitle, canShowChannelTitle, isSliderLayout, isGridLayout, canShowVideoListItemTitle, isVerticalLayoutMobile, } from '../../../../shared/selectors/app-settings';
var getVideo = function (state, props) { return props.videoItem; };
var isVideosTouched = function (state) { return state.isVideosTouched; };
var getVideoId = function (state, props) { return _.get(props, 'videoItem.id'); };
var getVideoTitle = function (state, props) { return _.get(props, 'videoItem.title'); };
var getChannelTitle = createSelector([getChannelForWidget], function (channel) { return channel.title; });
var isFirstVideo = function (state, props) { return props.isFirstVideo; };
var isInitialVideo = createSelector([getChannelForWidget, getVideoId, isFirstVideo], function (channelData, videoId, isFirstVideo) {
    return _.get(channelData, 'featuredItem') === videoId || isFirstVideo;
});
var isInitialCover = createSelector([isVideosTouched, isInitialVideo, isVerticalLayoutMobile], function (isVideosTouched, isInitialVideo, isVerticalLayoutMobile) {
    return !isVerticalLayoutMobile && !isVideosTouched && isInitialVideo;
});
export var isChannelCoverView = createSelector([getAllSettings, isInitialCover, getVideo], function (appSettings, isInitialCover, videoItem) {
    return !videoItem || shouldShowChannelCover(appSettings, isInitialCover);
});
var isThumbnailTitleSelector = createSelector(isChannelCoverView, isGridLayout, isSliderLayout, function (isCover, isGrid, isSlider) { return isCover && (isGrid || isSlider); });
var mainTitleSelector = createSelector(isChannelCoverView, canShowChannelTitle, getChannelTitle, getVideoTitle, function (isChannelCoverView, canShowChannelTitle, channelTitle, videoTitle) {
    return isChannelCoverView && canShowChannelTitle ? channelTitle : videoTitle;
});
var thumbnailTitleSelector = createSelector(isChannelCoverView, canShowChannelTitle, canShowVideoListItemTitle, getChannelTitle, getVideoTitle, function (isChannelCoverView, canShowChannelTitle, canShowVideoListItemTitle, channelTitle, videoTitle) {
    if (canShowChannelTitle) {
        return channelTitle;
    }
    // Grid and slider layouts don't have a channel/featured video cover toggler by itself.
    // But this parameter can be changed from settings of another layout.
    // In such case we should just not show video title for channel cover
    if (canShowVideoListItemTitle && !isChannelCoverView) {
        return videoTitle;
    }
    return '';
});
export var getTitle = createSelector(canShowMainItemTitle, isThumbnailTitleSelector, thumbnailTitleSelector, mainTitleSelector, function (canShowMainItemTitle, isThumbnailTitle, thumbnailTitle, mainTitle) {
    if (!canShowMainItemTitle && !isThumbnailTitle) {
        return '';
    }
    return isThumbnailTitle ? thumbnailTitle : mainTitle;
});
export var getCover = createSelector([getChannelForWidget, getVideo, isChannelCoverView], function (channel, video, isChannelCoverView) {
    if (video === void 0) { video = {}; }
    var customCoverUrl = video.customCoverUrl, coverUrl = video.coverUrl;
    var videoUrl = customCoverUrl || coverUrl;
    return isChannelCoverView
        ? channel.customCoverUrl || channel.coverUrl || videoUrl
        : videoUrl;
});
