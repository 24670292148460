import React from 'react';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import styles from './coming-soon.scss';
import { withStyles } from '@wix/wix-vod-shared/dist/src/common/utils/withStyles';
import { Picture, PictureMode, } from '@wix/wix-vod-shared/dist/src/common/components/picture';
import { VideoAspectRatio } from '@wix/wix-vod-shared/dist/src/common/components/video-aspect-ratio';
import { MAX_COVER_IMAGE_HEIGHT, MAX_COVER_IMAGE_WIDTH, } from '../../constants/sizes';
export var ComingSoon = withStyles(styles)(function (_a) {
    var coverUrl = _a.coverUrl;
    return (React.createElement("div", { className: styles.container },
        React.createElement(VideoAspectRatio, null,
            coverUrl && (React.createElement(Picture, { src: coverUrl, className: styles.picture, mode: PictureMode.FIT_TO_WIDTH, breakpoints: [
                    {
                        width: MAX_COVER_IMAGE_WIDTH,
                        height: MAX_COVER_IMAGE_HEIGHT,
                    },
                ] })),
            React.createElement("div", { className: styles.text }, i18n.t('widget.this-channel-is-coming-soon')))));
});
