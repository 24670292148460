import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/dist/common/video-playback-statuses';
import PlayerWrapper from './player-wrapper';
import { abortPlayVideo, pauseVideo, setPlaybackStatus, } from '../../redux/client/actions/player/change-playback-status';
import { isVideoPaused, isVideoPausedOptimistic, isVideoPauseRequested, isVideoPlayAborted, isVideoPlaying, isVideoPlayingOptimistic, isVideoPlayRequested, } from '../../selectors/video-playback-status';
import { isMuted } from '../../../shared/selectors/player';
import { setAutoPlayed } from '../../redux/client/actions/player/set-autoplayed';
import PropTypes from 'prop-types';
import { isMobile } from '../../../shared/selectors/form-factor';
function getDependentProps(paused, isInFullscreen) {
    return {
        showInitialOverlay: !isInFullscreen && paused,
    };
}
var TabletPlayer = /** @class */ (function (_super) {
    __extends(TabletPlayer, _super);
    function TabletPlayer(props) {
        var _this = _super.call(this, props) || this;
        _this.onPlayStart = function () {
            var _a = _this.props, setPlaybackStatus = _a.setPlaybackStatus, onPlayStart = _a.onPlayStart;
            setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.PLAYING);
            onPlayStart();
            _this.setState(getDependentProps(false, true));
        };
        _this.onFullScreenChanged = function (isInFullScreen) {
            _this.props.onFullScreenChanged(isInFullScreen);
            var state = {
                isInFullScreen: isInFullScreen,
            };
            if (!isInFullScreen) {
                _this.props.pauseVideo();
                var newPaused = true;
                state = __assign(__assign(__assign({}, state), { paused: newPaused }), getDependentProps(newPaused, isInFullScreen));
            }
            _this.setState(state);
        };
        _this.onPause = function () {
            var _a = _this.props, setPlaybackStatus = _a.setPlaybackStatus, isVideoPaused = _a.isVideoPaused, setAutoPlayed = _a.setAutoPlayed;
            if (!isVideoPaused) {
                setAutoPlayed(false);
                setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.PAUSED);
            }
        };
        _this.onResume = function () {
            var setPlaybackStatus = _this.props.setPlaybackStatus;
            setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.PLAYING);
        };
        _this.onEnded = function () {
            var setPlaybackStatus = _this.props.setPlaybackStatus;
            setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.ENDED);
        };
        _this.state = __assign(__assign({}, getDependentProps(props.paused, false)), { paused: props.paused });
        return _this;
    }
    TabletPlayer.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var paused = nextProps.paused;
        var isInFullScreen = this.state.isInFullScreen;
        if (this.props.paused !== paused) {
            var newState = __assign(__assign({}, getDependentProps(paused, isInFullScreen)), { paused: paused });
            // do not show overlay when in full screen (android case)
            if (paused && isInFullScreen) {
                newState.showInitialOverlay = false;
            }
            this.setState(newState);
        }
    };
    TabletPlayer.prototype.render = function () {
        var playAtFullScreen = this.props.playAtFullScreen;
        var _a = this.state, paused = _a.paused, showInitialOverlay = _a.showInitialOverlay;
        var props = _.omit(this.props, 'paused', 'mobileMode', 'playAtFullScreen', 'onFullScreenChanged', 'onPurchaseClick', 'onSubscribeClick', 'onRentClick');
        return (React.createElement(PlayerWrapper, __assign({}, props, { showInitialOverlayOnPause: true, mobileMode: true, paused: paused, playAtFullScreen: playAtFullScreen, showInitialOverlay: showInitialOverlay, onPlayStart: this.onPlayStart, onPause: this.onPause, onResume: this.onResume, onEnded: this.onEnded, onFullScreenChanged: this.onFullScreenChanged })));
    };
    TabletPlayer.propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        videoUrls: PropTypes.object,
        videoItem: PropTypes.object,
        channelData: PropTypes.object,
        size: PropTypes.object,
        simple: PropTypes.bool,
        overlay: PropTypes.any,
        onFullScreenChanged: PropTypes.func,
        onPlayStart: PropTypes.func,
        onEnded: PropTypes.func,
        preload: PropTypes.string,
        reportStats: PropTypes.func,
        fetchVideoUrls: PropTypes.func,
        appSettings: PropTypes.object,
        canShowChannelCover: PropTypes.bool,
        mainOrThumbnailLocation: PropTypes.string,
        muted: PropTypes.bool,
        loop: PropTypes.bool,
        currentSiteUser: PropTypes.object,
        onMemberSignUp: PropTypes.func,
        currentTime: PropTypes.number,
        setAutoPlayed: PropTypes.func,
        setPlaybackStatus: PropTypes.func,
        abortPlayVideo: PropTypes.func,
        onPause: PropTypes.func,
        onResume: PropTypes.func,
        isVideoCardsEnabled: PropTypes.bool,
        fetchVideoCards: PropTypes.func,
        onPlayRequest: PropTypes.func,
        shouldLoadVideo: PropTypes.bool,
        isOverQuota: PropTypes.bool,
        isLive: PropTypes.bool,
        cleanupVideoCards: PropTypes.func,
        isInLightbox: PropTypes.bool,
        isAutoplayed: PropTypes.bool,
        isVideoPlayRequested: PropTypes.bool,
        isVideoPauseRequested: PropTypes.bool,
        isVideoPlaying: PropTypes.bool,
        isVideoPaused: PropTypes.bool,
        isVideoPlayingOptimistic: PropTypes.bool,
        isVideoPausedOptimistic: PropTypes.bool,
        isVideoPlayAborted: PropTypes.bool,
    };
    TabletPlayer.defaultProps = {
        onFullScreenChanged: _.noop,
        onPlayStart: _.noop,
    };
    TabletPlayer = __decorate([
        connect(function (state) { return ({
            isVideoPlayRequested: isVideoPlayRequested(state),
            isVideoPauseRequested: isVideoPauseRequested(state),
            isVideoPlaying: isVideoPlaying(state),
            isVideoPaused: isVideoPaused(state),
            isVideoPlayingOptimistic: isVideoPlayingOptimistic(state),
            isVideoPausedOptimistic: isVideoPausedOptimistic(state),
            isVideoPlayAborted: isVideoPlayAborted(state),
            isMuted: isMuted(state),
            playAtFullScreen: !isMobile(state),
        }); }, {
            pauseVideo: pauseVideo,
            setAutoPlayed: setAutoPlayed,
            setPlaybackStatus: setPlaybackStatus,
            abortPlayVideo: abortPlayVideo,
        })
    ], TabletPlayer);
    return TabletPlayer;
}(React.Component));
export default TabletPlayer;
