import { __decorate, __extends, __read } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import _ from 'lodash';
import { connect } from 'react-redux';
import { logBi } from '../../../../shared/worker/actions/bi';
import { getBiToken, getInstanceId, getSiteUrl, getFullSiteUrl, getSiteOwnerId, } from '../../../redux/client/hydrated-data/hydrated-data';
import { SHARE_MODES } from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/share-buttons/constants';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import EmbedSelect from './embed-select/embed-select';
import CustomSize from './custom-size/custom-size';
import CopyContent from './copy-content/copy-content';
import Header from './header/header';
import { calculateHeight, calculateWidth, MIN_WIDTH, MIN_HEIGHT, EMBED_API_ENDPOINT, } from './utils';
import { withStyles } from '../../../../shared/utils/withStyles';
import styles from './embed-form.scss';
var SIZES = [
    {
        value: '560x315',
        text: '560x315',
    },
    {
        value: '1280x720',
        text: '1280x720',
    },
    {
        value: '853x480',
        text: '853x480',
    },
    {
        value: '640x360',
        text: '640x360',
    },
    {
        value: '',
        text: i18n.t('share-overlay.embed.custom'),
    },
];
var DEFAULT_SIZE = SIZES[0];
var EmbedForm = /** @class */ (function (_super) {
    __extends(EmbedForm, _super);
    function EmbedForm(props) {
        var _this = _super.call(this, props) || this;
        _this.saveRef = function (ref) {
            _this.containerRef = ref;
        };
        _this.handleBackButtonClick = function (e) {
            e.preventDefault();
            _this.props.onBack();
        };
        _this.saveTextAreaRef = function (node) {
            _this.textAreaRef = node;
        };
        _this.selectCode = function () {
            _this.textAreaRef.select();
        };
        _this.updateSize = function (size) {
            _this.setState({ size: size });
        };
        _this.onWidthBlur = function (width) {
            var useDefaultValues = !_.gt(width, MIN_WIDTH);
            var customWidth = useDefaultValues ? MIN_WIDTH : width;
            var customHeight = useDefaultValues ? MIN_HEIGHT : calculateHeight(width);
            _this.setState({
                customHeight: customHeight,
                customWidth: customWidth,
            });
        };
        _this.onHeightBlur = function (height) {
            var useDefaultValues = !_.gt(height, MIN_HEIGHT);
            var customHeight = useDefaultValues ? MIN_HEIGHT : height;
            var customWidth = useDefaultValues ? MIN_WIDTH : calculateWidth(height);
            _this.setState({
                customHeight: customHeight,
                customWidth: customWidth,
            });
        };
        _this.setCustomSize = function () {
            var size = _this.state.size;
            var customSize = _.last(SIZES).value;
            if (size !== customSize) {
                var _a = __read(size.split('x'), 2), width = _a[0], height = _a[1];
                _this.setState({
                    size: customSize,
                    customHeight: height,
                    customWidth: width,
                });
            }
        };
        _this.stopPropagation = function (event) {
            event.stopPropagation();
        };
        _this.handleContentCopied = function () {
            var _a = _this.props, channelId = _a.channelId, videoId = _a.videoId;
            _this.props.logBi('widget.share.embed.copy.clicked', {
                channelID: channelId,
                videoID: videoId,
                shareType: SHARE_MODES.VIDEO,
            });
        };
        _this.state = {
            customWidth: '',
            customHeight: '',
            size: DEFAULT_SIZE.value,
            websiteUrl: null,
            instanceId: props.instanceId,
            biToken: props.biToken,
            pathToPage: null,
        };
        return _this;
    }
    EmbedForm.prototype.componentDidMount = function () {
        var _a = this.props, siteUrl = _a.siteUrl, fullSiteUrl = _a.fullSiteUrl;
        var baseUrlLen = siteUrl.length;
        var _b = __read(fullSiteUrl.substr(baseUrlLen).split('?'), 1), pathToPage = _b[0];
        this.setState({
            pathToPage: pathToPage ? encodeURIComponent(pathToPage) : '',
        });
        focus(this.containerRef);
    };
    EmbedForm.prototype.generateEmbedUrl = function () {
        var _a = this.getSize(), width = _a.width, height = _a.height;
        var embedUrl = this.buildEmbedUrl();
        if (!embedUrl) {
            return '';
        }
        return "<iframe width=\"".concat(width, "\" height=\"").concat(height, "\" src=\"").concat(embedUrl, "\" frameborder=\"0\" allowfullscreen></iframe>");
    };
    EmbedForm.prototype.buildEmbedUrl = function () {
        var _a = this.state, pathToPage = _a.pathToPage, instanceId = _a.instanceId, biToken = _a.biToken;
        if (pathToPage === null) {
            return '';
        }
        var _b = this.props, channelId = _b.channelId, videoId = _b.videoId, compId = _b.compId, sitePageId = _b.sitePageId, siteOwnerId = _b.siteOwnerId;
        var channelIdOrOwnerId = channelId || siteOwnerId; // this is legacy logic of V2. For single videos we pass ownerId as channelId.
        // eslint-disable-next-line max-len
        return "".concat(EMBED_API_ENDPOINT, "?instanceId=").concat(instanceId, "&biToken=").concat(biToken, "&pathToPage=").concat(pathToPage, "&channelId=").concat(channelIdOrOwnerId, "&videoId=").concat(videoId, "&compId=").concat(compId, "&sitePageId=").concat(sitePageId);
    };
    EmbedForm.prototype.getSize = function () {
        var _a = this.state, size = _a.size, customWidth = _a.customWidth, customHeight = _a.customHeight;
        if (size) {
            var _b = __read(size.split('x'), 2), width = _b[0], height = _b[1];
            return { width: width, height: height };
        }
        else if (customWidth && customHeight) {
            return { width: customWidth, height: customHeight };
        }
        var _c = __read(DEFAULT_SIZE.value.split('x'), 2), defaultWidth = _c[0], defaultHeight = _c[1];
        return { width: defaultWidth, height: defaultHeight };
    };
    EmbedForm.prototype.getOptions = function () {
        return SIZES;
    };
    EmbedForm.prototype.getCustomSizeWidth = function () {
        var _a = this.state, size = _a.size, customWidth = _a.customWidth;
        return size ? size.split('x')[0] : customWidth;
    };
    EmbedForm.prototype.getCustomSizeHeight = function () {
        var _a = this.state, size = _a.size, customHeight = _a.customHeight;
        return size ? size.split('x')[1] : customHeight;
    };
    EmbedForm.prototype.render = function () {
        var _a = this.props, dataHook = _a.dataHook, isRTL = _a.isRTL;
        var size = this.state.size;
        return (React.createElement("section", { "data-hook": dataHook, ref: this.saveRef, onClick: this.stopPropagation, className: styles['embed-form'] },
            React.createElement(Header, { dataHook: "header", onBackButtonClick: this.handleBackButtonClick }),
            React.createElement("div", { className: styles['size-picker'] },
                React.createElement(EmbedSelect, { dataHook: "embed-select", onChange: this.updateSize, selected: size, options: this.getOptions(), isRTL: isRTL, ariaLabel: i18n.t('widget.accessibility.widget-size', {
                        size: size.label,
                    }) }),
                React.createElement(CustomSize, { dataHook: "custom-size", className: styles['custom-sizes'], inactive: Boolean(size), width: this.getCustomSizeWidth(), height: this.getCustomSizeHeight(), onFocus: this.setCustomSize, onWidthBlur: this.onWidthBlur, onHeightBlur: this.onHeightBlur })),
            React.createElement(CopyContent, { onInit: this.saveTextAreaRef, onFocus: this.selectCode, onContentCopied: this.handleContentCopied, value: this.generateEmbedUrl(), isRTL: isRTL })));
    };
    EmbedForm.propTypes = {
        dataHook: PropTypes.string,
        onBack: PropTypes.func,
        channelId: PropTypes.string,
        videoId: PropTypes.string,
        compId: PropTypes.string,
        sitePageId: PropTypes.string,
        isRTL: PropTypes.bool,
        biToken: PropTypes.string.isRequired,
        instanceId: PropTypes.string.isRequired,
        siteUrl: PropTypes.string.isRequired,
        fullSiteUrl: PropTypes.string.isRequired,
        siteOwnerId: PropTypes.string.isRequired,
    };
    EmbedForm = __decorate([
        connect(function (state) { return ({
            biToken: getBiToken(state),
            instanceId: getInstanceId(state),
            siteUrl: getSiteUrl(state),
            fullSiteUrl: getFullSiteUrl(state),
            siteOwnerId: getSiteOwnerId(state),
        }); }, { logBi: logBi }),
        withStyles(styles)
    ], EmbedForm);
    return EmbedForm;
}(React.Component));
export default EmbedForm;
