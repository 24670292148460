import { __assign } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { Direction } from '../../../shared/containers/direction';
var wrapWithPortal = function (portal, children) {
    return portal ? (React.createElement(Portal, { role: "presentation" },
        React.createElement(Direction, null, children))) : (React.createElement("div", { role: "presentation" }, children));
};
export default function FixedInIFrame(_a) {
    var children = _a.children, className = _a.className, style = _a.style, dataHook = _a.dataHook, _b = _a.portal, portal = _b === void 0 ? true : _b;
    return wrapWithPortal(portal, React.createElement("div", { style: __assign(__assign({}, style), { position: 'fixed' }), "data-hook": dataHook, className: className, role: "presentation" }, children));
}
FixedInIFrame.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    dataHook: PropTypes.string,
    style: PropTypes.object,
    portal: PropTypes.bool,
};
