import { __decorate, __extends } from "tslib";
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideMenuIcon from '../../../../assets/top-menu.svg';
import { openSlideMenu, closeSlideMenu, } from '../../../../redux/actions/slide-menu';
import { shouldShowMenuButton } from '../../ui-selectors/slide-menu';
import fedopsLogger from '../../../../../loggers/fedops/mobile-widget';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
var SlideMenuButton = /** @class */ (function (_super) {
    __extends(SlideMenuButton, _super);
    function SlideMenuButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function () {
            var _a = _this.props, isSlideMenuOpened = _a.isSlideMenuOpened, openSlideMenu = _a.openSlideMenu, closeSlideMenu = _a.closeSlideMenu;
            if (isSlideMenuOpened) {
                closeSlideMenu();
            }
            else {
                _this.props.logOpenSlideMenuStart();
                openSlideMenu();
            }
        };
        return _this;
    }
    SlideMenuButton.prototype.render = function () {
        var _a = this.props, className = _a.className, iconClassName = _a.iconClassName, shouldBeShown = _a.shouldBeShown;
        return (shouldBeShown && (React.createElement("div", { "data-hook": "slide-menu-button", className: className, onClick: this.handleClick, role: "button", "aria-label": i18n.t('widget.mobile.slide-menu-button-accessibility') },
            React.createElement(SlideMenuIcon, { className: iconClassName }))));
    };
    SlideMenuButton.propTypes = {
        videoItem: PropTypes.object,
        channelData: PropTypes.object,
        className: PropTypes.string,
        iconClassName: PropTypes.string,
        isSlideMenuOpened: PropTypes.bool,
        alwaysShow: PropTypes.bool,
        shouldBeShown: PropTypes.bool,
        isFirstVideo: PropTypes.bool,
        openSlideMenu: PropTypes.func,
        closeSlideMenu: PropTypes.func,
    };
    SlideMenuButton = __decorate([
        connect(function (state, props) { return ({
            isSlideMenuOpened: state.slideMenuOpened,
            shouldBeShown: props.alwaysShow || shouldShowMenuButton(state, props),
        }); }, {
            openSlideMenu: openSlideMenu,
            closeSlideMenu: closeSlideMenu,
            logOpenSlideMenuStart: fedopsLogger.openSlideMenu.start,
        })
    ], SlideMenuButton);
    return SlideMenuButton;
}(React.Component));
export default SlideMenuButton;
