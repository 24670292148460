import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from '../../../../shared/selectors/current-site-user';
import { getAvailableVideoType, AVAILABLE_VIDEO_TYPES, FULL_ACCESS_ACTION_NAMES, getFullAccessActionName, canPlayFullOrPreview, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import purchaseTranslations from '../constants/purchase-translations';
import renderSubscribeButtonText from '../../../../shared/components/overlay-texts/channel/subscribe-button-text';
import renderPurchaseButtonText from '../../../../shared/components/overlay-texts/purchase-button-text';
import { isVerticalLayoutMobile } from '../../../../shared/selectors/app-settings';
var getVideo = function (state, props) { return props.videoItem; };
var isChannelView = function (state, props) { return props.isChannelCoverView; };
export var playButtonText = createSelector([getChannelForWidget, getVideo, getCurrentSiteUser, isChannelView], function (channel, video, currentSiteUser, isChannelCoverView) {
    var videoType = getAvailableVideoType(channel, video, currentSiteUser);
    if (videoType === AVAILABLE_VIDEO_TYPES.LIVE_STREAMING) {
        return 'widget.overlay-buttons.watch-live';
    }
    if (videoType === AVAILABLE_VIDEO_TYPES.FULL) {
        return isChannelCoverView
            ? 'widget.overlay-buttons.start-watching'
            : 'widget.overlay-buttons.play-video';
    }
    if (videoType === AVAILABLE_VIDEO_TYPES.TRAILER) {
        return 'widget.overlay-buttons.trailer';
    }
    if (videoType === AVAILABLE_VIDEO_TYPES.FIRST_20_SEC) {
        return 'shared.preview';
    }
    var fullAccessAction = getFullAccessActionName(channel, video, currentSiteUser);
    if (fullAccessAction === FULL_ACCESS_ACTION_NAMES.SIGN_IN) {
        if (isLiveVideo(video)) {
            return 'widget.overlay-buttons.watch-live';
        }
        return isChannelCoverView
            ? 'widget.overlay-buttons.start-watching'
            : 'widget.overlay-buttons.play-video';
    }
    return null;
});
export var playButtonAction = createSelector([
    getChannelForWidget,
    getVideo,
    getCurrentSiteUser,
    function (state, props) { return props.onMemberSignUp; },
    function (state, props) { return props.onPlayRequestedAllowed; },
    function (state, props) { return props.onPlayRequestedDisallowed; },
], function (channel, video, currentSiteUser, onMemberSignUp, onPlayRequestedAllowed, onPlayRequestedDisallowed) {
    if (getFullAccessActionName(channel, video, currentSiteUser) ===
        FULL_ACCESS_ACTION_NAMES.SIGN_IN) {
        return onMemberSignUp;
    }
    if (canPlayFullOrPreview(channel, video, currentSiteUser)) {
        return onPlayRequestedAllowed;
    }
    return onPlayRequestedDisallowed;
});
export var subscribeButtonText = createSelector([getChannelForWidget, getVideo], function (channel, video) { return renderSubscribeButtonText({ channel: channel, video: video }); });
export var purchaseButtonText = createSelector([getChannelForWidget, getVideo, isVerticalLayoutMobile], function (channel, video, isVerticalLayout) {
    return renderPurchaseButtonText({
        channel: channel,
        video: video,
        translations: purchaseTranslations,
        isVerticalLayoutMobile: isVerticalLayout,
    });
});
