import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addLocaleData, IntlProvider } from 'react-intl';
import translations from '@wix/wix-vod-shared/dist/src/common/i18n/translations';
import { getExperiments } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { getHydratedData } from '../../widget/redux/client/hydrated-data/hydrated-data';
import { getLocale } from '../selectors/app-settings';
import reactIntlData from '../../locales/react-intl-locale-data';
import { withWidgetProps } from '../../widget/containers/widget-props';
var LocaleProvider = /** @class */ (function (_super) {
    __extends(LocaleProvider, _super);
    function LocaleProvider(props) {
        var _this = _super.call(this, props) || this;
        _this.setExperiments = function () {
            var experiments = getExperiments();
            if (!_this.state.experimentsReady && experiments) {
                translations.setExperiments(experiments);
                _this.setState({
                    experimentsReady: true,
                });
            }
        };
        _this.state = {
            locale: null,
            translationsReady: false,
            experimentsReady: false,
        };
        return _this;
    }
    LocaleProvider.prototype.UNSAFE_componentWillMount = function () {
        this.setLocaleData();
    };
    LocaleProvider.prototype.componentDidMount = function () {
        this.setLocaleData();
    };
    LocaleProvider.prototype.setLocaleData = function () {
        var hydratedLocaleData = this.props.hydratedLocaleData;
        if (!hydratedLocaleData) {
            return;
        }
        addLocaleData(reactIntlData[hydratedLocaleData.locale]);
        translations.set(hydratedLocaleData.translations);
        this.setState({
            locale: hydratedLocaleData.locale,
            translationsReady: true,
        });
        this.setExperiments();
    };
    LocaleProvider.prototype.componentDidUpdate = function () {
        this.setExperiments();
    };
    LocaleProvider.prototype.render = function () {
        var _a = this.state, locale = _a.locale, translationsReady = _a.translationsReady;
        var children = this.props.children;
        if (!translationsReady || !children) {
            return null;
        }
        return (React.createElement(IntlProvider, { messages: translations.getAll(), locale: locale }, children));
    };
    LocaleProvider.propTypes = {
        children: PropTypes.node,
        hydratedLocaleData: PropTypes.object,
        locale: PropTypes.string,
    };
    LocaleProvider = __decorate([
        connect(function (state) {
            var _a = getHydratedData(state), locale = _a.locale, translations = _a.translations;
            return {
                hydratedLocaleData: locale && translations ? { locale: locale, translations: translations } : null,
                locale: getLocale(state),
            };
        }),
        withWidgetProps(function (_a) {
            var captureException = _a.captureException;
            return ({ captureException: captureException });
        })
    ], LocaleProvider);
    return LocaleProvider;
}(React.Component));
export default LocaleProvider;
