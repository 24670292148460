import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ensureVideoLoaded } from '../../../../../redux/server/actions/video/ensure-video-loaded';
import { selectVideo } from '../../../../../redux/client/actions/select-video';
import { playVideo, pauseVideo, } from '../../../../../redux/client/actions/player/change-playback-status';
import { getCurrentSiteUser } from '../../../../../../shared/selectors/current-site-user';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getVideosGroupedByIds } from '../../../../../../shared/selectors/videos';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { getVideoCardsList } from '../../../../../selectors/video-cards';
import VideoCardsWrapper from '../../../../../components/video-cards-wrapper/video-cards-wrapper';
var VideoCardsWrapperDesktop = /** @class */ (function (_super) {
    __extends(VideoCardsWrapperDesktop, _super);
    function VideoCardsWrapperDesktop() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handlePlayRequest = function (_a) {
            var id = _a.id;
            var _b = _this.props, channelData = _b.channelData, currentSiteUser = _b.currentSiteUser, selectVideo = _b.selectVideo, playVideo = _b.playVideo, ensureVideoLoaded = _b.ensureVideoLoaded;
            ensureVideoLoaded(id).then(function () {
                var videoItem = _this.props.videoByIds[id];
                var isPlayAllowed = canPlayFullOrPreview(channelData, videoItem, currentSiteUser);
                if (isPlayAllowed) {
                    playVideo(videoItem.id);
                    return;
                }
                selectVideo(videoItem.id);
            });
        };
        return _this;
    }
    VideoCardsWrapperDesktop.prototype.render = function () {
        return (React.createElement(VideoCardsWrapper, __assign({}, this.props, { handlePlayRequest: this.handlePlayRequest })));
    };
    VideoCardsWrapperDesktop.propTypes = {
        currentSiteUser: PropTypes.object,
        channelData: PropTypes.object,
        videoByIds: PropTypes.object,
        cardsData: PropTypes.array,
        children: PropTypes.func.isRequired,
        selectVideo: PropTypes.func,
        playVideo: PropTypes.func,
        pauseVideo: PropTypes.func,
        ensureVideoLoaded: PropTypes.func,
        thumbnailSize: PropTypes.number,
    };
    VideoCardsWrapperDesktop = __decorate([
        connect(function (state) { return ({
            cardsData: getVideoCardsList(state),
            currentSiteUser: getCurrentSiteUser(state),
            videoByIds: getVideosGroupedByIds(state),
            channelData: getChannelForWidget(state),
        }); }, {
            selectVideo: selectVideo,
            playVideo: playVideo,
            pauseVideo: pauseVideo,
            ensureVideoLoaded: ensureVideoLoaded,
        })
    ], VideoCardsWrapperDesktop);
    return VideoCardsWrapperDesktop;
}(React.PureComponent));
export default VideoCardsWrapperDesktop;
