import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '../../../../shared/utils/withStyles';
import styles from './home.wrapper.scss';
var HomeLayoutWrapper = function (_a) {
    var children = _a.children, viewMode = _a.viewMode;
    return (React.createElement("div", { className: styles.container, "data-view-mode": viewMode, role: "presentation" }, children));
};
HomeLayoutWrapper.propTypes = {
    children: PropTypes.node,
    viewMode: PropTypes.string,
};
export default withStyles(styles)(HomeLayoutWrapper);
