import { FULL_ACCESS_ACTION_NAMES } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { DEFAULT_TRANSLATIONS as VIDEO_TRANSLATIONS } from '../../../../../../../../shared/components/overlay-texts/play-button-text';
export default function getTranslationData(availableVideoType, fullAccessAction) {
    if (availableVideoType) {
        return {
            props: VIDEO_TRANSLATIONS[availableVideoType],
        };
    }
    if (fullAccessAction === FULL_ACCESS_ACTION_NAMES.SIGN_IN) {
        return {
            props: 'widget.overlay-buttons.play-video',
        };
    }
    return null;
}
