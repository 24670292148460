(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "react", "reactDOM"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("lodash"), require("react"), require("react-dom")) : factory(root[undefined], root["React"], root["ReactDOM"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE_YLtl__, __WEBPACK_EXTERNAL_MODULE_cDcd__, __WEBPACK_EXTERNAL_MODULE_faye__) {
return 