import { createSelector } from 'reselect';
import { getCompId, getFullSiteUrl, getCurrentPageId, getInstanceId, } from '../redux/client/hydrated-data/hydrated-data';
import getUrlToVideo from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-video';
import getUrlToChannel from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-channel';
var getUrlToVideoSafe = function (videoItem, params) {
    if (!videoItem) {
        return null;
    }
    return getUrlToVideo(videoItem, params);
};
export var getShareMode = function (state) { return state.shareOverlay.shareMode; };
export var isShareOverlayOpen = function (state) { return state.shareOverlay.isOpen; };
export var getInstanceInfo = createSelector(getInstanceId, getCompId, getCurrentPageId, function (instanceId, componentId, sitePageId) { return ({
    instanceId: instanceId,
    componentId: componentId,
    sitePageId: sitePageId,
}); });
export var getVideoUrl = createSelector(function (state, ownProps) { return ownProps.videoItem; }, getCompId, getFullSiteUrl, function (videoItem, compId, siteUrl) {
    return getUrlToVideoSafe(videoItem, { compId: compId, siteUrl: siteUrl });
});
export var getChannelUrl = createSelector(getCompId, getFullSiteUrl, function (compId, siteUrl) { return getUrlToChannel({ siteUrl: siteUrl, compId: compId }); });
