import { __extends, __read } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Measure from './measure';
function childrenKeys(props) {
    return React.Children.map(props.children, function (child) { return child.key; });
}
// FIXME: looks like it doesn't work at all, and I don't see a good way to fix it.
// Need to talk with Product Owner and redesign all places that related for this logic
var ElementSwitcher = /** @class */ (function (_super) {
    __extends(ElementSwitcher, _super);
    function ElementSwitcher() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            width: null,
        };
        _this.onMeasure = function (dimensions) {
            var width = _.get(dimensions, 'width');
            if (width !== 0 && width !== _this.state.width) {
                _this.setState({ width: width });
            }
        };
        return _this;
    }
    ElementSwitcher.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        // Correct solution would be to check width of prev and next children but
        // to simplify and to speed up keys are used instead
        var nextKeys = childrenKeys(nextProps).join('++');
        var prevKeys = childrenKeys(this.props).join('++');
        if (nextProps.maxWidth !== this.props.maxWidth || nextKeys !== prevKeys) {
            this.setState({ width: null });
        }
    };
    ElementSwitcher.prototype.render = function () {
        var _a = this.props, maxWidth = _a.maxWidth, children = _a.children, shouldAlwaysMeasure = _a.shouldAlwaysMeasure;
        var width = this.state.width;
        var _b = __read(React.Children.toArray(children), 2), normal = _b[0], small = _b[1];
        return (React.createElement(Measure, { onMeasure: this.onMeasure, shouldMeasure: shouldAlwaysMeasure || width === null }, width && width > maxWidth ? small : normal));
    };
    ElementSwitcher.propTypes = {
        children: PropTypes.any,
        maxWidth: PropTypes.number,
        shouldAlwaysMeasure: PropTypes.bool,
    };
    return ElementSwitcher;
}(React.Component));
export default ElementSwitcher;
