import _ from 'lodash';
import { createSelector } from 'reselect';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isNavigationArrowsOutside, getContainerMargins, isPlayInFrame, } from '../../../shared/selectors/app-settings';
import { getMainVideo } from '../../selectors/get-video';
import { getCurrentSiteUser } from '../../../shared/selectors/current-site-user';
import { FORCED_MARGIN_WIDTH, MAX_MARGIN_WIDTH } from './constants';
export var getSliderHorizontalMargin = createSelector(getContainerMargins, isNavigationArrowsOutside, function (horizontalMargin, isArrowsOutside) {
    return _.clamp(horizontalMargin, isArrowsOutside ? FORCED_MARGIN_WIDTH : 0, MAX_MARGIN_WIDTH);
});
export var canRenderPlayer = createSelector(getChannelForWidget, getMainVideo, getCurrentSiteUser, isPlayInFrame, function (channel, video, user, isInFrame) {
    return Boolean(video && isInFrame && canPlayFullOrPreview(channel, video, user));
});
