import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import { connect } from 'react-redux';
import fedopsLogger from '../../../shared/utils/fedops-logger';
import mobileFedopsLogger from '../../../loggers/fedops/mobile-widget';
import { addSingleMeasurement } from '../../../shared/utils/performance/index';
import { getOpenedEventParams } from '../../../shared/utils/bi/video-overlay-events-params';
import { logBi } from '../../../shared/worker/actions/bi';
import { performanceApplicationLoaded } from '../../../shared/worker/actions/performanceLogger';
import PERFORMANCE_TYPES from '../../../shared/utils/performance/types';
import { CHANNEL_LAYOUT_VALUES } from '@wix/wix-vod-constants/dist/app-settings/channel-layout-values';
import { getAllSettings, getChannelLayout, } from '../../../shared/selectors/app-settings';
import * as viewModeSelectors from '../../selectors/view-mode';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getMainVideo } from '../../selectors/get-video';
import { shouldShowChannelCover } from '@wix/wix-vod-shared/dist/src/common/utils/overlay-buttons-utils';
import { sendLoadComplete } from '../../utils/load-complete';
import { withWidgetProps } from '../widget-props';
var LAYOUTS_WITH_CHANNEL_COVER = [
    CHANNEL_LAYOUT_VALUES.CLASSIC,
    CHANNEL_LAYOUT_VALUES.COMPACT,
    CHANNEL_LAYOUT_VALUES.STRIP,
];
var PerformanceLoggers = /** @class */ (function (_super) {
    __extends(PerformanceLoggers, _super);
    function PerformanceLoggers() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.logWidgetOpenedBi = function () {
            var _a = _this.props, appSettings = _a.appSettings, channel = _a.channel, mainVideo = _a.mainVideo, isSiteMode = _a.isSiteMode, viewMode = _a.viewMode, showChannelCover = _a.showChannelCover;
            addSingleMeasurement(PERFORMANCE_TYPES.APP_FULLY_RENDERED);
            _this.props.registerToComponentDidLayout(function () {
                _this.props.performanceApplicationLoaded();
            });
            _this.props.logBi('widget.opened', getOpenedEventParams(appSettings, channel, mainVideo, showChannelCover, viewMode));
            if (isSiteMode) {
                _this.props.sendLoadComplete();
            }
        };
        return _this;
    }
    PerformanceLoggers.prototype.componentDidMount = function () {
        this.props.logAppLoaded();
        this.logWidgetOpenedBi();
    };
    PerformanceLoggers.prototype.render = function () {
        return null;
    };
    PerformanceLoggers.propTypes = {
        appSettings: PropTypes.object.isRequired,
        channel: PropTypes.object.isRequired,
        mainVideo: PropTypes.object,
        isSiteMode: PropTypes.bool.isRequired,
        viewMode: PropTypes.string.isRequired,
    };
    PerformanceLoggers = __decorate([
        connect(function (state) { return ({
            appSettings: getAllSettings(state),
            channel: getChannelForWidget(state),
            mainVideo: getMainVideo(state),
            isSiteMode: viewModeSelectors.isSiteMode(state),
            viewMode: viewModeSelectors.getViewMode(state),
        }); }, {
            logBi: logBi,
            sendLoadComplete: sendLoadComplete,
            logAppLoaded: fedopsLogger.appLoaded,
            performanceApplicationLoaded: performanceApplicationLoaded,
        }),
        withWidgetProps(function (widgetProps) { return ({
            registerToComponentDidLayout: widgetProps.host.registerToComponentDidLayout,
        }); })
    ], PerformanceLoggers);
    return PerformanceLoggers;
}(React.Component));
function showChannelCoverForWidget(state) {
    var channelLayout = getChannelLayout(state);
    var canShowChannelCover = includes(LAYOUTS_WITH_CHANNEL_COVER, channelLayout);
    var appSettings = getAllSettings(state);
    return shouldShowChannelCover(appSettings, canShowChannelCover);
}
export var WidgetPerformanceLoggers = connect(function (state) { return ({
    showChannelCover: showChannelCoverForWidget(state),
}); }, {
    logAppLoaded: fedopsLogger.appLoaded,
})(PerformanceLoggers);
export var MobilePerformanceLoggers = connect(function (state) { return ({
    showChannelCover: !state.isVideosTouched,
}); }, {
    logAppLoaded: mobileFedopsLogger.appLoaded,
})(PerformanceLoggers);
