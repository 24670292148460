export var MIN_INLINE_SHARE_THUMBNAIL_WIDTH = 399;
export var DEFAULT_WIDGET_HEIGHT = 250;
export var DEFAULT_WIDGET_WIDTH = 320;
export var DEFAULT_VIDEO_WIDTH = 320;
export var DEFAULT_VIDEO_HEIGHT = 180;
export var MAX_COVER_IMAGE_WIDTH = 1280;
export var MAX_COVER_IMAGE_HEIGHT = 720;
export var VERTICAL_LAYOUT_HEADER_HEIGHT = 40;
export var VERTICAL_LAYOUT_VIDEO_HEIGHT = 220;
export var VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM = 2;
export var VERTICAL_LAYOUT_LOAD_MORE_BUTTON_HEIGHT = 66;
