import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import formatMessageWithPrice from '../../../utils/format-message-with-price';
import { isSubscriptionButtonVisible } from './subscribe-button-text-utils';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
/**
 * Is video subscribe button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @return {Boolean}
 */
/**
 * Video sign in to play button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.translation {Object} default is 'widget.overlay-buttons.get-subscription'
 * @return {React.Component}
 */
export default function renderSubscribeButtonText(props) {
    if (!isSubscriptionButtonVisible(props)) {
        return null;
    }
    var isPricingPlan = isPricingPlanEnabled(props.channel);
    return isPricingPlan
        ? i18n.t('widget.mobile.overlay-button.subscribe.short')
        : formatMessageWithPrice(props.translation || 'widget.overlay-buttons.get-subscription', dealInfoHelpers.getSubscriptionPriceInfo(props.channel.dealInfo));
}
