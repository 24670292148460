import { __decorate, __extends } from "tslib";
import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './menu-item.scss';
import KEY_CODES from '@wix/wix-vod-shared/dist/src/widget/constants/key-codes';
var MenuItem = /** @class */ (function (_super) {
    __extends(MenuItem, _super);
    function MenuItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleKeyDown = function (event) {
            if (_.includes([KEY_CODES.SPACE, KEY_CODES.ENTER], event.keyCode)) {
                event.preventDefault();
                _this.props.onClick();
                return;
            }
            var _a = event.target, previousElementSibling = _a.previousElementSibling, nextElementSibling = _a.nextElementSibling;
            if (KEY_CODES.UP === event.keyCode) {
                event.preventDefault();
                if (previousElementSibling) {
                    previousElementSibling.focus();
                }
            }
            if (KEY_CODES.DOWN === event.keyCode) {
                event.preventDefault();
                if (nextElementSibling) {
                    nextElementSibling.focus();
                }
            }
        };
        return _this;
    }
    MenuItem.prototype.render = function () {
        var _a = this.props, dataHook = _a.dataHook, onClick = _a.onClick, children = _a.children, icon = _a.icon, className = _a.className;
        return (React.createElement("div", { "data-hook": dataHook, role: "menuitem", onKeyDown: this.handleKeyDown, tabIndex: "0", className: classnames(styles.container, className), onClick: onClick },
            React.createElement("span", { className: styles.icon }, icon),
            React.createElement("span", { className: styles.text }, children)));
    };
    MenuItem.propTypes = {
        className: PropTypes.string,
        dataHook: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
        icon: PropTypes.node,
    };
    MenuItem = __decorate([
        withStyles(styles)
    ], MenuItem);
    return MenuItem;
}(React.Component));
export default MenuItem;
