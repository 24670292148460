import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import NavArrow from './images/prev.svg';
import { withStyles } from '../../../../../../shared/utils/withStyles';
import styles from './nav-button.scss';
var navTranslations = {
    next: 'shared.navigation.next-page',
    prev: 'shared.navigation.previous-page',
};
var GenericNavButton = /** @class */ (function (_super) {
    __extends(GenericNavButton, _super);
    function GenericNavButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GenericNavButton.prototype.render = function () {
        var _a;
        var _b = this.props, name = _b.name, disabled = _b.disabled, ariaLabel = _b.ariaLabel, onClick = _b.onClick;
        var classNames = classnames(styles['nav-button'], styles[name], (_a = {},
            _a[styles.disabled] = disabled,
            _a));
        return (React.createElement("button", { className: classNames, type: "button", onClick: disabled ? null : onClick, "aria-label": ariaLabel },
            React.createElement(NavArrow, { className: styles['nav-arrow'] }),
            React.createElement("div", { className: styles['nav-text'] }, i18n.t(navTranslations[name]))));
    };
    GenericNavButton.propTypes = {
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        ariaLabel: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    };
    GenericNavButton.defaultProps = {
        rounded: false,
    };
    GenericNavButton = __decorate([
        withStyles(styles)
    ], GenericNavButton);
    return GenericNavButton;
}(React.Component));
export function PrevButton(props) {
    return (React.createElement(GenericNavButton, __assign({}, props, { ariaLabel: i18n.t('widget.accessibility.prev-videos'), name: "prev" })));
}
export function NextButton(props) {
    return (React.createElement(GenericNavButton, __assign({}, props, { ariaLabel: i18n.t('widget.accessibility.next-videos'), name: "next" })));
}
