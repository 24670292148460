import { FULL_ACCESS_ACTION_NAMES } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
export default function getCallbackForAction(props, canPlayFullOrPreview, fullAccessAction) {
    if (canPlayFullOrPreview) {
        return props.onPlayRequest;
    }
    else if (fullAccessAction === FULL_ACCESS_ACTION_NAMES.SIGN_IN) {
        return props.onPlayMemberOnlyRequest;
    }
    return props.onPlayWithoutPreviewRequest;
}
