import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import AnimateCrossfade from '../animate-crossfade/animate-crossfade';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from './main-item-slider.scss';
var MainItemSlider = /** @class */ (function (_super) {
    __extends(MainItemSlider, _super);
    function MainItemSlider(props) {
        var _this = _super.call(this, props) || this;
        _this.containerRef = null;
        _this.saveContainerRef = function (ref) {
            _this.containerRef = ref;
        };
        _this.renderItem = function (videoId, index, mainVideoIdIndex) {
            var isCurrentOrPrevOrNext = Math.abs(mainVideoIdIndex - index) <= 1;
            if (!isCurrentOrPrevOrNext) {
                return React.createElement("div", { key: videoId });
            }
            var isVisible = index === mainVideoIdIndex;
            return (React.createElement("section", { className: styles.item, "data-hook": "main-item-slider-item", "data-is-visible-overlay": isVisible ? 'visible' : null, "aria-hidden": !isVisible, key: videoId }, _this.props.renderItem(videoId, isVisible)));
        };
        _this.state = {
            wasPlayerVisible: false,
        };
        return _this;
    }
    MainItemSlider.prototype.UNSAFE_componentWillReceiveProps = function () {
        this.setState({ wasPlayerVisible: this.props.isPlayerVisible });
    };
    MainItemSlider.prototype.componentDidUpdate = function (prevProps) {
        var _this = this;
        var _a = this.props, mainVideoId = _a.mainVideoId, isMainVideoAtChannelCover = _a.isMainVideoAtChannelCover;
        var isMainVideoIdChanged = prevProps.mainVideoId !== mainVideoId;
        var isMainVideoAtChannelCoverChanged = prevProps.isMainVideoAtChannelCover !== isMainVideoAtChannelCover;
        if (isMainVideoIdChanged || isMainVideoAtChannelCoverChanged) {
            // Because of animation for 0.3s. Needed to read updated player overlay
            // onTransitionEnd can`t be used because selected video can be updated to not rendered yet
            setTimeout(function () {
                // To read just current overlay instead of all rendered
                var visibleOverlayRef = _this.containerRef &&
                    _this.containerRef.querySelector('[data-is-visible-overlay="visible"]');
                focus(visibleOverlayRef);
            }, 400);
        }
    };
    MainItemSlider.prototype.render = function () {
        var _this = this;
        var _a = this.props, loadedVideoIds = _a.loadedVideoIds, mainVideoId = _a.mainVideoId, className = _a.className, onAnimationComplete = _a.onAnimationComplete, isPlayerVisible = _a.isPlayerVisible;
        var videoIdsToRender = loadedVideoIds.length
            ? loadedVideoIds
            : [mainVideoId];
        var mainVideoIdIndex = loadedVideoIds.length
            ? loadedVideoIds.indexOf(mainVideoId)
            : 0;
        return (React.createElement("div", { className: classnames(styles.container, className), ref: memoizedPartial(this.saveContainerRef) },
            React.createElement(AnimateCrossfade, { activeClassName: isPlayerVisible ? styles.active : '', fadeInOnly: this.state.wasPlayerVisible, onAnimationComplete: onAnimationComplete, currentIdIndex: mainVideoIdIndex }, _.map(videoIdsToRender, function (videoId, index) {
                return _this.renderItem(videoId, index, mainVideoIdIndex);
            }))));
    };
    MainItemSlider.propTypes = {
        loadedVideoIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        mainVideoId: PropTypes.string.isRequired,
        renderItem: PropTypes.func.isRequired,
        onAnimationComplete: PropTypes.func,
        isMainVideoAtChannelCover: PropTypes.bool,
        isPlayerVisible: PropTypes.bool,
        className: PropTypes.string,
    };
    MainItemSlider.defaultProps = {
        isMainVideoAtChannelCover: false,
    };
    MainItemSlider = __decorate([
        withStyles(styles)
    ], MainItemSlider);
    return MainItemSlider;
}(React.Component));
export default MainItemSlider;
