import { selectVideo } from './select-video';
import { playVideo } from './player/change-playback-status';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from '../../../../shared/selectors/current-site-user';
import { getVideosGroupedByIds } from '../../../../shared/selectors/videos';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { createProxy } from '../../../../shared/worker/lib';
export var requestPlayVideo = createProxy('requestPlayVideo', function (id, muted, autoPlayed) {
    if (muted === void 0) { muted = false; }
    if (autoPlayed === void 0) { autoPlayed = false; }
    return function (dispatch, getState) {
        var state = getState();
        var videoByIds = getVideosGroupedByIds(state);
        var channelData = getChannelForWidget(state);
        var currentSiteUser = getCurrentSiteUser(state);
        var videoItem = videoByIds[id];
        var isPlayAllowed = canPlayFullOrPreview(channelData, videoItem, currentSiteUser);
        if (isPlayAllowed) {
            dispatch(playVideo(videoItem.id, muted, autoPlayed));
        }
        else {
            dispatch(selectVideo(videoItem.id));
        }
    };
});
