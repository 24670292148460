import { __read, __spreadArray } from "tslib";
import fedopsLogger from '../../loggers/fedops/new-fedops-logger';
import { openFullScreenPurchaseOverlay as openPurchaseOverlay, openFullScreenMemberOnlyOverlay as openMemberOnlyOverlay, openFullScreenSubscribeOverlay as openSubscribeOverlay, openFullScreenRentOverlay as openRentOverlay, } from '../../shared/utils/open-overlay';
import { createProxy } from '../../shared/worker/lib';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
export var openFullScreenPurchaseOverlay = createProxy('openFullScreenPurchaseOverlay', function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return function (dispatch) {
        dispatch(fedopsLogger.loadCashierModal.start());
        dispatch(openPurchaseOverlay.apply(void 0, __spreadArray([], __read(args), false)));
    };
});
export var openFullScreenMemberOnlyOverlay = createProxy('openFullScreenMemberOnlyOverlay', function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return function (dispatch) {
        dispatch(fedopsLogger.loadCashierModal.start());
        dispatch(openMemberOnlyOverlay.apply(void 0, __spreadArray([], __read(args), false)));
    };
});
export var openFullScreenSubscribeOverlay = createProxy('openFullScreenSubscribeOverlay', function (channelId, callback) { return function (dispatch, getState) {
    var info = getState().channelInfo.info;
    var isPlan = isPricingPlanEnabled(info);
    dispatch(fedopsLogger.loadCashierModal.start());
    dispatch(openSubscribeOverlay(channelId, callback, isPlan));
}; });
export var openFullScreenRentOverlay = createProxy('openFullScreenRentOverlay', function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return function (dispatch) {
        dispatch(fedopsLogger.loadCashierModal.start());
        dispatch(openRentOverlay.apply(void 0, __spreadArray([], __read(args), false)));
    };
});
