import _ from 'lodash';
import { createSelector } from 'reselect';
import { getCurrentSiteUser } from '../../../shared/selectors/current-site-user';
import { isMainItemChannelCover, canShowMainItemPublisher, canShowMainItemTitle, getMainItemOverlayOpacity, } from '../../../shared/selectors/app-settings';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getMinPriceInfo } from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import { getVideoCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/cover';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';
import renderVideoPlayButtonText from '../../../shared/components/overlay-texts/play-button-text';
import renderVideoSignInToWatchButtonText from '../../../shared/components/overlay-texts/video/sign-in-to-watch-button-text';
import renderVideoSubscribeButtonText from '../../../shared/components/overlay-texts/video/subscribe-button-text';
import renderChannelSignInToWatchButtonText from '../../../shared/components/overlay-texts/channel/sign-in-to-watch-button-text';
import renderChannelSubscribeButtonText from '../../../shared/components/overlay-texts/channel/subscribe-button-text';
import renderOpenVideoWithoutPreviewButtonText from '../../../shared/components/overlay-texts/channel/open-video-without-preview-button-text';
import renderPurchaseButtonText from '../../../shared/components/overlay-texts/purchase-button-text';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
var getIsVideosPristine = function (state) { return !state.isVideosTouched; };
var getShowChannelCover = function (state, ownProps) {
    return _.isUndefined(ownProps.showChannelCover) ? true : ownProps.showChannelCover;
};
var getVideoItem = function (state, ownProps) { return ownProps.videoItem; };
var getEndedProp = function (state, ownProps) { return ownProps.ended; };
export var canShowChannelCover = createSelector(getIsVideosPristine, isMainItemChannelCover, getShowChannelCover, function (isPristine, isSettingSet, showChannelCover) {
    return isPristine && isSettingSet && showChannelCover;
});
export var getTitleText = createSelector(canShowMainItemTitle, canShowChannelCover, getChannelForWidget, getVideoItem, function (canShowTitle, isChannelCover, channelData, videoItem) {
    if (!canShowTitle) {
        return '';
    }
    return isChannelCover ? channelData.title : videoItem.title;
});
var getTextRendererProps = createSelector(getChannelForWidget, getVideoItem, getCurrentSiteUser, function (channel, video, currentSiteUser) { return ({
    channel: channel,
    video: video,
    currentSiteUser: currentSiteUser,
}); });
export var getSubscribeButtonText = createSelector(getTextRendererProps, canShowChannelCover, function (props, isChannelCover) {
    return (isChannelCover
        ? renderChannelSubscribeButtonText(props)
        : renderVideoSubscribeButtonText(props)) || '';
});
export var getPublisherText = createSelector(canShowMainItemPublisher, getVideoItem, canShowChannelCover, function (isSettingOn, videoItem, isChannelCover) {
    if (!isSettingOn || !videoItem.publisher) {
        return '';
    }
    return isChannelCover ? '' : videoItem.publisher;
});
export var getOpacityStyle = createSelector(getMainItemOverlayOpacity, function (opacityPercents) { return ({
    backgroundColor: "rgba(0,0,0,".concat(_.round(opacityPercents / 100, 2), ")"),
}); });
export var getPlayButtonText = createSelector(getTextRendererProps, canShowChannelCover, function (props, isChannelCover) {
    if (isChannelCover) {
        return (renderVideoPlayButtonText(props) ||
            renderChannelSignInToWatchButtonText(props) ||
            renderOpenVideoWithoutPreviewButtonText(props));
    }
    return (renderVideoPlayButtonText(props) ||
        renderVideoSignInToWatchButtonText(props));
});
export var getPurchaseButtonText = createSelector(getTextRendererProps, canShowChannelCover, function (props, isChannelCover) {
    return isChannelCover ? '' : renderPurchaseButtonText(props);
});
var getMinVideoPriceInfo = createSelector(getVideoItem, function (videoItem) {
    return videoItem ? getMinPriceInfo(videoItem.dealInfo) : null;
});
var getNextVideoId = function (state, ownProps) { return ownProps.nextVideoId; };
var getHasNextVideo = createSelector(getNextVideoId, function (nextVideoId) {
    return Boolean(nextVideoId);
});
export var getPlayNextButtonText = createSelector(getTextRendererProps, getEndedProp, getMinVideoPriceInfo, getHasNextVideo, function (props, ended, minVideoPriceInfo, hasNextVideo) {
    if (!ended || minVideoPriceInfo || !hasNextVideo) {
        return null;
    }
    return i18n.t('widget.overlay-buttons.next-video');
});
export var getCoverUrl = createSelector(canShowChannelCover, getChannelForWidget, getVideoItem, function (isChannelCover, channel, video) {
    var channelCover = getChannelCoverUrl(channel);
    var videoCover = getVideoCoverUrl(video);
    return isChannelCover ? channelCover || videoCover : videoCover;
});
