import { __awaiter, __decorate, __extends, __generator } from "tslib";
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WixSDK from '../../../shared/utils/wix-sdk';
import { getVodSettings } from '../../../api/private/get-vod-settings';
import { getSettings } from '../../../api/v3/private/settings';
import { isUnsavedTemplateSite } from '../../redux/client/hydrated-data/hydrated-data';
import { isPrivateGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
var PremiumIntentUpdater = /** @class */ (function (_super) {
    __extends(PremiumIntentUpdater, _super);
    function PremiumIntentUpdater() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PremiumIntentUpdater.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isUnsavedTemplateSite, vodSettings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isUnsavedTemplateSite = this.props.isUnsavedTemplateSite;
                        if (isUnsavedTemplateSite) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, (isPrivateGraphAPIEnabled()
                                ? getSettings()
                                : getVodSettings())];
                    case 1:
                        vodSettings = _a.sent();
                        WixSDK.Settings.appEngaged(vodSettings.biUserUploadedFiles
                            ? WixSDK.Settings.PremiumIntent.PAID
                            : WixSDK.Settings.PremiumIntent.FREE);
                        return [2 /*return*/];
                }
            });
        });
    };
    PremiumIntentUpdater.prototype.render = function () {
        return null;
    };
    PremiumIntentUpdater.propTypes = {
        isUnsavedTemplateSite: PropTypes.bool.isRequired,
    };
    PremiumIntentUpdater = __decorate([
        connect(function (state) { return ({
            isUnsavedTemplateSite: isUnsavedTemplateSite(state),
        }); })
    ], PremiumIntentUpdater);
    return PremiumIntentUpdater;
}(React.Component));
export default PremiumIntentUpdater;
