import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notForPreview } from '../../../../../shared/utils/not-for-preview';
import { logBi } from '../../../../../shared/worker/actions/bi';
import { getCurrentSiteUser } from '../../../../../shared/selectors/current-site-user';
import { openFullScreenSubscribeOverlay, openFullScreenPurchaseOverlay, openFullScreenRentOverlay, } from '../../../../utils/open-overlay';
var StripPlayerWrapper = /** @class */ (function (_super) {
    __extends(StripPlayerWrapper, _super);
    function StripPlayerWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.openPurchase = notForPreview(function () {
            var _a = _this.props, videoItem = _a.videoItem, channelData = _a.channelData;
            _this.props.openFullScreenPurchaseOverlay(channelData.id, videoItem.id);
        });
        _this.openRent = notForPreview(function () {
            var _a = _this.props, videoItem = _a.videoItem, channelData = _a.channelData;
            _this.props.openFullScreenRentOverlay(channelData.id, videoItem.id);
        });
        _this.openSubscription = notForPreview(function () {
            var channelData = _this.props.channelData;
            _this.props.openFullScreenSubscribeOverlay(channelData.id, function () {
                _this.props.logBi('widget.subscriptionPopUp.Completed', {
                    channelID: channelData.id,
                    result: 'canceled',
                    errorMsg: '',
                });
            });
        });
        return _this;
    }
    StripPlayerWrapper.prototype.render = function () {
        var props = _.omit(this.props, 'mobileMode', // can't do this
        'playAtFullScreen', // can't do this
        'showInitialOverlay', // can't do this
        'showControls', // can't do this
        'showPoster', // can't do this
        'currentTime', // can't do this
        'paused', // can't do this
        'muted', // can't do this
        'onPurchaseClick', 'onSubscribeClick', 'onRentClick');
        var PlayerComponent = this.props.PlayerComponent;
        return (React.createElement(PlayerComponent, __assign({}, props, { playAtFullScreen: false, showPlayer: false, showInitialOverlay: true, showControls: true, showPoster: true, paused: true, shouldLoadVideo: false, onPurchaseClick: this.openPurchase, onSubscribeClick: this.openSubscription, onRentClick: this.openRent })));
    };
    StripPlayerWrapper.propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        videoUrls: PropTypes.object,
        size: PropTypes.object,
        simple: PropTypes.bool,
        overlay: PropTypes.any,
        onPlayStart: PropTypes.func,
        onEnded: PropTypes.func,
        preload: PropTypes.string,
        reportStats: PropTypes.func,
        fetchVideoUrls: PropTypes.func,
        appSettings: PropTypes.object,
        mainOrThumbnailLocation: PropTypes.string,
        loop: PropTypes.bool,
        onSubscribeClick: PropTypes.func,
        onPurchaseClick: PropTypes.func,
        isVideoCardsEnabled: PropTypes.bool,
        fetchVideoCards: PropTypes.func,
        onPlayRequest: PropTypes.func,
        shouldLoadVideo: PropTypes.bool,
        isOverQuota: PropTypes.bool,
        isLive: PropTypes.bool,
        cleanupVideoCards: PropTypes.func,
        isInLightbox: PropTypes.bool,
        isAutoplayed: PropTypes.bool,
        handleVideoEnd: PropTypes.func,
        canShowChannelCover: PropTypes.bool,
        onMemberSignUp: PropTypes.func,
        isUserLogged: PropTypes.bool,
        videoItem: PropTypes.object,
        channelData: PropTypes.object,
        currentSiteUser: PropTypes.object,
        PlayerComponent: PropTypes.func,
    };
    StripPlayerWrapper = __decorate([
        connect(function (state) { return ({
            currentSiteUser: getCurrentSiteUser(state),
        }); }, {
            logBi: logBi,
            openFullScreenSubscribeOverlay: openFullScreenSubscribeOverlay,
            openFullScreenPurchaseOverlay: openFullScreenPurchaseOverlay,
            openFullScreenRentOverlay: openFullScreenRentOverlay,
        })
    ], StripPlayerWrapper);
    return StripPlayerWrapper;
}(React.Component));
export default StripPlayerWrapper;
