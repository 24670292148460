import { createSelector } from 'reselect';
import { getCurrentSiteUser } from '../../../../shared/selectors/current-site-user';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { isVideoFromComputer, isVideoOriginallyLive, } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
var getVideos = function (state) { return state.entities.videos; };
/**
 * @param state
 * @param listType
 * @returns main video item
 */
var getMainVideo = createSelector([getVideos, getMainVideoId], function (videos, mainVideoId) { return mainVideoId && videos[mainVideoId]; });
/**
 * @param state
 * @param listType
 * @returns {Boolean}
 */
export var canPlayVideoInFrame = createSelector([getChannelForWidget, getMainVideo, getCurrentSiteUser], function (channel, mainVideo, currentSiteUser) {
    return Boolean(mainVideo &&
        (isVideoFromComputer(mainVideo) || isVideoOriginallyLive(mainVideo)) &&
        canPlayFullOrPreview(channel, mainVideo, currentSiteUser));
});
