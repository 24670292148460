import { sides } from '../../constants/popout';
export default function invertSide(side) {
    var _a;
    return ((_a = {},
        _a[sides.TOP] = sides.BOTTOM,
        _a[sides.LEFT] = sides.RIGHT,
        _a[sides.RIGHT] = sides.LEFT,
        _a[sides.BOTTOM] = sides.TOP,
        _a)[side] || sides.NONE);
}
