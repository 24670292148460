import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestPlayVideoOnload } from '../../../widget/redux/client/actions/request-play-video-onload';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getAutoplay } from '../../selectors/app-settings';
import { getMainVideo } from '../../../widget/selectors/get-video';
import { getVideoIdFromUrl } from '../../../widget/selectors/query-params';
import * as viewModeSelectors from '../../../widget/selectors/view-mode';
var AutoPlayVideo = /** @class */ (function (_super) {
    __extends(AutoPlayVideo, _super);
    function AutoPlayVideo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AutoPlayVideo.prototype.UNSAFE_componentWillMount = function () {
        var _a = this.props, videoIdFromUrl = _a.videoIdFromUrl, isAutoPlay = _a.isAutoPlay, isMobile = _a.isMobile, requestPlayVideoOnload = _a.requestPlayVideoOnload, mainVideoId = _a.mainVideoId, isSite = _a.isSite, isStandalone = _a.isStandalone;
        if (isAutoPlay && !videoIdFromUrl && (isSite || isStandalone)) {
            requestPlayVideoOnload(mainVideoId, isMobile);
        }
    };
    AutoPlayVideo.prototype.render = function () {
        return null;
    };
    AutoPlayVideo.propTypes = {
        dataHook: PropTypes.string,
        isAutoPlay: PropTypes.bool,
        isMobile: PropTypes.bool,
        mainVideoId: PropTypes.string.isRequired,
        requestPlayVideoOnload: PropTypes.func.isRequired,
        videoIdFromUrl: PropTypes.string,
        isSite: PropTypes.bool.isRequired,
        isStandalone: PropTypes.bool.isRequired,
    };
    AutoPlayVideo.defaultProps = {
        dataHook: 'autoplay-video',
        isMobile: false,
    };
    AutoPlayVideo = __decorate([
        connect(function (state) { return ({
            mainVideo: getMainVideo(state),
            mainVideoId: getMainVideoId(state),
            isAutoPlay: getAutoplay(state),
            videoIdFromUrl: getVideoIdFromUrl(state),
            isSite: viewModeSelectors.isSiteMode(state),
            isStandalone: viewModeSelectors.isStandalone(state),
        }); }, {
            requestPlayVideoOnload: requestPlayVideoOnload,
        })
    ], AutoPlayVideo);
    return AutoPlayVideo;
}(React.PureComponent));
export default AutoPlayVideo;
