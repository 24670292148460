export var DIMENSIONS = 16 / 9;
export var MIN_WIDTH = 200;
export var MIN_HEIGHT = Math.round(MIN_WIDTH / DIMENSIONS);
var EMBED_DEV_API_ENDPOINT = 'http://localhost:4000';
var EMBED_PROD_API_ENDPOINT = 'https://embed.wix.com/video';
export var EMBED_API_ENDPOINT = process.env.NODE_ENV === 'production'
    ? EMBED_PROD_API_ENDPOINT
    : EMBED_DEV_API_ENDPOINT;
export var calculateHeight = function (width) { return Math.round(Number(width) / DIMENSIONS); };
export var calculateWidth = function (height) { return Math.round(Number(height) * DIMENSIONS); };
