import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isClassicLayout, isStripLayout, isCompactLayout, isSliderLayout, isGridLayout, isPlayInFrame, } from '../../../shared/selectors/app-settings';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { selectVideo, resetSelectedVideo, } from '../../redux/client/actions/select-video';
import { ensureVideoLoaded } from '../../redux/server/actions/video/ensure-video-loaded';
import { setSavedTime } from '../../redux/client/actions/player/set-saved-time';
import { getCurrentChannelId } from '../../selectors/channel-info';
import { requestPlayVideo } from '../../redux/client/actions/request-play-video';
import { openFullScreenVideoOverlay, closeFullScreenVideoOverlay, } from '../../redux/client/actions/full-screen-modal';
import { PersistentEvents } from '../../components/persistent-events/persistent-events';
var PersistentEventsHandler = /** @class */ (function (_super) {
    __extends(PersistentEventsHandler, _super);
    function PersistentEventsHandler() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleEvent = function (eventData) {
            var selectedVideoId = eventData.selectedVideoId, savedPlayerTime = eventData.savedPlayerTime, openOverlay = eventData.openOverlay;
            var _a = _this.props, resetSelectedVideo = _a.resetSelectedVideo, setSavedTime = _a.setSavedTime;
            if (!selectedVideoId) {
                resetSelectedVideo();
                return;
            }
            if (savedPlayerTime && !openOverlay) {
                setSavedTime(savedPlayerTime);
            }
            _this.tryPlayVideoForLayout(eventData);
        };
        return _this;
    }
    PersistentEventsHandler.prototype.tryPlayVideoForLayout = function (eventData) {
        var autoplay = eventData.autoplay, selectedVideoId = eventData.selectedVideoId, openOverlay = eventData.openOverlay;
        var _a = this.props, isPlayInFrame = _a.isPlayInFrame, isStrip = _a.isStrip, isCompact = _a.isCompact, isGrid = _a.isGrid, isSlider = _a.isSlider, mainVideoId = _a.mainVideoId, requestPlayVideo = _a.requestPlayVideo, selectVideo = _a.selectVideo, ensureVideoLoaded = _a.ensureVideoLoaded, channel = _a.channel, openFullScreenVideoOverlay = _a.openFullScreenVideoOverlay, closeFullScreenVideoOverlay = _a.closeFullScreenVideoOverlay;
        if (openOverlay) {
            var isPlaysLightbox = (isCompact || isGrid || isSlider) && !isPlayInFrame;
            if (isStrip || isPlaysLightbox) {
                openFullScreenVideoOverlay(channel.id, selectedVideoId, autoplay, closeFullScreenVideoOverlay);
                return;
            }
        }
        ensureVideoLoaded(selectedVideoId).then(function () {
            if (mainVideoId !== selectedVideoId) {
                selectVideo(selectedVideoId);
            }
            if (!isStrip && autoplay) {
                // Strip always plays in lightbox
                requestPlayVideo(selectedVideoId);
            }
        });
    };
    PersistentEventsHandler.prototype.render = function () {
        return React.createElement(PersistentEvents, { onEvent: this.handleEvent });
    };
    PersistentEventsHandler.propTypes = {
        isClassic: PropTypes.bool.isRequired,
        isStrip: PropTypes.bool.isRequired,
        isCompact: PropTypes.bool.isRequired,
        isSlider: PropTypes.bool.isRequired,
        isGrid: PropTypes.bool.isRequired,
        isPlayInFrame: PropTypes.bool.isRequired,
        currentChannelId: PropTypes.string.isRequired,
        mainVideoId: PropTypes.string.isRequired,
        channel: PropTypes.object.isRequired,
        selectVideo: PropTypes.func.isRequired,
        ensureVideoLoaded: PropTypes.func.isRequired,
        resetSelectedVideo: PropTypes.func.isRequired,
        setSavedTime: PropTypes.func.isRequired,
        requestPlayVideo: PropTypes.func.isRequired,
        openFullScreenVideoOverlay: PropTypes.func.isRequired,
        closeFullScreenVideoOverlay: PropTypes.func.isRequired,
    };
    PersistentEventsHandler = __decorate([
        connect(function (state) { return ({
            isClassic: isClassicLayout(state),
            isStrip: isStripLayout(state),
            isCompact: isCompactLayout(state),
            isSlider: isSliderLayout(state),
            isGrid: isGridLayout(state),
            isPlayInFrame: isPlayInFrame(state),
            currentChannelId: getCurrentChannelId(state),
            mainVideoId: getMainVideoId(state),
            channel: getChannelForWidget(state),
        }); }, {
            selectVideo: selectVideo,
            ensureVideoLoaded: ensureVideoLoaded,
            resetSelectedVideo: resetSelectedVideo,
            setSavedTime: setSavedTime,
            requestPlayVideo: requestPlayVideo,
            openFullScreenVideoOverlay: openFullScreenVideoOverlay,
            closeFullScreenVideoOverlay: closeFullScreenVideoOverlay,
        })
    ], PersistentEventsHandler);
    return PersistentEventsHandler;
}(React.Component));
export { PersistentEventsHandler };
