import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logWidgetVidPlayStart } from '../../../shared/utils/bi/widget-common-events';
import VideoPlayer from '../../player-widget/components/new-player/player';
import { logBi } from '../../../shared/worker/actions/bi';
import { getBuyButtonDisplayType } from '@wix/wix-vod-shared/dist/src/common/utils/overlay-buttons-utils';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { convertSToMS } from '@wix/wix-vod-shared/dist/src/widget/utils/time';
import { shouldFetchUrls } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/fetch-urls';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import { isVideoOverQuota } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/quota';
import getVideoUrls from '../../selectors/get-video-urls';
import { getAllSettings } from '../../../shared/selectors/app-settings';
import { getCurrentSiteUser } from '../../../shared/selectors/current-site-user';
import { isAutoplayed } from '../../../shared/selectors/player';
import { fetchVideoUrls } from '../../redux/client/actions/video-urls/fetch';
import { reportStats } from '../../redux/server/actions/stats/report';
import { fetchVideoCards } from '../../redux/server/actions/video-cards/get';
import { cleanupVideoCards } from '../../redux/client/actions/video-cards/cleanup';
import { isVideoCardsEnabled } from '../../selectors/video-cards';
import STATS_EVENTS from '../../../shared/constants/stats/events';
import translations from '@wix/wix-vod-shared/dist/src/common/i18n/translations';
import { ERRORS } from 'playable/dist/src/constants';
import fedopsLogger from '../../../loggers/fedops/new-fedops-logger';
/*
 * PlayerWrapper does these while wrapping VODPlayer:
 * - sends BI
 * - logs statistics
 * - checks memberOnly setting
 */
var PlayerWithStatAndBI = /** @class */ (function (_super) {
    __extends(PlayerWithStatAndBI, _super);
    function PlayerWithStatAndBI() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.reloadCounts = {};
        _this.reloadVideoUrls = function (error) {
            if (!error) {
                return;
            }
            var _a = _this.props, fetchVideoUrls = _a.fetchVideoUrls, videoItem = _a.videoItem;
            var id = videoItem.id;
            _this.reloadCounts[id] = _this.reloadCounts[id] || 0;
            _this.reloadCounts[id] += 1;
            if (_this.reloadCounts[id] > 1) {
                return;
            }
            switch (error.errorType) {
                case ERRORS.MANIFEST_LOAD:
                case ERRORS.LEVEL_LOAD:
                case ERRORS.CONTENT_LOAD:
                    fetchVideoUrls(videoItem, true);
                    break;
                default:
                    break;
            }
        };
        _this.onPlayStart = function () {
            _this.props.onPlayStart();
            _this.sendVidStartPlayBI();
            _this.props.autoplayLiveInteractionEnd();
        };
        _this.onEnded = function () {
            var _a = _this.props, onEnded = _a.onEnded, reportStats = _a.reportStats, channelData = _a.channelData, videoItem = _a.videoItem, currentSiteUser = _a.currentSiteUser, handleVideoEnd = _a.handleVideoEnd;
            var event;
            _this.sendVidPlayedBI();
            if (canAccessFullVideo(channelData, videoItem, currentSiteUser)) {
                event = STATS_EVENTS.VIDEO_PLAY_COMPLETE;
            }
            else {
                event = STATS_EVENTS.VIDEO_PLAY_TRAILER;
            }
            reportStats(event, {
                channel: channelData,
                videoItem: videoItem,
            });
            onEnded();
            handleVideoEnd();
        };
        _this.sendBIForVideoInfoShow = function () {
            _this.sendBI('widget.vid.info.clicked');
        };
        _this.sendBIForPurchaseRequested = function () {
            _this.sendBuyBI('widget.vid.buy.clicked');
        };
        _this.sendBIForShareRequested = function (networkName) {
            var _a = _this.props, reportStats = _a.reportStats, channelData = _a.channelData, videoItem = _a.videoItem;
            reportStats(STATS_EVENTS.VIDEO_SHARE, {
                channel: channelData,
                videoItem: videoItem,
                network: {
                    name: networkName,
                },
            });
            _this.sendBI('widget.vid.share.clicked', {
                button: networkName,
            });
        };
        _this.sendBIForFullScreenChanged = function (isFullScreen) {
            var _a = _this.props, channelData = _a.channelData, videoItem = _a.videoItem;
            if (isFullScreen) {
                _this.props.logBi('widget.vid.fullScreen.entered', {
                    channelID: channelData.id,
                    videoID: videoItem.id,
                    origin: 'on_site',
                });
            }
            _this.props.onFullScreenChanged(isFullScreen);
        };
        _this.onPurchaseRequest = function () {
            var onPurchaseClick = _this.props.onPurchaseClick;
            _this.sendBIForPurchaseRequested();
            onPurchaseClick();
        };
        _this.onRentRequest = function () {
            var onRentClick = _this.props.onRentClick;
            _this.sendBIForPurchaseRequested();
            onRentClick();
        };
        _this.onSubscribeRequest = function () {
            var onSubscribeClick = _this.props.onSubscribeClick;
            _this.sendBIForPurchaseRequested();
            onSubscribeClick();
        };
        return _this;
    }
    PlayerWithStatAndBI.prototype.componentDidMount = function () {
        this.checkVideoUrls(this.props);
        this.updateVideoCards(this.props.videoItem);
    };
    PlayerWithStatAndBI.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        this.checkVideoUrls(nextProps);
    };
    PlayerWithStatAndBI.prototype.componentDidUpdate = function (prevProps) {
        var isVideoCardsEnabledChanged = !prevProps.isVideoCardsEnabled && this.props.isVideoCardsEnabled;
        var isVideoIdChanged = prevProps.videoItem.id !== this.props.videoItem.id;
        if (isVideoIdChanged || isVideoCardsEnabledChanged) {
            this.updateVideoCards(this.props.videoItem);
        }
    };
    PlayerWithStatAndBI.prototype.checkVideoUrls = function (_a) {
        var fetchVideoUrls = _a.fetchVideoUrls, videoUrls = _a.videoUrls, channelData = _a.channelData, videoItem = _a.videoItem, currentSiteUser = _a.currentSiteUser, shouldLoadVideo = _a.shouldLoadVideo;
        if (shouldLoadVideo &&
            shouldFetchUrls({ videoUrls: videoUrls, channelData: channelData, videoItem: videoItem, currentSiteUser: currentSiteUser })) {
            fetchVideoUrls(videoItem);
        }
    };
    PlayerWithStatAndBI.prototype.updateVideoCards = function (videoItem) {
        var _a = this.props, isVideoCardsEnabled = _a.isVideoCardsEnabled, fetchVideoCards = _a.fetchVideoCards, shouldLoadVideo = _a.shouldLoadVideo, cleanupVideoCards = _a.cleanupVideoCards;
        if (!shouldLoadVideo || !videoItem) {
            return;
        }
        if (!isVideoCardsEnabled || !videoItem.hasCards) {
            cleanupVideoCards();
        }
        else {
            fetchVideoCards(videoItem.listId, videoItem.id);
        }
    };
    Object.defineProperty(PlayerWithStatAndBI.prototype, "isUserLogged", {
        get: function () {
            var currentSiteUser = this.props.currentSiteUser;
            return Boolean(currentSiteUser);
        },
        enumerable: false,
        configurable: true
    });
    PlayerWithStatAndBI.prototype.sendVidStartPlayBI = function () {
        var _a = this.props, button = _a.mainOrThumbnailLocation, channelData = _a.channelData, videoItem = _a.videoItem;
        this.props.logWidgetVidPlayStart({
            channelData: channelData,
            videoItem: videoItem,
            button: button,
        });
    };
    PlayerWithStatAndBI.prototype.sendVidPlayedBI = function () {
        var _a = this.props, button = _a.mainOrThumbnailLocation, channelData = _a.channelData, _b = _a.videoItem, id = _b.id, durationSec = _b.durationSec, isLive = _a.isLive, isAutoplayed = _a.isAutoplayed;
        this.props.logBi('widget.vid.played', {
            channelID: channelData.id,
            videoID: id,
            button: button,
            isLive: isLive,
            duration: convertSToMS(durationSec),
            isAutoplay: isAutoplayed,
        });
    };
    PlayerWithStatAndBI.prototype.sendBI = function (name, params) {
        var _a = this.props, channelData = _a.channelData, videoItem = _a.videoItem;
        var id = videoItem.id, videoSource = videoItem.videoSource;
        this.props.logBi(name, _.assign({}, params, {
            channelID: channelData.id,
            videoID: id,
            videoType: videoSource,
        }));
    };
    PlayerWithStatAndBI.prototype.sendBuyBI = function (name) {
        var _a = this.props, videoItem = _a.videoItem, channelData = _a.channelData, button = _a.mainOrThumbnailLocation;
        var buyType = getBuyButtonDisplayType(channelData, videoItem);
        this.sendBI(name, {
            buyType: buyType,
            button: button,
        });
    };
    PlayerWithStatAndBI.prototype.render = function () {
        var _a = this.props, size = _a.size, isOverQuota = _a.isOverQuota, fillAllSpace = _a.fillAllSpace;
        var props = _.omit(this.props, 'size', 'reportStats');
        return (React.createElement(VideoPlayer, __assign({}, props, size, { isFetchDisabled: true, translations: translations.getAll(), onPlayStart: this.onPlayStart, onEnded: this.onEnded, onError: this.reloadVideoUrls, onPurchaseRequest: this.onPurchaseRequest, onRentRequest: this.onRentRequest, onSubscribeRequest: this.onSubscribeRequest, onShareRequest: this.sendBIForShareRequested, onVideoInfoShow: this.sendBIForVideoInfoShow, onFullScreenChanged: this.sendBIForFullScreenChanged, isOverQuota: isOverQuota, fillAllSpace: fillAllSpace })));
    };
    PlayerWithStatAndBI.propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        videoUrls: PropTypes.object,
        videoItem: PropTypes.object,
        // remove after new player
        channelData: PropTypes.object,
        size: PropTypes.object,
        simple: PropTypes.bool,
        mobileMode: PropTypes.bool,
        overlay: PropTypes.any,
        onFullScreenChanged: PropTypes.func,
        onPlayStart: PropTypes.func,
        onEnded: PropTypes.func,
        preload: PropTypes.string,
        reportStats: PropTypes.func,
        fetchVideoUrls: PropTypes.func,
        appSettings: PropTypes.object,
        canShowChannelCover: PropTypes.bool,
        mainOrThumbnailLocation: PropTypes.string,
        muted: PropTypes.bool,
        loop: PropTypes.bool,
        showInitialOverlay: PropTypes.bool,
        currentSiteUser: PropTypes.object,
        onMemberSignUp: PropTypes.func,
        currentTime: PropTypes.number,
        abortPlayVideo: PropTypes.func,
        onPause: PropTypes.func,
        onResume: PropTypes.func,
        onSubscribeClick: PropTypes.func,
        onPurchaseClick: PropTypes.func,
        onRentClick: PropTypes.func,
        isVideoCardsEnabled: PropTypes.bool,
        fetchVideoCards: PropTypes.func,
        onPlayRequest: PropTypes.func,
        shouldLoadVideo: PropTypes.bool,
        isOverQuota: PropTypes.bool,
        isLive: PropTypes.bool,
        cleanupVideoCards: PropTypes.func,
        isInLightbox: PropTypes.bool,
        isAutoplayed: PropTypes.bool,
        isVideoPlayRequested: PropTypes.bool,
        isVideoPauseRequested: PropTypes.bool,
        isVideoPlaying: PropTypes.bool,
        isVideoPaused: PropTypes.bool,
        isVideoPlayingOptimistic: PropTypes.bool,
        isVideoPausedOptimistic: PropTypes.bool,
        isVideoPlayAborted: PropTypes.bool,
        fillAllSpace: PropTypes.bool,
        handleVideoEnd: PropTypes.func,
    };
    PlayerWithStatAndBI.defaultProps = {
        abortPlayVideo: _.noop,
        onFullScreenChanged: _.noop,
        onPlayStart: _.noop,
        onEnded: _.noop,
        onMemberSignUp: _.noop,
        onSubscribeClick: _.noop,
        onPurchaseClick: _.noop,
        onRentClick: _.noop,
        handleVideoEnd: _.noop,
        mainOrThumbnailLocation: 'main',
        simple: false,
        mobileMode: false,
        onPlayRequest: _.noop,
        shouldLoadVideo: true,
        isLive: false,
    };
    PlayerWithStatAndBI = __decorate([
        connect(function (state, props) {
            var video = _.get(props, 'videoItem', {});
            return {
                videoUrls: getVideoUrls(state, props),
                appSettings: getAllSettings(state),
                currentSiteUser: getCurrentSiteUser(state),
                isVideoCardsEnabled: isVideoCardsEnabled(state, video),
                isOverQuota: isVideoOverQuota(video),
                isLive: isLiveVideo(video),
                isAutoplayed: isAutoplayed(state),
            };
        }, {
            reportStats: reportStats,
            fetchVideoUrls: fetchVideoUrls,
            fetchVideoCards: fetchVideoCards,
            cleanupVideoCards: cleanupVideoCards,
            logBi: logBi,
            logWidgetVidPlayStart: logWidgetVidPlayStart,
            autoplayLiveInteractionEnd: fedopsLogger.autoplayLive.end,
        })
    ], PlayerWithStatAndBI);
    return PlayerWithStatAndBI;
}(React.Component));
export default PlayerWithStatAndBI;
