import _ from 'lodash';
import { createSelector } from 'reselect';
import { MediaStreamType as MEDIA_STREAM_TYPES } from 'playable/dist/src/constants';
var getVideoUrls = function (props) { return props.videoUrls; };
function toVidiTypes(url, type) {
    if (/mp4/i.test(type)) {
        return {
            url: url,
            type: MEDIA_STREAM_TYPES.MP4,
            name: /[0-9]+p/i.exec(type)[0],
        };
    }
    if (/mpd/i.test(type)) {
        return { url: url, type: MEDIA_STREAM_TYPES.DASH };
    }
    if (/m3u8/i.test(type)) {
        return { url: url, type: MEDIA_STREAM_TYPES.HLS };
    }
    if (/webm/i.test(type)) {
        return { url: url, type: MEDIA_STREAM_TYPES.WEBM };
    }
    return null;
}
export var getDownloadUrl = createSelector([getVideoUrls], function (videoUrls) { return videoUrls && videoUrls.downloadUrl; });
var getVidiUrls = createSelector([getVideoUrls], function (videoUrls) {
    return _.map(videoUrls, toVidiTypes);
});
export default getVidiUrls;
