import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { subscribeButtonText } from '../../ui-selectors/buttons';
var HomeView = /** @class */ (function (_super) {
    __extends(HomeView, _super);
    function HomeView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HomeView.prototype.render = function () {
        var _a = this.props, text = _a.text, className = _a.className, onClick = _a.onClick;
        return text ? (React.createElement("div", { "data-hook": "subscribe-button", className: className, onClick: onClick }, text)) : null;
    };
    HomeView.propTypes = {
        text: PropTypes.element,
        videoItem: PropTypes.object,
        onClick: PropTypes.func.isRequired,
        className: PropTypes.string,
    };
    HomeView = __decorate([
        connect(function (state, props) { return ({
            text: subscribeButtonText(state, props),
        }); })
    ], HomeView);
    return HomeView;
}(React.Component));
export default HomeView;
