import { __assign } from "tslib";
import React from 'react';
import { WidgetComponent as DesktopWidgetComponent } from './widget/widget-ooi';
import { WidgetComponent as MobileWidgetComponent } from './mobile/mobile-ooi';
export default {
    component: function (props) {
        var isResponsive = props.appState.appSettings.booleans.responsive;
        var isDesktop = ['Desktop', 'Tablet'].includes(props.host.formFactor);
        var Component = isResponsive || isDesktop
            ? DesktopWidgetComponent
            : MobileWidgetComponent;
        return React.createElement(Component, __assign({}, props));
    },
};
