import { __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
var AccessibleVideosContainer = /** @class */ (function (_super) {
    __extends(AccessibleVideosContainer, _super);
    function AccessibleVideosContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.saveContainerRef = function (ref) {
            _this.containerRef = ref;
            _this.props.onRef(ref);
        };
        return _this;
    }
    AccessibleVideosContainer.prototype.componentDidUpdate = function (prevProps) {
        var currentVideosPageNumber = this.props.currentVideosPageNumber;
        var isPageChanged = prevProps.currentVideosPageNumber !== currentVideosPageNumber;
        if (isPageChanged) {
            focus(this.containerRef);
        }
        if (this.checkIsSearchJustEnded(prevProps)) {
            focus(this.containerRef);
        }
    };
    AccessibleVideosContainer.prototype.checkIsSearchJustEnded = function (prevProps) {
        var _a = this.props, currentVideosPageNumber = _a.currentVideosPageNumber, videoIdsByPageNumber = _a.videoIdsByPageNumber, isVideosFetching = _a.isVideosFetching, isChannelHasVideos = _a.isChannelHasVideos, isSearching = _a.isSearching;
        if (!prevProps.isSearching || isSearching) {
            return;
        }
        var isVideosFetchingEnded = !isVideosFetching && prevProps.isVideosFetching !== isVideosFetching;
        var isVideosPresent = _.get(videoIdsByPageNumber[currentVideosPageNumber], 'length');
        var isVideosAtCurrentPageChanged = !_.isEqual(prevProps.videoIdsByPageNumber[currentVideosPageNumber], videoIdsByPageNumber[currentVideosPageNumber]);
        return (isVideosFetchingEnded &&
            isChannelHasVideos &&
            (isVideosAtCurrentPageChanged || !isVideosPresent));
    };
    AccessibleVideosContainer.prototype.isNoSearchResults = function () {
        var _a = this.props, videoIdsByPageNumber = _a.videoIdsByPageNumber, searchQuery = _a.searchQuery, isVideosFetching = _a.isVideosFetching;
        return !isVideosFetching && searchQuery && !videoIdsByPageNumber.length;
    };
    AccessibleVideosContainer.prototype.getVideosContainerAriaLabel = function () {
        var _a = this.props, channelTitle = _a.channelTitle, currentVideosPageNumber = _a.currentVideosPageNumber, searchQuery = _a.searchQuery;
        if (this.isNoSearchResults()) {
            return null;
        }
        if (searchQuery) {
            var translationKey_1 = currentVideosPageNumber === 0
                ? 'widget.accessibility.search-results-for'
                : 'widget.accessibility.search-results-page-n-for';
            return i18n.t(translationKey_1, {
                searchQuery: searchQuery,
                pageNumber: currentVideosPageNumber + 1,
            });
        }
        var translationKey = currentVideosPageNumber === 0
            ? 'widget.accessibility.channel-videos'
            : 'widget.accessibility.channel-videos-page-n';
        return i18n.t(translationKey, {
            channelTitle: channelTitle,
            pageNumber: currentVideosPageNumber + 1,
        });
    };
    AccessibleVideosContainer.prototype.render = function () {
        var _a = this.props, className = _a.className, style = _a.style, children = _a.children, dataHook = _a.dataHook;
        return (React.createElement("section", { className: className, style: style, "data-hook": dataHook, ref: this.saveContainerRef, tabIndex: "0", "aria-label": this.getVideosContainerAriaLabel() }, children));
    };
    AccessibleVideosContainer.propTypes = {
        className: PropTypes.string.isRequired,
        channelTitle: PropTypes.string.isRequired,
        isChannelHasVideos: PropTypes.bool.isRequired,
        isVideosFetching: PropTypes.bool.isRequired,
        isSearching: PropTypes.bool.isRequired,
        videoIdsByPageNumber: PropTypes.array.isRequired,
        searchQuery: PropTypes.string,
        currentVideosPageNumber: PropTypes.number.isRequired,
        children: PropTypes.node.isRequired,
        onRef: PropTypes.func,
        style: PropTypes.object,
        dataHook: PropTypes.string,
    };
    AccessibleVideosContainer.defaultProps = {
        onRef: _.noop,
    };
    return AccessibleVideosContainer;
}(React.Component));
export default AccessibleVideosContainer;
