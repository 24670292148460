import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isInFullScreen } from '../selectors/player';
import { updateWindowSize } from '../../widget/redux/client/actions/window-size';
var WindowSize = /** @class */ (function (_super) {
    __extends(WindowSize, _super);
    function WindowSize() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.updateSize = function () {
            // Need to wait for setting isInFullScreen to store
            // TODO use isFullScreenRequested and remove timeout
            setTimeout(function () {
                _this._updateSize();
            });
        };
        _this._updateSize = _.debounce(function () {
            if (_this.props.isInFullScreen) {
                return;
            }
            clearInterval(_this.updateWidgetSizeInterval);
            if (!_this.containerRef) {
                return;
            }
            var _a = _this.containerRef, clientWidth = _a.clientWidth, clientHeight = _a.clientHeight;
            var nextWindowSize = {
                width: clientWidth,
                height: clientHeight,
            };
            if (nextWindowSize.width === 0 || nextWindowSize.height === 0) {
                return;
            }
            if (!_.isEqual(nextWindowSize, _this.props.windowSize)) {
                _this.props.updateWindowSize(nextWindowSize);
            }
        }, 100);
        return _this;
    }
    WindowSize.prototype.componentDidMount = function () {
        window.addEventListener('resize', this.updateSize);
        // For cases, when editor don`t show iFrame from not selected page at mobile mode
        this.updateWidgetSizeInterval = setInterval(this.updateSize, 200);
    };
    WindowSize.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.updateSize);
        clearInterval(this.updateWidgetSizeInterval);
    };
    WindowSize.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { style: {
                width: '100%',
                height: '100%',
            }, ref: function (containerRef) { return (_this.containerRef = containerRef); } }, this.props.children));
    };
    WindowSize.propTypes = {
        isInFullScreen: PropTypes.bool.isRequired,
        windowSize: PropTypes.object.isRequired,
        updateWindowSize: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };
    WindowSize = __decorate([
        connect(function (state) { return ({
            isInFullScreen: isInFullScreen(state),
            windowSize: state.windowSize,
        }); }, {
            updateWindowSize: updateWindowSize,
        })
    ], WindowSize);
    return WindowSize;
}(React.Component));
export default WindowSize;
