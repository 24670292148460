export var sides = {
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    NONE: 'none',
};
export var trianglePositions = {
    START: 'start',
    CENTER: 'center',
    END: 'end',
};
export var popoutPositions = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
    TOP: 'top',
};
export var openOn = {
    CLICK: 'click',
    HOVER: 'hover',
};
