import { TEXT_LABELS as labels } from 'playable/dist/src/constants';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
export default (function () {
    var _a;
    return (_a = {},
        _a[labels.LOGO_LABEL] = i18n.t('widget.player.watch-on-site-label'),
        _a[labels.LOGO_TOOLTIP] = i18n.t('widget.player.watch-on-site-tooltip'),
        _a[labels.PAUSE_CONTROL_LABEL] = i18n.t('widget.player.pause-control-label'),
        _a[labels.PLAY_CONTROL_LABEL] = i18n.t('widget.player.play-control-label'),
        _a[labels.PROGRESS_CONTROL_LABEL] = i18n.t('widget.player.progress-control-label'),
        _a[labels.PROGRESS_CONTROL_VALUE] = function (_a) {
            var _b = _a.percent, percent = _b === void 0 ? 0 : _b;
            return i18n.t('widget.player.progress-control-value', { percent: percent });
        },
        _a[labels.MUTE_CONTROL_LABEL] = i18n.t('widget.player.mute-control-label'),
        _a[labels.MUTE_CONTROL_TOOLTIP] = i18n.t('widget.player.mute-control-tooltip'),
        _a[labels.UNMUTE_CONTROL_LABEL] = i18n.t('widget.player.unmute-control-label'),
        _a[labels.UNMUTE_CONTROL_TOOLTIP] = i18n.t('widget.player.unmute-control-tooltip'),
        _a[labels.VOLUME_CONTROL_LABEL] = i18n.t('widget.player.volume-control-label'),
        _a[labels.VOLUME_CONTROL_VALUE] = function (_a) {
            var _b = _a.volume, volume = _b === void 0 ? 0 : _b;
            return i18n.t('widget.player.volume-control-value', { percent: volume });
        },
        _a[labels.ENTER_FULL_SCREEN_LABEL] = i18n.t('widget.player.enter-full-screen-label'),
        _a[labels.ENTER_FULL_SCREEN_TOOLTIP] = i18n.t('widget.player.enter-full-screen-tooltip'),
        _a[labels.EXIT_FULL_SCREEN_LABEL] = i18n.t('widget.player.exit-full-screen-label'),
        _a[labels.EXIT_FULL_SCREEN_TOOLTIP] = i18n.t('widget.player.exit-full-screen-tooltip'),
        _a[labels.LIVE_INDICATOR_TEXT] = function (_a) {
            var isEnded = _a.isEnded;
            return isEnded
                ? i18n.t('widget.player.live-ended-indicator')
                : i18n.t('widget.player.live-indicator');
        },
        _a[labels.LIVE_SYNC_LABEL] = i18n.t('widget.player.live-sync-label'),
        _a[labels.LIVE_SYNC_TOOLTIP] = i18n.t('widget.player.live-sync-tooltip'),
        _a[labels.ENTER_PICTURE_IN_PICTURE_TOOLTIP] = i18n.t('widget.player.enter_picture_in_picture_tooltip'),
        _a[labels.ENTER_PICTURE_IN_PICTURE_LABEL] = i18n.t('widget.player.enter_picture_in_picture_label'),
        _a[labels.EXIT_PICTURE_IN_PICTURE_LABEL] = i18n.t('widget.player.exit_picture_in_picture_label'),
        _a[labels.EXIT_PICTURE_IN_PICTURE_TOOLTIP] = i18n.t('widget.player.exit_picture_in_picture_tooltip'),
        _a);
});
