var _a;
import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isResponsiveEditor } from '../../shared/selectors/app-settings';
import { DesktopView } from '../views/desktop/desktop';
import { CHANNEL_LAYOUT_VALUES } from '@wix/wix-vod-constants/dist/app-settings/channel-layout-values';
import strip from './strip/strip';
import classic from './classic/classic';
import compact from './compact/compact';
import slider from './slider/slider';
import grid from './grid/grid';
var layoutLoaderMap = (_a = {},
    _a[CHANNEL_LAYOUT_VALUES.STRIP] = strip,
    _a[CHANNEL_LAYOUT_VALUES.CLASSIC] = classic,
    _a[CHANNEL_LAYOUT_VALUES.COMPACT] = compact,
    _a[CHANNEL_LAYOUT_VALUES.SLIDER_SMALL] = slider,
    _a[CHANNEL_LAYOUT_VALUES.SLIDER_BIG] = slider,
    _a[CHANNEL_LAYOUT_VALUES.GRID] = grid,
    _a);
var getLayoutComponent = function (id, isResponsiveEditor) {
    return isResponsiveEditor ? slider : layoutLoaderMap[id];
};
function getLayoutId(props) {
    return _.get(props.appSettings, 'numbers.channelLayout');
}
var LayoutSwitcher = /** @class */ (function (_super) {
    __extends(LayoutSwitcher, _super);
    function LayoutSwitcher() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LayoutSwitcher.prototype.render = function () {
        var layoutId = getLayoutId(this.props);
        var Layout = getLayoutComponent(layoutId, this.props.isResponsiveEditor);
        return (React.createElement(DesktopView, null,
            React.createElement(Layout, null)));
    };
    LayoutSwitcher.propTypes = {
        children: PropTypes.element,
        appSettings: PropTypes.object,
        isResponsiveEditor: PropTypes.bool,
    };
    LayoutSwitcher = __decorate([
        connect(function (state) { return ({
            appSettings: state.appSettings,
            isResponsiveEditor: isResponsiveEditor(state),
        }); })
    ], LayoutSwitcher);
    return LayoutSwitcher;
}(React.Component));
export default LayoutSwitcher;
