import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isButtonsTextShown } from '../../../../../shared/selectors/app-settings';
import { playButtonText, playButtonAction } from '../../ui-selectors/buttons';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
var PlayButton = /** @class */ (function (_super) {
    __extends(PlayButton, _super);
    function PlayButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PlayButton.prototype.render = function () {
        var _a = this.props, text = _a.text, action = _a.action, className = _a.className, icon = _a.icon, isTextShown = _a.isTextShown;
        if (!text) {
            return null;
        }
        return (React.createElement("div", { "data-hook": "overlay-play-button", role: "button", "aria-label": i18n.t(text), onClick: action, className: className },
            icon,
            isTextShown && i18n.t(text)));
    };
    PlayButton.propTypes = {
        videoItem: PropTypes.object.isRequired,
        text: PropTypes.string,
        icon: PropTypes.node,
        action: PropTypes.func,
        onMemberSignUp: PropTypes.func.isRequired,
        onPlayRequestedAllowed: PropTypes.func.isRequired,
        onPlayRequestedDisallowed: PropTypes.func.isRequired,
        isChannelCoverView: PropTypes.bool,
        isTextShown: PropTypes.bool,
        className: PropTypes.string,
    };
    PlayButton = __decorate([
        connect(function (state, props) { return ({
            text: playButtonText(state, props),
            action: playButtonAction(state, props),
            isTextShown: isButtonsTextShown(state),
        }); })
    ], PlayButton);
    return PlayButton;
}(React.Component));
export default PlayButton;
