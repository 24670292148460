import { browserLocalStorage } from '@wix/wix-vod-shared/dist/src/common/utils/local-storage';
import { DEAL_INFO_TYPES } from '@wix/wix-vod-constants/dist/common/deal-info-types';
import { VIDEO_PAYMENT_LOGOUT_KEY } from '../constants/payments';
import WixSDK from './wix-sdk';
export var openPaymentModalAfterReload = function (paymentType) {
    browserLocalStorage.setItem(VIDEO_PAYMENT_LOGOUT_KEY, JSON.stringify({
        paymentType: paymentType,
        openerCompId: WixSDK.Utils.getOrigCompId(),
    }));
};
export var handleLogOutPaymentModal = function (_a) {
    var _b;
    var onPurchase = _a.onPurchase, onSubscription = _a.onSubscription, onRent = _a.onRent;
    var stored = browserLocalStorage.getItem(VIDEO_PAYMENT_LOGOUT_KEY);
    var openers = (_b = {},
        _b[DEAL_INFO_TYPES.RENT] = onRent,
        _b[DEAL_INFO_TYPES.SALE] = onPurchase,
        _b[DEAL_INFO_TYPES.SUBSCRIPTION] = onSubscription,
        _b);
    if (stored) {
        var _c = JSON.parse(stored), paymentType = _c.paymentType, openerCompId = _c.openerCompId;
        if (openerCompId === WixSDK.Utils.getCompId()) {
            openers[paymentType]();
            browserLocalStorage.removeItem(VIDEO_PAYMENT_LOGOUT_KEY);
        }
    }
};
