import _ from 'lodash';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/dist/env/hosts';
import { COLLECTOR_SERVICE } from '../../../../../shared/constants/stats/endpoints-url';
import { buildReport } from '../../../../../shared/constants/stats/events';
import createAsyncActions from '../../../../../shared/redux/helpers/create-async-actions';
import { getSiteOwnerId } from '../../../client/hydrated-data/hydrated-data';
import { createAsyncProxy } from '../../../../../shared/worker/lib';
import { HttpClient } from '@wix/http-client';
var reportUrl = "".concat(PRODUCTION_HOSTS.statsCollector).concat(COLLECTOR_SERVICE);
var ACTIONS = createAsyncActions('SERVER.STATS.REPORT');
export var NAMES = ACTIONS.NAMES;
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
export var reportStats = createAsyncProxy('reportStats', function (event, eventData) {
    return function (dispatch, getState) {
        dispatch(START());
        var analytics = {
            analytics: { type: 'error', name: 'widget.stats.report' },
        };
        var state = getState();
        var ownerId = getSiteOwnerId(state);
        var data = buildReport(event, eventData, { ownerId: ownerId });
        return new HttpClient()
            .post(reportUrl, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (response) {
            if (!_.get(response, 'data.success')) {
                return Promise.reject(response);
            }
            dispatch(SUCCESS(data, response));
        })
            .catch(function (error) {
            dispatch(FAIL(data, error, analytics));
        });
    };
});
