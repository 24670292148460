import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/dist/common/video-playback-statuses';
var INITIAL = VIDEO_PLAYBACK_STATUSES.INITIAL, PLAY_REQUESTED = VIDEO_PLAYBACK_STATUSES.PLAY_REQUESTED, PAUSE_REQUESTED = VIDEO_PLAYBACK_STATUSES.PAUSE_REQUESTED, PLAYING = VIDEO_PLAYBACK_STATUSES.PLAYING, PAUSED = VIDEO_PLAYBACK_STATUSES.PAUSED, ENDED = VIDEO_PLAYBACK_STATUSES.ENDED, PLAY_ABORTED = VIDEO_PLAYBACK_STATUSES.PLAY_ABORTED;
var isInStatus = function (status) { return function (state, id) {
    var playbackState = state.playback[id];
    if (!playbackState) {
        return status === INITIAL;
    }
    return playbackState.status === status;
}; };
export var isInitial = isInStatus(INITIAL);
export var isPlayRequested = isInStatus(PLAY_REQUESTED);
export var isPauseRequested = isInStatus(PAUSE_REQUESTED);
export var isPlaying = isInStatus(PLAYING);
export var isPlayAborted = isInStatus(PLAY_ABORTED);
export var isPaused = isInStatus(PAUSED);
export var isEnded = isInStatus(ENDED);
export var isMuted = function (state) { return state.playback.muted; };
export var isPlayingOptimistic = function (state, id) {
    return isInStatus(PLAY_REQUESTED)(state, id) || isInStatus(PLAYING)(state, id);
};
export var isPausedOptimistic = function (state, id) {
    return isInStatus(PAUSE_REQUESTED)(state, id) || isInStatus(PAUSED)(state, id);
};
