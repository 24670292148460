import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import classnames from 'classnames';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { onEscPress } from '../../../shared/utils/call-on-keyboard-press';
import { connect } from 'react-redux';
import { logBi } from '../../../shared/worker/actions/bi';
import Icon from '../../../shared/components/icon/icon';
import { LinkButton } from '../../../shared/components/button/button';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from './search-bar.scss';
var SearchBar = /** @class */ (function (_super) {
    __extends(SearchBar, _super);
    function SearchBar(props) {
        var _this = _super.call(this, props) || this;
        _this.updateValue = function () {
            var value = _this.input.value;
            _this.props.onChange(value);
            _this.setState({ currentValue: value });
        };
        _this.searchOnEnter = function (event) {
            var keyCode = event.nativeEvent.keyCode;
            var value = _this.input.value;
            // check if Enter
            if (keyCode === 13) {
                _this.sendSearchBI(value);
                _this.permitSearch();
            }
        };
        _this.permitSearch = function () {
            var onSearch = _this.props.onSearch;
            var value = _this.input.value;
            _this.setFocused(true);
            onSearch(value);
        };
        _this.closeSearch = function () {
            _this.input.value = '';
            _this.input.blur();
            _this.setState({
                currentValue: '',
                focused: false,
            });
            _this.props.onClose('');
        };
        _this.handleFocus = function (event) {
            _this.props.onFocus(event);
            _this.setFocused(true);
        };
        _this.handleBlur = function (event) {
            _this.props.onBlur(event);
            _this.setFocused(false);
        };
        _this.state = {
            currentValue: props.value,
            focused: false,
        };
        return _this;
    }
    SearchBar.prototype.componentDidMount = function () {
        document.addEventListener('keyup', memoizedPartial(onEscPress, this.closeSearch));
    };
    SearchBar.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ currentValue: nextProps.value });
        }
    };
    SearchBar.prototype.componentWillUnmount = function () {
        document.removeEventListener('keyup', memoizedPartial(onEscPress, this.closeSearch));
    };
    SearchBar.prototype.sendSearchBI = function (searchQuery) {
        if (searchQuery !== '') {
            this.props.logBi('widget.search.clicked');
        }
    };
    SearchBar.prototype.setFocused = function (focused) {
        var _this = this;
        // Don`t hide if click outside with new value passed to props
        setTimeout(function () { return _this.setState({ focused: focused }); }, 0);
    };
    SearchBar.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this.props, className = _b.className, value = _b.value, layout = _b.layout;
        var _c = this.state, currentValue = _c.currentValue, focused = _c.focused;
        var placeholder = i18n.t('widget.search-bar.search-video');
        var containerClassName = classnames(styles.container, (_a = {},
            _a[styles.strip] = layout === 'strip',
            _a[styles['has-value']] = currentValue,
            _a[styles['has-submitted-value']] = value,
            _a[styles.changed] = value !== currentValue,
            _a[styles.focused] = focused,
            _a.className = className,
            _a));
        return (React.createElement("div", { className: classnames(className, containerClassName) },
            React.createElement("div", { className: styles['background-text'] }, placeholder),
            React.createElement("input", { type: "text", className: styles.input, "data-hook": "search-bar-input", value: currentValue, onKeyPress: this.searchOnEnter, onFocus: this.handleFocus, onBlur: this.handleBlur, onChange: this.updateValue, ref: function (node) { return (_this.input = node); }, placeholder: placeholder }),
            React.createElement(Icon, { className: styles['search-icon'], name: "search-bar" }),
            React.createElement(LinkButton, { className: styles['close-search'], onClick: this.closeSearch, "aria-hidden": !currentValue, isFocusable: Boolean(currentValue || value), ariaLabel: i18n.t('widget.accessibility.close-videos-search') },
                React.createElement(Icon, { name: "clear-search" })),
            React.createElement(LinkButton, { className: styles['permit-search'], onClick: this.permitSearch, isFocusable: Boolean(currentValue || value), ariaLabel: i18n.t('widget.accessibility.search-videos') },
                React.createElement(Icon, { name: "permit-search" }))));
    };
    SearchBar.propTypes = {
        onChange: PropTypes.func,
        onSearch: PropTypes.func,
        onClose: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        className: PropTypes.string,
        value: PropTypes.string,
        layout: PropTypes.string,
    };
    SearchBar.defaultProps = {
        layout: 'classic',
        value: '',
        onChange: _.noop,
        onSearch: _.noop,
        onClose: _.noop,
        onFocus: _.noop,
        onBlur: _.noop,
        onKeyPress: _.noop,
    };
    SearchBar = __decorate([
        connect(null, { logBi: logBi }),
        withStyles(styles)
    ], SearchBar);
    return SearchBar;
}(React.Component));
export default SearchBar;
