import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './with-tooltip.scss';
var WithTooltip = /** @class */ (function (_super) {
    __extends(WithTooltip, _super);
    function WithTooltip() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WithTooltip.prototype.renderTooltip = function () {
        var _a = this.props, tooltip = _a.tooltip, innerClassName = _a.innerClassName;
        return (React.createElement("span", { className: styles.tooltip },
            React.createElement("span", { className: classnames(innerClassName, styles['tooltip-inner']) }, tooltip)));
    };
    WithTooltip.prototype.render = function () {
        var _a = this.props, tooltip = _a.tooltip, children = _a.children, className = _a.className;
        if (!children) {
            return null;
        }
        return (React.createElement("span", { className: classnames(className, styles['with-tooltip']) },
            tooltip && this.renderTooltip(),
            children));
    };
    WithTooltip.propTypes = {
        children: PropTypes.any,
        tooltip: PropTypes.any,
        className: PropTypes.string,
        innerClassName: PropTypes.string,
    };
    WithTooltip = __decorate([
        withStyles(styles)
    ], WithTooltip);
    return WithTooltip;
}(React.Component));
export default WithTooltip;
