import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '../../utils/withStyles';
import styles from './scrollbar-wrapper.scss';
var ScrollbarWrapper = /** @class */ (function (_super) {
    __extends(ScrollbarWrapper, _super);
    function ScrollbarWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRef = function (node) {
            if (!node) {
                return;
            }
            _this.props.getRef(node);
        };
        return _this;
    }
    ScrollbarWrapper.prototype.render = function () {
        var _a;
        var _b = this.props, onlyOnHover = _b.onlyOnHover, scrollX = _b.scrollX, scrollY = _b.scrollY, className = _b.className;
        var props = _.pick(this.props, 'children', 'style');
        var classNames = classnames(className, styles['scrollbar-wrapper'], (_a = {},
            _a[styles['only-on-hover']] = onlyOnHover,
            _a[styles['scroll-x']] = scrollX,
            _a[styles['scroll-y']] = scrollY,
            _a));
        return React.createElement("div", __assign({}, props, { ref: this.getRef, className: classNames }));
    };
    ScrollbarWrapper.propTypes = {
        scrollX: PropTypes.bool,
        scrollY: PropTypes.bool,
        onlyOnHover: PropTypes.bool,
        getRef: PropTypes.func,
    };
    ScrollbarWrapper.defaultProps = {
        scrollX: false,
        scrollY: true,
        onlyOnHover: false,
        getRef: _.noop,
    };
    ScrollbarWrapper = __decorate([
        withStyles(styles)
    ], ScrollbarWrapper);
    return ScrollbarWrapper;
}(React.Component));
var WidgetScrollbarWrapper = /** @class */ (function (_super) {
    __extends(WidgetScrollbarWrapper, _super);
    function WidgetScrollbarWrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WidgetScrollbarWrapper.prototype.render = function () {
        var className = this.props.className;
        var props = _.omit(this.props, 'styles');
        return (React.createElement(ScrollbarWrapper, __assign({}, props, { className: classnames(styles.widget, className) })));
    };
    return WidgetScrollbarWrapper;
}(React.Component));
export { WidgetScrollbarWrapper };
