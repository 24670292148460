import { __awaiter, __decorate, __extends, __generator } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import getUrlToChannel from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-channel';
import getUrlToVideo from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-video';
import socialNetworkNames from '@wix/wix-vod-shared/dist/src/widget/constants/social-networks';
import { connect } from 'react-redux';
import { reportStats } from '../../../widget/redux/server/actions/stats/report';
import STATS_EVENTS from '../../../shared/constants/stats/events';
import { getCompId, getFullSiteUrl, } from '../../../widget/redux/client/hydrated-data/hydrated-data';
import { openModal } from '../../../shared/redux/client/actions/modal';
import styles from './share-mobile.scss';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from '../../../shared/utils/withStyles';
// https://w3c.github.io/web-share/
var ABORT_ERR = 20;
var ShareMobile = /** @class */ (function (_super) {
    __extends(ShareMobile, _super);
    function ShareMobile(props) {
        var _this = _super.call(this, props) || this;
        _this.hasNativeShare = function () { return Boolean(navigator.share); };
        _this.loadSharePopup = function () { return __awaiter(_this, void 0, void 0, function () {
            var ShareMobileContent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import(
                        /* webpackChunkName: "mobile/share-popup-content" */ './popup-content/popup-content')];
                    case 1:
                        ShareMobileContent = (_a.sent()).default;
                        this.setState({
                            ShareMobileContent: ShareMobileContent,
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.sendStatsReport = function (name) {
            var _a = _this.props, reportStats = _a.reportStats, channel = _a.channelData, videoItem = _a.videoItem;
            var event = videoItem
                ? STATS_EVENTS.VIDEO_SHARE
                : STATS_EVENTS.CHANNEL_SHARE;
            var stats = {
                network: {
                    name: name,
                },
                channel: channel,
            };
            if (videoItem) {
                stats.videoItem = videoItem;
            }
            reportStats(event, stats);
        };
        _this.openPopup = function () {
            if (_this.hasNativeShare()) {
                _this.openNativeShare();
            }
            else {
                _this.openSharePopup();
            }
        };
        _this.openNativeShare = function () {
            var _a = _this.props, siteUrl = _a.siteUrl, compId = _a.compId, channelData = _a.channelData, videoItem = _a.videoItem;
            var url, text;
            if (videoItem) {
                text = videoItem.title;
                url = getUrlToVideo(videoItem, { siteUrl: siteUrl, compId: compId });
            }
            else {
                text = channelData.title;
                url = getUrlToChannel({ siteUrl: siteUrl, compId: compId });
            }
            navigator
                .share({
                url: url,
                text: text,
            })
                .then(function () { return _this.sendStatsReport(socialNetworkNames.LINK); })
                .catch(function (error) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(error.code !== ABORT_ERR)) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.loadSharePopup()];
                        case 1:
                            _a.sent();
                            this.openSharePopup();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        };
        _this.openSharePopup = function () {
            var _a = _this.props, videoItem = _a.videoItem, compId = _a.compId, channelData = _a.channelData, top = _a.top, siteUrl = _a.siteUrl, openModal = _a.openModal;
            var ShareMobileContent = _this.state.ShareMobileContent;
            var title, titleLabel, inputLabel, url, description, coverUrl;
            if (videoItem) {
                titleLabel = 'widget.share-video.share';
                inputLabel = 'widget.share-video.share-link';
                url = getUrlToVideo(videoItem, { siteUrl: siteUrl, compId: compId });
                description = videoItem.description;
                coverUrl = videoItem.customCoverUrl || videoItem.coverUrl;
                title = videoItem.title;
            }
            else {
                titleLabel = 'widget.share-channel.share';
                inputLabel = 'widget.share-channel.share-link';
                url = getUrlToChannel({ siteUrl: siteUrl, compId: compId });
                description = channelData.description;
                coverUrl = channelData.customCoverUrl || channelData.coverUrl;
                title = channelData.title;
            }
            var shareParams = {
                siteUrl: url,
                title: title,
                description: description,
                coverUrl: coverUrl,
            };
            openModal({
                title: React.createElement("span", { className: styles.titleWrap }, i18n.t(titleLabel)),
                top: top,
                closeButtonLabel: i18n.t('widget.accessibility.close-share'),
                content: (React.createElement(ShareMobileContent, { shareParams: shareParams, inputLabel: i18n.t(inputLabel), channelId: channelData.id, videoId: videoItem && videoItem.id, onShare: _this.sendStatsReport })),
            });
        };
        _this.state = {
            ShareMobileContent: null,
        };
        return _this;
    }
    ShareMobile.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.hasNativeShare()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadSharePopup()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (this.props.isOpen) {
                            this.openPopup();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ShareMobile.prototype.render = function () {
        var children = this.props.children;
        return children
            ? React.cloneElement(children, {
                onClick: this.openPopup,
            })
            : null;
    };
    ShareMobile.displayName = 'ShareMobile';
    ShareMobile.propTypes = {
        videoItem: PropTypes.object,
        channelData: PropTypes.object,
        siteUrl: PropTypes.string,
        reportStats: PropTypes.func,
        openModal: PropTypes.func,
        getUrlToChannel: PropTypes.func,
        getUrlToVideo: PropTypes.func,
        children: PropTypes.node,
        compId: PropTypes.string,
        isOpen: PropTypes.bool,
    };
    ShareMobile.defaultProps = {
        dataHook: 'share-mobile',
    };
    ShareMobile = __decorate([
        connect(function (state) { return ({
            compId: getCompId(state),
            siteUrl: getFullSiteUrl(state),
        }); }, { reportStats: reportStats, openModal: openModal }),
        withStyles(styles)
    ], ShareMobile);
    return ShareMobile;
}(React.Component));
export default ShareMobile;
