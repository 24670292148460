import { __awaiter, __decorate, __extends, __generator } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import EVENTS from '../../shared/constants/events';
import { connect } from 'react-redux';
import { setHydratedData } from '../redux/client/hydrated-data/hydrated-data';
import { consumePubSubEvent, withPubSubEvents } from './pub-sub-events';
var LoginWatcher = /** @class */ (function (_super) {
    __extends(LoginWatcher, _super);
    function LoginWatcher() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleUserLogin = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var _a, id, email;
            return __generator(this, function (_b) {
                _a = event.data, id = _a.id, email = _a.email;
                this.props.setHydratedData({
                    currentSiteUser: {
                        id: id,
                        email: email,
                    },
                });
                return [2 /*return*/];
            });
        }); };
        return _this;
    }
    LoginWatcher.prototype.componentDidUpdate = function (prevProps) {
        consumePubSubEvent(EVENTS.SESSION.LOGIN_USER, this.handleUserLogin, {
            prevProps: prevProps,
            nextProps: this.props,
        });
    };
    LoginWatcher.prototype.render = function () {
        return null;
    };
    LoginWatcher.propTypes = {
        setHydratedData: PropTypes.func.isRequired,
    };
    LoginWatcher = __decorate([
        connect(null, { setHydratedData: setHydratedData }),
        withPubSubEvents
    ], LoginWatcher);
    return LoginWatcher;
}(React.Component));
export { LoginWatcher };
