var _a, _b;
import _ from 'lodash';
import { requestPlayVideo } from './request-play-video';
import { requestPlay } from '../../../../shared/redux/client/reducers/playback';
import { getNextVideoId } from '../lazy-channel-videos/selectors';
import { setOverlayContent } from './player/set-overlay-content';
import { isVolumeChangedByUser, isAutoplayed, } from '../../../../shared/selectors/player';
import { getVideoById } from '../../../../shared/selectors/videos';
import { getVideoEndOption, isSingleVideo, } from '../../../../shared/selectors/app-settings';
import { getVideoCardsList } from '../../../selectors/video-cards';
import { VIDEO_END_OPTIONS } from '@wix/wix-vod-constants/dist/app-settings/video-end-options';
import INFO_OVERLAY_CONTENT from '../../../player-widget/components/new-player/containers/animated-overlay-content/info-overlay-content-enums';
import { hidePlayerUI } from './player/ui';
import { isFacebookVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import { getChannelById } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { logWidgetVidClick } from '../../../../shared/utils/bi/widget-common-events';
import { createProxy } from '../../../../shared/worker/lib';
import { getCurrentChannelId } from '../../../selectors/channel-info';
export var handleVideoEndDesktop = function () { return handleVideoEnd(false); };
export var handleVideoEndMobile = function () { return handleVideoEnd(true); };
var logNextVideoClick = function (dispatch, state, nextVideoId) {
    return dispatch(logWidgetVidClick({
        videoItem: getVideoById(state, nextVideoId),
        channelData: getChannelById(state, getCurrentChannelId(state)),
    }));
};
var desktopHandlers = (_a = {},
    _a[VIDEO_END_OPTIONS.OPEN_ACTION_CARDS] = openActionCards,
    _a[VIDEO_END_OPTIONS.PLAY_NEXT_VIDEO] = playNextVideo,
    _a);
var mobileHandlers = (_b = {},
    _b[VIDEO_END_OPTIONS.PLAY_NEXT_VIDEO] = playNextVideoMobile,
    _b);
export var handleVideoEnd = createProxy('handleVideoEnd', function (isMobile) { return function (dispatch, getState) {
    var state = getState();
    if (!isSingleVideo(state)) {
        var videoEndOption = getVideoEndOption(state);
        var handlers = isMobile ? mobileHandlers : desktopHandlers;
        var handler = handlers[videoEndOption];
        if (handler) {
            handler(dispatch, state);
        }
    }
}; });
function playNextVideo(dispatch, state) {
    var nextVideoId = getNextVideoId(state);
    var muted = isAutoplayed(state) && !isVolumeChangedByUser(state);
    if (nextVideoId) {
        logNextVideoClick(dispatch, state, nextVideoId);
        dispatch(requestPlayVideo(nextVideoId, muted));
    }
}
function playNextVideoMobile(dispatch, state) {
    var nextVideoId = getNextVideoId(state);
    if (nextVideoId) {
        var nextVideo = getVideoById(state, nextVideoId);
        if (!isFacebookVideo(nextVideo)) {
            logNextVideoClick(dispatch, state, nextVideoId);
            dispatch(requestPlay(nextVideoId, true));
        }
    }
}
function openActionCards(dispatch, state) {
    var videoCards = getVideoCardsList(state);
    if (!_.isEmpty(videoCards)) {
        dispatch(hidePlayerUI());
        dispatch(setOverlayContent(INFO_OVERLAY_CONTENT.ALL_CARDS));
    }
}
