import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TabletPlayer from '../components/players/tablet-player';
import DesktopPlayer from '../components/players/desktop-player';
import { isPortableDevice } from '../../shared/selectors/form-factor';
export var PlayerModuleContext = React.createContext({
    PlayerComponent: null,
    isPortableDevice: false,
});
var PlayerModuleLoader = /** @class */ (function (_super) {
    __extends(PlayerModuleLoader, _super);
    function PlayerModuleLoader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PlayerModuleLoader.prototype.render = function () {
        var _a = this.props, children = _a.children, isPortableDevice = _a.isPortableDevice;
        var PlayerComponent = isPortableDevice ? TabletPlayer : DesktopPlayer;
        return (React.createElement(PlayerModuleContext.Provider, { value: {
                PlayerComponent: PlayerComponent,
                isPortableDevice: isPortableDevice,
            } }, children));
    };
    PlayerModuleLoader.propTypes = {
        children: PropTypes.object,
    };
    PlayerModuleLoader = __decorate([
        connect(function (state) { return ({
            isPortableDevice: isPortableDevice(state),
        }); })
    ], PlayerModuleLoader);
    return PlayerModuleLoader;
}(React.PureComponent));
export var withPlayerModuleLoader = function (WrappedComponent) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        class_1.prototype.render = function () {
            var _this = this;
            return (React.createElement(PlayerModuleContext.Consumer, null, function (_a) {
                var PlayerComponent = _a.PlayerComponent, isPortableDevice = _a.isPortableDevice;
                return (React.createElement(WrappedComponent, __assign({}, _this.props, { PlayerComponent: PlayerComponent, isPortableDevice: isPortableDevice })));
            }));
        };
        return class_1;
    }(React.Component));
};
export default PlayerModuleLoader;
