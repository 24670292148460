import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import Tooltip from '../../../../../shared/components/tooltip/tooltip';
import { sides } from '../../../../../shared/constants/popout';
import AccountInfo from '../account-info/account-info';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './account-button-with-tooltip.scss';
var AccountButtonWithTooltip = /** @class */ (function (_super) {
    __extends(AccountButtonWithTooltip, _super);
    function AccountButtonWithTooltip() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.contentRef = null;
        _this.saveContentRef = function (ref) {
            _this.contentRef = ref;
        };
        _this.focusContent = function () {
            // need to wait for popup rendering to focus
            // ref callback can`t be used because content will render once for popup
            var interval = setInterval(function () {
                if (_this.contentRef) {
                    focus(_this.contentRef);
                    clearInterval(interval);
                }
            }, 200);
        };
        return _this;
    }
    AccountButtonWithTooltip.prototype.render = function () {
        var _a = this.props, logoutLabel = _a.logoutLabel, userEmail = _a.userEmail, onLogoutClick = _a.onLogoutClick, className = _a.className, tooltipSide = _a.tooltipSide, popoutClassName = _a.popoutClassName, popoutTriangleClassName = _a.popoutTriangleClassName, isFocusable = _a.isFocusable;
        return (React.createElement(Tooltip, { preventHideOnHover: true, popoutSide: tooltipSide, dataHook: "channel-account-info", iconName: "avatar", iconAriaLabel: i18n.t('widget.accessibility.my-account'), isButtonFocusable: isFocusable, className: classnames(styles['account-info-button'], className), popoutClassName: classnames(styles.popout, popoutClassName), popoutInnerClassName: styles['popout-inner'], popoutTriangleClassName: classnames(styles['popout-triangle'], popoutTriangleClassName), onTooltipShow: this.focusContent },
            React.createElement("section", { ref: this.saveContentRef },
                React.createElement(AccountInfo, { logoutLabel: logoutLabel, userEmail: userEmail, onLogoutClick: onLogoutClick }))));
    };
    AccountButtonWithTooltip.propTypes = {
        className: PropTypes.string,
        popoutClassName: PropTypes.string,
        popoutTriangleClassName: PropTypes.string,
        logoutLabel: PropTypes.string,
        userEmail: PropTypes.string,
        tooltipSide: PropTypes.oneOf(_.values(sides)),
        isFocusable: PropTypes.bool,
        onLogoutClick: PropTypes.func,
    };
    AccountButtonWithTooltip.defaultProps = {
        tooltipSide: sides.TOP,
    };
    AccountButtonWithTooltip = __decorate([
        withStyles(styles)
    ], AccountButtonWithTooltip);
    return AccountButtonWithTooltip;
}(React.Component));
export default AccountButtonWithTooltip;
