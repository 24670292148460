import { getBuyButtonDisplayTypeWhenInOverlay } from '@wix/wix-vod-shared/dist/src/common/utils/overlay-buttons-utils';
import { isVideoOverQuota } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/quota';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { getPlayButtonTypeString } from '../../../shared/utils/bi/video-overlay-events-params';
import { logWidgetVidClick } from '../../../shared/utils/bi/widget-common-events';
import { logBi } from '../../../shared/worker/actions/bi';
import { createProxy } from '../../../shared/worker/lib';
export var logChannelCoverButtonClicked = function (button) {
    return logBi('widget.chCover.btn.clicked', { button: button });
};
export var logSubscriptionPopupClosed = function () {
    return logBi('widget.subscriptionPopUp.Completed', {
        result: 'canceled',
        errorMsg: '',
    });
};
export var logVideoButtonClicked = function (videoItem, button) {
    return logBi('widget.VidSelected.btn.clicked', {
        button: button,
        buttonType: 'main',
        videoID: videoItem.id,
        overQuoted: isVideoOverQuota(videoItem),
    });
};
export var logPlayButtonClicked = createProxy('logPlayButtonClicked', function (_a) {
    var channelData = _a.channelData, videoItem = _a.videoItem, currentSiteUser = _a.currentSiteUser, isChannelCover = _a.isChannelCover;
    return function (dispatch) {
        if (isChannelCover) {
            var button = canAccessFullVideo(channelData, videoItem, currentSiteUser)
                ? 'startWatching'
                : 'watchPreview';
            dispatch(logChannelCoverButtonClicked(button));
        }
        else {
            dispatch(logVideoButtonClicked(videoItem, getPlayButtonTypeString(channelData, videoItem, currentSiteUser)));
        }
        dispatch(logWidgetVidClick({ videoItem: videoItem, channelData: channelData }));
    };
});
export var logPurchaseButtonClicked = function (_a) {
    var appSettings = _a.appSettings, videoItem = _a.videoItem, channelData = _a.channelData, isChannelCover = _a.isChannelCover;
    return function (dispatch) {
        var buttonDisplayType = getBuyButtonDisplayTypeWhenInOverlay({
            settings: appSettings,
            videoItem: videoItem,
            channelData: channelData,
            showChannelCover: isChannelCover,
        });
        dispatch(logVideoButtonClicked(videoItem, buttonDisplayType));
    };
};
