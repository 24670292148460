import { __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { HttpClient } from '@wix/http-client';
import { get } from 'lodash';
import { EngineState as ENGINE_STATES, VIDEO_EVENTS, } from 'playable/dist/src/constants';
var PING_INTERVAL = 5000;
var LiveVideoCounter = /** @class */ (function (_super) {
    __extends(LiveVideoCounter, _super);
    function LiveVideoCounter(props) {
        var _this = _super.call(this, props) || this;
        _this.onStateChanged = function (_a) {
            var nextState = _a.nextState, prevState = _a.prevState;
            if (nextState === ENGINE_STATES.PLAYING && nextState !== prevState) {
                _this.startPollCounterService();
            }
            else if (nextState === ENGINE_STATES.PAUSED) {
                _this.stopPollCounterService();
            }
        };
        _this.startPollCounterService = function () {
            if (_this.counterIntervalId) {
                return;
            }
            _this.counterIntervalId = setInterval(_this.pingCounterService, PING_INTERVAL);
        };
        _this.stopPollCounterService = function () {
            clearInterval(_this.counterIntervalId);
            _this.counterIntervalId = null;
        };
        _this.pingCounterService = function () {
            _this.httpClient.get(_this.streamPingUrl);
        };
        _this.streamPingUrl = get(props, 'videoItem.streamPingUrl');
        _this.httpClient = new HttpClient();
        return _this;
    }
    LiveVideoCounter.prototype.componentDidMount = function () {
        var _a = this.props, eventEmitter = _a.eventEmitter, canAccessFullVideo = _a.canAccessFullVideo;
        if (!this.streamPingUrl || !canAccessFullVideo) {
            return;
        }
        eventEmitter.on(VIDEO_EVENTS.STATE_CHANGED, this.onStateChanged);
    };
    LiveVideoCounter.prototype.componentWillUnmount = function () {
        var eventEmitter = this.props.eventEmitter;
        this.stopPollCounterService();
        eventEmitter.off(VIDEO_EVENTS.STATE_CHANGED, this.onStateChanged);
    };
    LiveVideoCounter.prototype.render = function () {
        return null;
    };
    LiveVideoCounter.propTypes = {
        videoItem: PropTypes.object,
        eventEmitter: PropTypes.object,
        canAccessFullVideo: PropTypes.bool,
    };
    return LiveVideoCounter;
}(React.Component));
export default LiveVideoCounter;
