import React from 'react';
import PropTypes from 'prop-types';
import LiveVideoCounter from './components/live-video-counter';
import LiveVideoStatusUpdater from './components/live-video-status-updater';
import { isStreamingLive } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';
export var LiveVideoUpdaters = function (_a) {
    var videoItem = _a.videoItem, eventEmitter = _a.eventEmitter, channelId = _a.channelId, canAccessFullVideo = _a.canAccessFullVideo, isSiteMode = _a.isSiteMode;
    return isStreamingLive(videoItem) && isSiteMode ? (React.createElement("div", null,
        React.createElement(LiveVideoCounter, { eventEmitter: eventEmitter, videoItem: videoItem, canAccessFullVideo: canAccessFullVideo }),
        React.createElement(LiveVideoStatusUpdater, { eventEmitter: eventEmitter, videoItem: videoItem, channelId: channelId }))) : null;
};
LiveVideoUpdaters.propTypes = {
    videoItem: PropTypes.object,
    eventEmitter: PropTypes.object,
    canAccessFullVideo: PropTypes.bool,
    channelId: PropTypes.string,
    isSiteMode: PropTypes.bool.isRequired,
};
