import { __awaiter, __generator } from "tslib";
import { get } from '@wix/wix-vod-api/dist/src/private/settings/settings';
export var getVodSettings = function () { return __awaiter(void 0, void 0, void 0, function () {
    var error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, get()];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                error_1 = _a.sent();
                // NOTE: new site-based API responds with 404 error code if settings are missing (new user scenario)
                // temporary we are fixing this on the client and simulating empty object response, like it was done in old VOD
                if (error_1.status === 404) {
                    return [2 /*return*/, {}];
                }
                return [2 /*return*/, Promise.reject(error_1)];
            case 3: return [2 /*return*/];
        }
    });
}); };
