/* eslint-disable react/prop-types */
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { getFullAccessActionName, FULL_ACCESS_ACTION_NAMES, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
/**
 * Is channel sign in to play button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @return {Boolean}
 */
export function isVisible(_a) {
    var channel = _a.channel, video = _a.video, currentSiteUser = _a.currentSiteUser;
    return (getFullAccessActionName(channel, video, currentSiteUser) ===
        FULL_ACCESS_ACTION_NAMES.SIGN_IN);
}
/**
 * Channel sign in to play button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.translation {Object} default is sharedTranslations.playVideo
 * @return {React.Component}
 */
export default function renderSignInToWatchButtonText(props) {
    if (!isVisible(props)) {
        return null;
    }
    var translation = props.translation || 'widget.overlay-buttons.play-video';
    return i18n.t(translation);
}
