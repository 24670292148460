var _a;
import { createLoggerActions } from '../../shared/worker/actions/fedopsLogger';
import { VIDEO_SOURCES } from '@wix/wix-vod-constants/dist/common/video-sources';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import LAYOUTS_MAP from '@wix/wix-vod-shared/dist/src/common/layouts-map';
var SOURCES_INTERACTIONS_MAPPING = (_a = {},
    _a[VIDEO_SOURCES.COMPUTER] = 'VIDEO_PLAY_COMPUTER',
    _a[VIDEO_SOURCES.YOUTUBE] = 'VIDEO_PLAY_YOUTUBE',
    _a[VIDEO_SOURCES.VIMEO] = 'VIDEO_PLAY_VIMEO',
    _a[VIDEO_SOURCES.FACEBOOK] = 'VIDEO_PLAY_FACEBOOK',
    _a[VIDEO_SOURCES.LIVE] = 'VIDEO_PLAY_LIVE',
    _a);
var noopActionCreator = function () { return function () { }; };
var noopInteraction = { start: noopActionCreator, end: noopActionCreator };
function createPlayVideoInteraction(createInteraction) {
    return function (_a) {
        var videoSource = _a.videoSource;
        var interactionName = SOURCES_INTERACTIONS_MAPPING[videoSource];
        if (interactionName === undefined) {
            return noopInteraction;
        }
        return createInteraction(interactionName);
    };
}
function createPlayInteraction(createInteraction) {
    var getInteraction = function (layout, videoItem) {
        var videoSource = isLiveVideo(videoItem)
            ? VIDEO_SOURCES.LIVE
            : videoItem.videoSource;
        var interactionName = "PLAY_".concat(videoSource.toUpperCase(), "_").concat(LAYOUTS_MAP[layout].toUpperCase());
        return createInteraction(interactionName);
    };
    return {
        start: function (layout, videoItem) { return getInteraction(layout, videoItem).start(); },
        end: function (layout, videoItem) { return getInteraction(layout, videoItem).end(); },
    };
}
function createNewFedopsLogger() {
    var _a = createLoggerActions(undefined), appLoaded = _a.appLoaded, createInteraction = _a.createInteraction;
    return {
        appLoaded: appLoaded,
        getWidgetData: createInteraction('GET_WIDGET_DATA'),
        playVideo: createPlayVideoInteraction(createInteraction),
        autoplayLive: createInteraction('AUTOPLAY_LIVE'),
        play: createPlayInteraction(createInteraction),
        loadCashierModal: createInteraction('CASHIER_MODAL_LOAD'),
        loadCashierPaymentPage: createInteraction('CASHIER_PAYMENT_PAGE_LOAD'),
        loadCashierPaymentCompletePage: createInteraction('CASHIER_PAYMENT_COMPLETE_PAGE_LOAD'),
    };
}
export default createNewFedopsLogger();
