export function getSlideWidth(width, slidesToShow, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.slidesPreviewWidth, slidesPreviewWidth = _c === void 0 ? 0 : _c;
    return (width - 2 * slidesPreviewWidth) / slidesToShow;
}
export function getTrackLeft(slidesCount, slidesPerPage, slideIndex, slideWidth, slidesPreviewWidth) {
    if (slideIndex === 0) {
        return 0;
    }
    if (slideIndex >= slidesCount - slidesPerPage) {
        return slideIndex * slideWidth * -1 + 2 * slidesPreviewWidth;
    }
    return slideIndex * slideWidth * -1 + slidesPreviewWidth;
}
