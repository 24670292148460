import _ from 'lodash';
var regex = /(auto|scroll|overlay)/;
var parents = function (node, ps) {
    if (node.parentNode === null) {
        return ps;
    }
    return parents(node.parentNode, ps.concat([node]));
};
var style = function (node, prop) {
    return getComputedStyle(node, null).getPropertyValue(prop);
};
var overflow = function (node) {
    return style(node, 'overflow') +
        style(node, 'overflow-y') +
        style(node, 'overflow-x');
};
var scroll = function (node) { return regex.test(overflow(node)); };
export default function getScrollParent(node) {
    if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
        return;
    }
    return (_.find(parents(node.parentNode, []), scroll) ||
        document.scrollingElement ||
        document.documentElement);
}
