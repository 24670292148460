import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as viewModeSelectors from '../../selectors/view-mode';
import { setWidgetHeight } from '../../../shared/worker/actions/resize/set-widget-height';
import { resizeWindow } from '../../../shared/worker/actions/resize/resize-window';
import { resizeComponent } from '../../../shared/worker/actions/resize/resize-component';
import { MAX_WIDGET_WIDTH, MIN_WIDGET_WIDTH, } from '../../layouts/compact/compact';
var ResizeWidgetByContent = /** @class */ (function (_super) {
    __extends(ResizeWidgetByContent, _super);
    function ResizeWidgetByContent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.interval = null;
        _this.containerEl = null;
        _this.updateSize = function () {
            var newSize = _this.containerSize;
            var _a = _this.props, windowSize = _a.windowSize, isEditor = _a.isEditor, resizeWindow = _a.resizeWindow, setWidgetHeight = _a.setWidgetHeight, resizeComponent = _a.resizeComponent;
            if (!newSize.width ||
                !newSize.height ||
                !windowSize.width ||
                _.isEqual(windowSize, newSize)) {
                return;
            }
            if (isEditor) {
                // editor
                resizeComponent(newSize);
            }
            else {
                // viewer
                resizeWindow(newSize.width, newSize.height);
            }
            // fix TPA bug. TODO remove
            setWidgetHeight(newSize.height, newSize.width, { overflow: false });
        };
        return _this;
    }
    ResizeWidgetByContent.prototype.componentDidMount = function () {
        this.interval = setInterval(this.updateSize, 300);
    };
    ResizeWidgetByContent.prototype.componentWillUnmount = function () {
        clearInterval(this.interval);
    };
    Object.defineProperty(ResizeWidgetByContent.prototype, "containerSize", {
        get: function () {
            var width = _.clamp(_.get(this.containerEl, 'clientWidth', 0), this.props.minWidth, MAX_WIDGET_WIDTH);
            var height = _.get(this.containerEl, 'clientHeight', 0);
            return {
                width: Math.ceil(width),
                height: Math.ceil(height),
            };
        },
        enumerable: false,
        configurable: true
    });
    ResizeWidgetByContent.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { ref: function (node) { return (_this.containerEl = node); } }, this.props.children));
    };
    ResizeWidgetByContent.propTypes = {
        windowSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        children: PropTypes.element,
        isEditor: PropTypes.bool.isRequired,
        resizeWindow: PropTypes.func.isRequired,
        setWidgetHeight: PropTypes.func.isRequired,
        minWidth: PropTypes.number,
    };
    ResizeWidgetByContent.defaultProps = {
        minWidth: MIN_WIDGET_WIDTH,
    };
    ResizeWidgetByContent = __decorate([
        connect(function (state) { return ({
            windowSize: state.windowSize,
            isEditor: viewModeSelectors.isEditorMode(state),
        }); }, {
            setWidgetHeight: setWidgetHeight,
            resizeWindow: resizeWindow,
            resizeComponent: resizeComponent,
        })
    ], ResizeWidgetByContent);
    return ResizeWidgetByContent;
}(React.Component));
export default ResizeWidgetByContent;
