import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
var ComingSoon = function (_a) {
    var className = _a.className;
    return (React.createElement("span", { className: className }, i18n.t('widget.overlay-buttons.coming-soon')));
};
ComingSoon.propTypes = {
    className: PropTypes.string,
};
export default ComingSoon;
