import { __extends } from "tslib";
import React from 'react';
import Lodash from 'lodash';
import { connect } from 'react-redux';
import { getAllChannelsGroupedByIds } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getSeoData } from './seoData';
import { getVideosGroupedByIds } from '../../../shared/selectors/videos';
import { isSingleVideo } from '../../../shared/selectors/app-settings';
import { withWidgetProps } from '../../containers/widget-props';
var SeoComponent = /** @class */ (function (_super) {
    __extends(SeoComponent, _super);
    function SeoComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SeoComponent.prototype.shouldComponentUpdate = function () {
        return false;
    };
    SeoComponent.prototype.render = function () {
        var _a = this.props, channels = _a.channels, videos = _a.videos, isSingleVideo = _a.isSingleVideo;
        var serializedSeoData = null;
        try {
            var seoData = getSeoData({ channels: channels, videos: videos, isSingleVideo: isSingleVideo });
            serializedSeoData = seoData && JSON.stringify(seoData);
        }
        catch (err) {
            this.props.captureException(err);
        }
        if (!serializedSeoData) {
            return null;
        }
        return (React.createElement("script", { type: "application/ld+json", dangerouslySetInnerHTML: { __html: serializedSeoData } }));
    };
    return SeoComponent;
}(React.Component));
export default withWidgetProps(function (_a) {
    var captureException = _a.captureException;
    return ({
        captureException: captureException,
    });
})(connect(function (state) { return ({
    isSingleVideo: isSingleVideo(state),
    channels: Lodash.values(getAllChannelsGroupedByIds(state)),
    videos: Lodash.values(getVideosGroupedByIds(state)).filter(function (video) { return video.id; }),
}); })(SeoComponent));
