import _ from 'lodash';
var EVENT_NAMES = {
    VIDEO_PLAY_TRAILER: 'VIDEO_PLAY_TRAILER',
    VIDEO_PLAY_COMPLETE: 'VIDEO_PLAY_COMPLETE',
    VIDEO_SHARE: 'VIDEO_SHARE',
    CHANNEL_SHARE: 'CHANNEL_SHARE',
};
var getEvents = function (_a) {
    var _b;
    var ownerId = _a.ownerId;
    return (_b = {},
        _b[EVENT_NAMES.VIDEO_PLAY_TRAILER] = {
            messageId: function () {
                return Date.now();
            },
            metrics: [
                {
                    type: 'video',
                    reportMetrics: [{ name: 'view', value: 1 }],
                    properties: {
                        view_type: 'trailer',
                        user_id: ownerId,
                        video_id: function (_a) {
                            var videoItem = _a.videoItem;
                            return videoItem.id;
                        },
                        channel_id: function (_a) {
                            var channel = _a.channel;
                            return channel.id;
                        },
                    },
                },
            ],
        },
        _b[EVENT_NAMES.VIDEO_PLAY_COMPLETE] = {
            messageId: function () {
                return Date.now();
            },
            metrics: [
                {
                    type: 'video',
                    reportMetrics: [{ name: 'view', value: 1 }],
                    properties: {
                        view_type: 'video',
                        user_id: ownerId,
                        video_id: function (_a) {
                            var videoItem = _a.videoItem;
                            // only youtube feed videos have no id, use externalId instead
                            return videoItem.id || videoItem.externalId;
                        },
                        channel_id: function (_a) {
                            var channel = _a.channel;
                            return channel.id;
                        },
                    },
                },
            ],
        },
        _b[EVENT_NAMES.VIDEO_SHARE] = {
            messageId: function () {
                return Date.now();
            },
            metrics: [
                {
                    type: 'video',
                    reportMetrics: [{ name: 'share', value: 1 }],
                    properties: {
                        user_id: ownerId,
                        video_id: function (_a) {
                            var videoItem = _a.videoItem;
                            // only youtube feed videos have no id, use externalId instead
                            return videoItem.id || videoItem.externalId;
                        },
                        channel_id: function (_a) {
                            var channel = _a.channel;
                            return channel.id;
                        },
                        social: function (_a) {
                            var network = _a.network;
                            return network.name;
                        },
                    },
                },
            ],
        },
        _b[EVENT_NAMES.CHANNEL_SHARE] = {
            messageId: function () {
                return Date.now();
            },
            metrics: [
                {
                    type: 'channel',
                    reportMetrics: [{ name: 'share', value: 1 }],
                    properties: {
                        user_id: ownerId,
                        channel_id: function (_a) {
                            var channel = _a.channel;
                            return channel.id;
                        },
                        social: function (_a) {
                            var network = _a.network;
                            return network.name;
                        },
                    },
                },
            ],
        },
        _b);
};
function fillParams(eventParams, data) {
    return _.mapValues(eventParams, function (value) {
        if (_.isFunction(value)) {
            return value(data);
        }
        else if (_.isArray(value)) {
            return _.map(value, function (item) { return fillParams(item, data); });
        }
        else if (_.isObject(value)) {
            return fillParams(value, data);
        }
        return value;
    });
}
export function buildReport(eventName, data, _a) {
    var ownerId = _a.ownerId;
    var events = getEvents({ ownerId: ownerId });
    var event = events[eventName];
    if (!event) {
        return;
    }
    data = _.merge({}, data, { ownerId: ownerId });
    return fillParams(event, data);
}
export default EVENT_NAMES;
