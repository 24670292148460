import { __assign } from "tslib";
import renderVideoSignInToWatchButtonText, { isVisible as isVideoSignInToWatchButtonVisible, } from '../video/sign-in-to-watch-button-text';
/**
 * Is video sign in to play button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @return {Boolean}
 */
export var isVisible = isVideoSignInToWatchButtonVisible;
/**
 * Video sign in to play button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.translation {Object} default is sharedTranslations.startWatching
 * @return {React.Component}
 */
export default function renderSignInToWatchButtonText(props) {
    return renderVideoSignInToWatchButtonText(__assign(__assign({}, props), { translation: props.translation || 'widget.overlay-buttons.start-watching' }));
}
