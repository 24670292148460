var WIDGET_PADDING = 20;
var VIDEOS_LIST_SPACING = 10;
export var MIN_WIDGET_WIDTH = 680;
export var MAX_WIDGET_WIDTH = 980;
export var VIDEO_HEIGHT_RATIO = 9 / 16;
var MOBILE_OVERLAY_HEIGHT_RATIO = 250 / 320;
var MOBILE_VIDEO_HEIGHT_RATIO = 180 / 320;
function roundAsBrowser(number) {
    return Math.floor(number * 100) / 100;
}
function playerHeightWithRatio(width) {
    return width * VIDEO_HEIGHT_RATIO;
}
export function playerSize(widgetWidth, widgetPadding) {
    if (widgetPadding === void 0) { widgetPadding = WIDGET_PADDING; }
    var width = widgetWidth - widgetPadding * 2;
    return {
        width: roundAsBrowser(width),
        height: roundAsBrowser(playerHeightWithRatio(width)),
    };
}
// TODO: move to mobile only selectors
export function playerSizeMobile(widgetWidth) {
    return playerSize(widgetWidth, 0);
}
// TODO: move to mobile only selectors
export function overlaySizeMobileMainItem(widgetWidth) {
    return {
        width: roundAsBrowser(widgetWidth),
        height: roundAsBrowser(widgetWidth * MOBILE_OVERLAY_HEIGHT_RATIO),
    };
}
export function playerSizeMobileMainItem(widgetWidth) {
    return {
        width: roundAsBrowser(widgetWidth),
        height: roundAsBrowser(widgetWidth * MOBILE_VIDEO_HEIGHT_RATIO),
    };
}
// TODO: move to strip only selectors
export function stripLayoutThumbnailSize(widgetWidth, videosInRow) {
    var width = widgetWidth / videosInRow;
    return {
        width: roundAsBrowser(width),
        height: roundAsBrowser(playerHeightWithRatio(width)),
    };
}
export function listThumbnailSize(widgetWidth, videosInRow) {
    var listWidth = widgetWidth - WIDGET_PADDING * 2;
    var width = (listWidth - (videosInRow - 1) * VIDEOS_LIST_SPACING) / videosInRow;
    return {
        width: roundAsBrowser(width),
        height: roundAsBrowser(playerHeightWithRatio(width)),
    };
}
