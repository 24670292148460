import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getVideosGroupedByIds } from '../../../../../shared/selectors/videos';
import { getPublicVideo } from '../channel/video/get';
import { createAsyncProxy } from '../../../../../shared/worker/lib';
export var ensureVideoLoaded = createAsyncProxy('ensureVideoLoaded', function (videoId) { return function (dispatch, getState) {
    var state = getState();
    var videoByIds = getVideosGroupedByIds(state);
    var channel = getChannelForWidget(state);
    return videoByIds[videoId]
        ? Promise.resolve()
        : dispatch(getPublicVideo(channel.id, videoId));
}; });
