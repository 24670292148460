import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isScheduledLive, isPendingLiveStream, isReadyLiveStream, } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';
import { getLocale, isRTL } from '../../../shared/selectors/app-settings';
import LiveLabel from '@wix/wix-vod-shared/dist/src/widget/ui-components/live-label/live-label';
import { LIVE_VIDEO_STATUS } from '@wix/wix-vod-constants/dist/common/live-statuses';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import ReminderIcon from './bell.svg';
var LiveLabelContainer = /** @class */ (function (_super) {
    __extends(LiveLabelContainer, _super);
    function LiveLabelContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LiveLabelContainer.prototype.render = function () {
        return (React.createElement(LiveLabel, __assign({}, this.props, { liveText: i18n.t('shared.live'), pendingLiveText: i18n.t('shared.pending-live'), scheduledSoonAriaLabel: i18n.t('widget.accessibility.scheduled-soon-label'), scheduledAriaLabel: i18n.t('widget.accessibility.scheduled-label'), startsInAriaLabel: i18n.t('widget.accessibility.starts-in'), reminderAriaLabel: i18n.t('widget.accessibility.reminder'), reminderIcon: React.createElement(ReminderIcon, null) })));
    };
    LiveLabelContainer.propTypes = {
        isPendingLiveStream: PropTypes.bool,
        isReadyLiveStream: PropTypes.bool,
        locale: PropTypes.string,
        startTime: PropTypes.number,
        videoTitle: PropTypes.string,
        isRTL: PropTypes.bool,
        liveVideoStatus: PropTypes.oneOf(LIVE_VIDEO_STATUS),
    };
    LiveLabelContainer = __decorate([
        connect(function (state, _a) {
            var itemType = _a.itemType, startTime = _a.startTime, liveVideoStatus = _a.liveVideoStatus;
            return ({
                startTime: startTime,
                isScheduled: isScheduledLive({ itemType: itemType }) && Boolean(startTime),
                isPendingLiveStream: isPendingLiveStream({ liveVideoStatus: liveVideoStatus }),
                isReadyLiveStream: isReadyLiveStream({ liveVideoStatus: liveVideoStatus }),
                locale: getLocale(state),
                isRTL: isRTL(state),
            });
        })
    ], LiveLabelContainer);
    return LiveLabelContainer;
}(React.Component));
export default LiveLabelContainer;
