var _a;
import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Thumbnail from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/thumbnail';
import ThumbnailContent from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/content/content';
import ThumbnailButtons from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/buttons/buttons';
import ThumbnailLiveLabel from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/live-label/live-label';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from '../../utils/withStyles';
import styles from './video-thumbnail.scss';
import { TEXT_ALIGNMENT_VALUES } from '@wix/wix-vod-constants/dist/app-settings/text-alignment-values';
import { canShowVideoListItemTitle, getVideoListPublisherVisibility, canShowVideoListItemDuration, canShowVideoListItemDescription, canShowVideoListItemContentBelow, isButtonsShownOnHover, isButtonsTextShown, getTextAlignment, getUseOptimalFontSizes, getTitleFont, getTextFont, getLocale, isRTL, } from '../../selectors/app-settings';
import { getVideoCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/cover';
import { getNormalizedCachedDimensions } from '@wix/wix-vod-shared/dist/src/common/utils/normalize-image-dimensions';
import { createGetPlayButtonConfig } from '../../../widget/layouts/compact/components/player-overlay/ui-selectors/video-overlay/play/button-config';
import { createGetPaidAccessButtonConfig } from '../../../widget/layouts/compact/components/player-overlay/ui-selectors/video-overlay/paid-access/button-config';
import formatMessageWithPrice from '../../utils/format-message-with-price';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import { isStreamingLive, isScheduledLive, isPendingLiveStream, } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';
import { getResizedImageUrl } from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';
var alignmentMap = (_a = {},
    _a[TEXT_ALIGNMENT_VALUES.LEFT] = 'left',
    _a[TEXT_ALIGNMENT_VALUES.CENTER] = 'center',
    _a[TEXT_ALIGNMENT_VALUES.RIGHT] = 'right',
    _a);
var VideoThumbnail = /** @class */ (function (_super) {
    __extends(VideoThumbnail, _super);
    function VideoThumbnail() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handlePlayClick = function () {
            var playButtonConfig = _this.props.playButtonConfig;
            if (!playButtonConfig) {
                return;
            }
            playButtonConfig.callback();
        };
        return _this;
    }
    VideoThumbnail.prototype.renderThumbnailContent = function () {
        var _a = this.props, videoItem = _a.videoItem, onPlayWithoutPreviewRequest = _a.onPlayWithoutPreviewRequest, showTitle = _a.showTitle, showPublisher = _a.showPublisher, showDescription = _a.showDescription, showContent = _a.showContent, isContentFocusable = _a.isContentFocusable, textAlignment = _a.textAlignment, isOptimalFontSizes = _a.isOptimalFontSizes, isRTL = _a.isRTL, titleFont = _a.titleFont, textFont = _a.textFont, renderThumbnailContent = _a.renderThumbnailContent, width = _a.width;
        if (!showContent) {
            return null;
        }
        var title = showTitle ? videoItem.title : '';
        var description = showDescription ? videoItem.description : '';
        var publisher = showPublisher ? videoItem.publisher : '';
        var alignment = alignmentMap[textAlignment];
        if (renderThumbnailContent) {
            return renderThumbnailContent({
                title: title,
                description: description,
                publisher: publisher,
                alignment: alignment,
                onClick: onPlayWithoutPreviewRequest,
            });
        }
        return (React.createElement(ThumbnailContent, { width: width, title: title, description: description, publisher: publisher, onClick: onPlayWithoutPreviewRequest, className: styles.content, titleClassName: styles.title, textClassName: styles.text, isFocusable: isContentFocusable, isOptimalFontSizes: isOptimalFontSizes, alignment: alignment, titleFont: titleFont, textFont: textFont, isRTL: isRTL }));
    };
    VideoThumbnail.prototype.renderThumbnailButtons = function () {
        var _a = this.props, isPlayerModuleLoaded = _a.isPlayerModuleLoaded, isRTL = _a.isRTL;
        var playButtonProps = isPlayerModuleLoaded
            ? this.getPlayButtonProps()
            : {};
        var thumbnailButtonsProps = __assign(__assign({}, playButtonProps), this.getBuyButtonProps());
        return (React.createElement(ThumbnailButtons, __assign({ showButtonsText: this.props.isButtonsTextShown, isFocusable: this.props.isContentFocusable }, thumbnailButtonsProps, { className: styles.buttons, isRTL: isRTL })));
    };
    VideoThumbnail.prototype.renderLiveLabel = function () {
        var _a = this.props, videoItem = _a.videoItem, locale = _a.locale, isContentFocusable = _a.isContentFocusable, isPendingLiveStream = _a.isPendingLiveStream, isRTL = _a.isRTL;
        if (!isLiveVideo(videoItem)) {
            return null;
        }
        return (React.createElement(ThumbnailLiveLabel, { videoTitle: videoItem.title, startTime: videoItem.dateStartLive, isStreaming: isStreamingLive(videoItem), isScheduled: isScheduledLive(videoItem), locale: locale, isFocusable: isContentFocusable, isPendingLiveStream: isPendingLiveStream, liveText: i18n.t('shared.live'), pendingLiveText: i18n.t('shared.pending-live'), scheduledSoonAriaLabel: i18n.t('widget.accessibility.scheduled-soon-label'), scheduledAriaLabel: i18n.t('widget.accessibility.scheduled-label'), startsInAriaLabel: i18n.t('widget.accessibility.starts-in'), reminderAriaLabel: i18n.t('widget.accessibility.reminder'), addReminderText: i18n.t('shared.add-reminder'), isRTL: isRTL }));
    };
    VideoThumbnail.prototype.getPlayButtonProps = function () {
        var playButtonConfig = this.props.playButtonConfig;
        if (!playButtonConfig) {
            return {
                showPlayButton: false,
            };
        }
        return {
            showPlayButton: true,
            playButtonText: i18n.t(playButtonConfig.translationData.props),
            onPlayClick: this.handlePlayClick,
        };
    };
    VideoThumbnail.prototype.getBuyButtonProps = function () {
        var paidAccessButtonConfig = this.props.paidAccessButtonConfig;
        if (!paidAccessButtonConfig) {
            return {
                showBuyButton: false,
            };
        }
        var _a = paidAccessButtonConfig.translationData.priceModel, currency = _a.currency, price = _a.price;
        return {
            showBuyButton: true,
            currency: currency,
            buyButtonText: formatMessageWithPrice(paidAccessButtonConfig.translationData.props, paidAccessButtonConfig.translationData.priceModel),
            buyAriaLabel: i18n.t(paidAccessButtonConfig.translationData.props, {
                price: "".concat(currency, " ").concat(price),
            }),
            onBuyClick: paidAccessButtonConfig.callback,
        };
    };
    VideoThumbnail.prototype.getBackgroundSrc = function () {
        var videoItem = this.props.videoItem;
        return getVideoCoverUrl(videoItem);
    };
    VideoThumbnail.prototype.getBackgroundUrl = function () {
        var _a = this.props, videoItem = _a.videoItem, width = _a.width;
        var src = getVideoCoverUrl(videoItem);
        var dimensions = getNormalizedCachedDimensions(src, {
            containerWidth: width,
            containerHeight: (width * 9) / 16,
        });
        return getResizedImageUrl({
            url: src,
            width: dimensions[0],
            height: dimensions[1],
        });
    };
    VideoThumbnail.prototype.render = function () {
        var _a = this.props, width = _a.width, breakpoints = _a.breakpoints, isButtonsShownOnHover = _a.isButtonsShownOnHover, videoItem = _a.videoItem, showDuration = _a.showDuration, isLoading = _a.isLoading, isContentFocusable = _a.isContentFocusable, dataHook = _a.dataHook, isRTL = _a.isRTL, useResponsiveThumbnail = _a.useResponsiveThumbnail;
        var duration = showDuration && videoItem.durationSec ? videoItem.durationStr : '';
        return (React.createElement(Thumbnail, { breakpoints: breakpoints, width: width, useResponsiveThumbnail: useResponsiveThumbnail || breakpoints, videoTitle: videoItem.title, isLoading: isLoading, dataHook: dataHook, onClick: this.handlePlayClick, overlayClassName: styles.overlay, backgroundUrl: this.getBackgroundUrl(), backgroundSrc: this.getBackgroundSrc(), buttons: this.renderThumbnailButtons(), content: this.renderThumbnailContent(), duration: duration, liveLabel: this.renderLiveLabel(), showButtonsOnHover: isButtonsShownOnHover, showLiveLabel: isLiveVideo(videoItem), isFocusable: isContentFocusable, ariaLabel: videoItem.title, isRTL: isRTL }));
    };
    VideoThumbnail.propTypes = {
        width: PropTypes.number.isRequired,
        videoItem: PropTypes.object.isRequired,
        breakpoints: PropTypes.object,
        playButtonConfig: PropTypes.object,
        paidAccessButtonConfig: PropTypes.object,
        onPlayWithoutPreviewRequest: PropTypes.func,
        showTitle: PropTypes.bool.isRequired,
        showDuration: PropTypes.bool.isRequired,
        showPublisher: PropTypes.bool.isRequired,
        showDescription: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool,
        isButtonsShownOnHover: PropTypes.bool.isRequired,
        isPendingLiveStream: PropTypes.bool.isRequired,
        locale: PropTypes.string,
        isButtonsTextShown: PropTypes.bool.isRequired,
        isContentFocusable: PropTypes.bool.isRequired,
        isOptimalFontSizes: PropTypes.bool.isRequired,
        textAlignment: PropTypes.number.isRequired,
        dataHook: PropTypes.string,
        titleFont: PropTypes.object,
        textFont: PropTypes.object,
        isPlayerModuleLoaded: PropTypes.bool,
        isRTL: PropTypes.bool,
        useResponsiveThumbnail: PropTypes.bool,
        renderThumbnailContent: PropTypes.func,
    };
    VideoThumbnail = __decorate([
        connect(function () {
            var getPlayButtonConfig = createGetPlayButtonConfig();
            var getPaidAccessButtonConfig = createGetPaidAccessButtonConfig();
            return function (state, ownProps) { return ({
                playButtonConfig: getPlayButtonConfig(state, ownProps),
                paidAccessButtonConfig: getPaidAccessButtonConfig(state, ownProps),
                showTitle: canShowVideoListItemTitle(state),
                showDuration: canShowVideoListItemDuration(state),
                showPublisher: getVideoListPublisherVisibility(state),
                showDescription: canShowVideoListItemDescription(state),
                showContent: canShowVideoListItemContentBelow(state),
                isButtonsShownOnHover: isButtonsShownOnHover(state),
                locale: getLocale(state),
                isButtonsTextShown: isButtonsTextShown(state),
                isPendingLiveStream: isPendingLiveStream(ownProps.videoItem),
                textAlignment: getTextAlignment(state),
                isOptimalFontSizes: getUseOptimalFontSizes(state),
                titleFont: getTitleFont(state),
                textFont: getTextFont(state),
                isPlayerModuleLoaded: state.modules.player,
                isRTL: isRTL(state),
            }); };
        }),
        withStyles(styles)
    ], VideoThumbnail);
    return VideoThumbnail;
}(React.Component));
export default VideoThumbnail;
