import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import decorate from '../../../../utils/decorate';
import { playVideo } from '../../../../redux/client/actions/player/change-playback-status';
import { selectVideo } from '../../../../redux/client/actions/select-video';
import InFrameActions from './in-frame';
var InFrameMobileActionCallbacks = /** @class */ (function (_super) {
    __extends(InFrameMobileActionCallbacks, _super);
    function InFrameMobileActionCallbacks() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectVideo = function () {
            var _a = _this.props, selectVideo = _a.selectVideo, videoId = _a.videoId;
            selectVideo(videoId);
        };
        _this.playVideo = function () {
            var _a = _this.props, playVideo = _a.playVideo, videoId = _a.videoId;
            _this.props.onPlayRequestedBi();
            playVideo(videoId);
        };
        _this.handlePlayRequest = function () {
            var _a = _this.props, isMainVideo = _a.isMainVideo, onBeforePlayRequested = _a.onBeforePlayRequested, onBeforeClick = _a.onBeforeClick;
            var handler = isMainVideo
                ? _.flow(onBeforePlayRequested, _this.playVideo)
                : _.flow(onBeforeClick, _this.selectVideo);
            handler();
        };
        return _this;
    }
    InFrameMobileActionCallbacks.prototype.render = function () {
        var children = this.props.children;
        return React.cloneElement(children, __assign(__assign({}, this.props), { onPlayRequest: this.handlePlayRequest }));
    };
    InFrameMobileActionCallbacks.propTypes = {
        children: PropTypes.element.isRequired,
        selectVideo: PropTypes.func.isRequired,
        playVideo: PropTypes.func.isRequired,
        onBeforeClick: PropTypes.func,
        onBeforePlayRequested: PropTypes.func,
        onPlayRequestedBi: PropTypes.func,
        channelId: PropTypes.string,
        videoId: PropTypes.string,
        videoItem: PropTypes.object,
        isMainVideo: PropTypes.bool,
    };
    InFrameMobileActionCallbacks = __decorate([
        connect(null, {
            playVideo: playVideo,
            selectVideo: selectVideo,
        }),
        decorate(InFrameActions)
    ], InFrameMobileActionCallbacks);
    return InFrameMobileActionCallbacks;
}(React.Component));
export default InFrameMobileActionCallbacks;
