import { __read, __spreadArray } from "tslib";
import _ from 'lodash';
var topics = {};
export default {
    subscribe: function (topic, listener) {
        if (!topics[topic]) {
            topics[topic] = [];
        }
        var index = topics[topic].push(listener) - 1;
        return function () {
            topics[topic].splice(index, 1);
        };
    },
    publish: function (topic) {
        var data = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            data[_i - 1] = arguments[_i];
        }
        if (!topics[topic]) {
            return;
        }
        _.forEach(topics[topic], function (item) {
            item.apply(void 0, __spreadArray([], __read(data), false));
        });
    },
};
