import { canSubscribeToChannel, canSubscribeToChannelByPricingPlan, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/channel-access';
/**
 * Is video subscribe button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @return {Boolean}
 */
export function isSubscriptionButtonVisible(_a) {
    var _b = _a.channel, channel = _b === void 0 ? {} : _b;
    return (canSubscribeToChannel(channel) ||
        canSubscribeToChannelByPricingPlan(channel));
}
