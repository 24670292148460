import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { logBi } from '../../../shared/worker/actions/bi';
import { areElementsRounded, getCanShowChannelTags, } from '../../../shared/selectors/app-settings';
import TagsCloud from '../../components/tags-cloud/tags-cloud';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from './tags.scss';
import { isMobile } from '../../../shared/selectors/form-factor';
var Tags = /** @class */ (function (_super) {
    __extends(Tags, _super);
    function Tags() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onTagClick = function (tag) {
            _this.sendTagSelectedBI();
            _this.props.onTagClick(tag);
        };
        return _this;
    }
    Tags.prototype.getTagList = function () {
        return _.map(this.props.tags || [], 'value');
    };
    Tags.prototype.sendTagSelectedBI = function () {
        this.props.logBi('widget.tags.selected');
    };
    Tags.prototype.render = function () {
        var _a;
        var _b = this.props, className = _b.className, tagClassName = _b.tagClassName, roundedTagClassName = _b.roundedTagClassName, isVisible = _b.isVisible, isRounded = _b.isRounded, onTagFocus = _b.onTagFocus, onTagBlur = _b.onTagBlur, isMobile = _b.isMobile;
        if (!isVisible) {
            return null;
        }
        var tagClassNames = classnames(styles.tag, tagClassName, (_a = {},
            _a[styles.mobile] = isMobile,
            _a));
        return (React.createElement(TagsCloud, { className: className, tagClassName: tagClassNames, isRounded: isRounded, roundedTagClassName: roundedTagClassName, tags: this.getTagList(), onTagBlur: onTagBlur, onTagFocus: onTagFocus, onTagClick: this.onTagClick }));
    };
    Tags.propTypes = {
        tags: PropTypes.array,
        className: PropTypes.string,
        tagClassName: PropTypes.string,
        roundedTagClassName: PropTypes.string,
        onTagFocus: PropTypes.func,
        onTagClick: PropTypes.func,
        onTagBlur: PropTypes.func,
        isVisible: PropTypes.bool,
        isRounded: PropTypes.bool,
        isMobile: PropTypes.bool.isRequired,
    };
    Tags.defaultProps = {
        onTagClick: _.noop,
    };
    Tags = __decorate([
        connect(function (state) { return ({
            isMobile: isMobile(state),
            isVisible: getCanShowChannelTags(state),
            isRounded: areElementsRounded(state),
        }); }, { logBi: logBi }),
        withStyles(styles)
    ], Tags);
    return Tags;
}(React.Component));
export default Tags;
