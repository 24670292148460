import { widgetWrapper } from '../shared/worker/widgetWrapper';
import { Component } from './mobile.component';
import { createStore } from './mobile.store';
export var WidgetComponent = widgetWrapper({
    createStore: createStore,
    Component: Component,
});
export default {
    component: WidgetComponent,
};
