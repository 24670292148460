import _ from 'lodash';
import { createSelector } from 'reselect';
import { getVideosByIds } from './videos';
export var isVideoDownloadEnabled = function (video) {
    return _.get(video, 'allowDownload', false);
};
export var isChannelDownloadEnabled = function (videos) {
    return _.some(videos, isVideoDownloadEnabled);
};
var _isDownloadEnabled = createSelector(getVideosByIds, _.nthArg(1), function (videos, videoId) {
    return videoId
        ? isVideoDownloadEnabled(videos[videoId])
        : isChannelDownloadEnabled(videos);
});
export var isDownloadEnabled = function (state, videoId) {
    if (videoId === void 0) { videoId = null; }
    return _isDownloadEnabled(state, videoId);
};
