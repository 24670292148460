import { getAvailableVideoType, getFullAccessActionName, canPlayFullOrPreview, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import getTranslationData from './translation-data';
import getCallbackFromProps from './callback';
export default function getPlayButtonConfig(props) {
    var channelData = props.channelData, videoItem = props.videoItem, currentSiteUser = props.currentSiteUser;
    var availableVideoType = getAvailableVideoType(channelData, videoItem, currentSiteUser);
    var fullAccessAction = getFullAccessActionName(channelData, videoItem, currentSiteUser);
    var callback = getCallbackFromProps(props, canPlayFullOrPreview(channelData, videoItem, currentSiteUser), fullAccessAction);
    var translationData = getTranslationData(availableVideoType, fullAccessAction);
    return {
        callback: callback,
        translationData: translationData,
    };
}
