import { __assign, __decorate, __extends } from "tslib";
import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { getSlideWidth, getTrackLeft } from './sizes';
import Track from './track';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './mobile-carousel.scss';
/*
 Mobile carousel is inspired by https://github.com/akiran/react-slick/tree/master/src
*/
function getTrackCSS(_a) {
    var slideCount = _a.slideCount, slidesToShow = _a.slidesToShow, slideWidth = _a.slideWidth, left = _a.left, right = _a.right;
    var trackWidth = (slideCount + 2 * slidesToShow) * slideWidth;
    var value = _.isNumber(left) ? left : right;
    return {
        opacity: 1,
        width: trackWidth,
        WebkitTransform: "translateX(".concat(value, "px)"),
        transform: "translateX(".concat(value, "px)"),
        transition: '',
        WebkitTransition: '',
        msTransform: "translateX(".concat(value, "px)"),
    };
}
function getTrackAnimateCSS(spec) {
    var style = getTrackCSS(spec);
    // useCSS is true by default so it can be undefined
    style.WebkitTransition = "-webkit-transform '".concat(spec.speed, "ms ").concat(spec.cssEase);
    style.transition = "transform ".concat(spec.speed, "ms ").concat(spec.cssEase);
    return style;
}
function calcSwipeDirection(touchObject) {
    var xDist = touchObject.startX - touchObject.curX;
    var yDist = touchObject.startY - touchObject.curY;
    var r = Math.atan2(yDist, xDist);
    var swipeAngle = Math.round((r * 180) / Math.PI);
    if (swipeAngle < 0) {
        swipeAngle = 360 - Math.abs(swipeAngle);
    }
    if ((swipeAngle <= 45 && swipeAngle >= 0) ||
        (swipeAngle <= 360 && swipeAngle >= 315)) {
        return 'left';
    }
    if (swipeAngle >= 135 && swipeAngle <= 225) {
        return 'right';
    }
    return 'vertical';
}
var MobileCarousel = /** @class */ (function (_super) {
    __extends(MobileCarousel, _super);
    function MobileCarousel(props) {
        var _this = _super.call(this, props) || this;
        _this.update = function () {
            if (!_this.wrapper) {
                return;
            }
            var _a = _this.props, slidesPreviewWidth = _a.slidesPreviewWidth, isRTL = _a.isRTL;
            var measurements = _this.getMeasurements();
            _this.setState(measurements, function () {
                var _a;
                var targetLeft = _this.getTrackLeft(_this._currentSlide, measurements.slideWidth, slidesPreviewWidth);
                var direction = isRTL ? 'right' : 'left';
                // getCSS function needs previously set state
                var trackStyle = getTrackCSS(_.assign((_a = {},
                    _a[direction] = targetLeft,
                    _a.slideCount = _this.getSlidesCount(),
                    _a), _this.props, _this.state));
                _this.setTrackStyle(trackStyle);
            });
        };
        _this.swipeStart = function (e) {
            var posX = _.isUndefined(e.touches) ? e.clientX : e.touches[0].pageX;
            var posY = _.isUndefined(e.touches) ? e.clientY : e.touches[0].pageY;
            _this._dragging = true;
            _this._animating = false;
            _this._touchObject = {
                startX: posX,
                startY: posY,
                curX: posX,
                curY: posY,
            };
        };
        _this.getSlidesCount = function () { return React.Children.count(_this.props.children); };
        _this.swipeMove = function (e) {
            var _a;
            if (!_this._dragging) {
                return;
            }
            if (_this._animating) {
                return;
            }
            var touchObject = _.clone(_this._touchObject);
            var slideWidth = _this.state.slideWidth;
            var _b = _this.props, slidesToShow = _b.slidesToShow, slidesPreviewWidth = _b.slidesPreviewWidth, onUserStartedTracking = _b.onUserStartedTracking, edgeFriction = _b.edgeFriction, isRTL = _b.isRTL;
            var currentSlide = _this._currentSlide;
            var curLeft = _this.getTrackLeft(currentSlide, slideWidth, slidesPreviewWidth);
            touchObject.curX = e.touches ? e.touches[0].pageX : e.clientX;
            touchObject.curY = e.touches ? e.touches[0].pageY : e.clientY;
            touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(touchObject.curX - touchObject.startX, 2)));
            var positionOffset = touchObject.curX > touchObject.startX ? 1 : -1;
            var slidesCount = _this.getSlidesCount();
            var swipeDirection = calcSwipeDirection.call(_this, touchObject);
            var touchSwipeLength = touchObject.swipeLength;
            if ((currentSlide === 0 && swipeDirection === 'right') ||
                (currentSlide >= slidesCount - slidesToShow && swipeDirection === 'left')) {
                touchSwipeLength = touchObject.swipeLength * edgeFriction;
            }
            var swipeLeft = curLeft + touchSwipeLength * positionOffset;
            _this._touchObject = touchObject;
            var direction = isRTL ? 'right' : 'left';
            var trackStyle = getTrackCSS(_.assign((_a = {},
                _a[direction] = swipeLeft,
                _a.slideCount = _this.getSlidesCount(),
                _a), _this.props, _this.state));
            _this.setTrackStyle(trackStyle);
            if (touchObject.swipeLength > 4) {
                onUserStartedTracking();
            }
        };
        _this.swipeEnd = function (e) {
            var _a;
            if (!_this._dragging) {
                return;
            }
            var _b = _this.props, touchThreshold = _b.touchThreshold, isRTL = _b.isRTL, slidesPreviewWidth = _b.slidesPreviewWidth;
            var touchObject = _this._touchObject;
            var minSwipe = _this.state.listWidth / touchThreshold;
            var swipeDirection = calcSwipeDirection.call(_this, touchObject);
            var currentSlide = _this._currentSlide;
            // reset the state of touch related state variables.
            _this._dragging = false;
            _this._touchObject = {};
            // Fix for #13
            if (!touchObject.swipeLength) {
                return;
            }
            var needSwipeDirection = isRTL ? 'right' : 'left';
            var oppositeSwipeDirection = isRTL ? 'left' : 'right';
            if (touchObject.swipeLength > minSwipe) {
                var slidesToScroll = _this.calcSlidesToScroll(touchObject.swipeLength);
                e.preventDefault();
                if (swipeDirection === needSwipeDirection) {
                    _this.slideHandler(currentSlide + slidesToScroll);
                }
                else if (swipeDirection === oppositeSwipeDirection) {
                    _this.slideHandler(currentSlide - slidesToScroll);
                }
                else {
                    _this.slideHandler(currentSlide);
                }
            }
            else {
                // Adjust the track back to it's original position.
                var currentLeft = _this.getTrackLeft(currentSlide, _this.state.slideWidth, slidesPreviewWidth);
                _this.setTrackStyle(getTrackAnimateCSS(_.assign((_a = {},
                    _a[needSwipeDirection] = currentLeft,
                    _a.slideCount = _this.getSlidesCount(),
                    _a), _this.props, _this.state)));
            }
        };
        _this.handlePrevButtonClick = function () {
            _this.changeSlide({ action: 'previous' });
        };
        _this.handleNextButtonClick = function () {
            _this.changeSlide({ action: 'next' });
        };
        _this.saveTrackRef = function (node) {
            _this.track = node;
        };
        _this.saveWrapperRef = function (node) {
            _this.wrapper = node;
            _this.update();
        };
        _this.saveListRef = function (node) {
            _this.list = node;
        };
        _this._currentSlide = props.initialSlide;
        _this._touchObject = {
            startX: 0,
            startY: 0,
            curX: 0,
            curY: 0,
        };
        _this.state = _this.getMeasurements();
        return _this;
    }
    MobileCarousel.prototype.componentDidMount = function () {
        this.update();
        window.addEventListener('resize', this.update);
    };
    MobileCarousel.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        if (nextProps.initialSlide !== this.props.initialSlide) {
            this._currentSlide = nextProps.initialSlide;
            this.update();
        }
    };
    MobileCarousel.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.update);
    };
    MobileCarousel.prototype.getMeasurements = function () {
        var _a = this.props, slidesToShow = _a.slidesToShow, slidesPreviewWidth = _a.slidesPreviewWidth, width = _a.width;
        return {
            listWidth: width,
            trackWidth: width,
            slideWidth: getSlideWidth(width, slidesToShow, {
                slidesPreviewWidth: slidesPreviewWidth,
            }),
        };
    };
    MobileCarousel.prototype.getTrackLeft = function (slideIndex, slideWidth, slidesPreviewWidth) {
        var isRTL = this.props.isRTL;
        var trackLeft = getTrackLeft(this.getSlidesCount(), this.props.slidesToShow, slideIndex, slideWidth, slidesPreviewWidth);
        return isRTL ? -trackLeft : trackLeft;
    };
    MobileCarousel.prototype.setTrackStyle = function (style) {
        _.assign(this.track.style, style);
    };
    MobileCarousel.prototype.calcSlidesToScroll = function (swipeLength) {
        var _a = this.state, slideWidth = _a.slideWidth, listWidth = _a.listWidth;
        var touchThreshold = this.props.touchThreshold;
        var minSwipe = listWidth / touchThreshold;
        var slidesCount = Math.floor(swipeLength / slideWidth);
        var lastSlideLength = swipeLength % slideWidth;
        if (lastSlideLength > minSwipe) {
            slidesCount += 1;
        }
        return slidesCount;
    };
    MobileCarousel.prototype.slideHandler = function (index) {
        var _a, _b;
        var _this = this;
        // Functionality of animateSlide and postSlide is merged into this function
        var _c = this.props, waitForAnimate = _c.waitForAnimate, slidesToShow = _c.slidesToShow, slidesPreviewWidth = _c.slidesPreviewWidth, beforeChange = _c.beforeChange, isRTL = _c.isRTL;
        if (waitForAnimate && this._animating) {
            return;
        }
        var currentSlide;
        var slideCount = this.getSlidesCount();
        var targetSlide = index;
        if (targetSlide < 0) {
            currentSlide = 0;
        }
        else if (targetSlide > slideCount - slidesToShow) {
            currentSlide = slideCount - slidesToShow;
        }
        else {
            currentSlide = targetSlide;
        }
        var targetLeft = this.getTrackLeft(currentSlide, this.state.slideWidth, slidesPreviewWidth);
        if (beforeChange) {
            beforeChange(this._currentSlide, currentSlide);
        }
        // Slide Transition happens here.
        // animated transition happens to target Slide and
        // non - animated transition happens to current Slide
        var direction = isRTL ? 'right' : 'left';
        var nextStateChanges = {
            trackStyle: getTrackCSS(__assign(__assign((_a = {}, _a[direction] = targetLeft, _a.slideCount = this.getSlidesCount(), _a), this.props), this.state)),
            swipeLeft: null,
        };
        var callback = function () {
            _this._animating = false;
            _this.setTrackStyle(nextStateChanges.trackStyle);
            _this.props.afterChange(currentSlide);
            _this.track.removeEventListener('transitionend', callback);
        };
        this._animating = true;
        var trackStyle = getTrackAnimateCSS(_.assign((_b = {},
            _b[direction] = targetLeft,
            _b.slideCount = this.getSlidesCount(),
            _b), this.props, this.state));
        this.setTrackStyle(trackStyle);
        this._currentSlide = currentSlide;
        this.track.addEventListener('transitionend', callback);
    };
    MobileCarousel.prototype.changeSlide = function (_a) {
        var action = _a.action, slideIndex = _a.slideIndex;
        var slideOffset;
        var targetSlide;
        var slideCount = this.getSlidesCount();
        var currentSlide = this._currentSlide;
        var slidesToScroll = this.props.slidesToScroll;
        var unevenOffset = slideCount % slidesToScroll !== 0;
        var indexOffset = unevenOffset
            ? 0
            : (slideCount - currentSlide) % slidesToScroll;
        if (action === 'previous') {
            slideOffset =
                indexOffset === 0
                    ? slidesToScroll
                    : this.props.slidesToShow - indexOffset;
            targetSlide = currentSlide - slideOffset;
        }
        else if (action === 'next') {
            slideOffset = indexOffset === 0 ? slidesToScroll : indexOffset;
            targetSlide = currentSlide + slideOffset;
        }
        else if (action === 'index') {
            targetSlide = slideIndex;
            if (targetSlide === currentSlide) {
                return;
            }
        }
        this.slideHandler(targetSlide);
    };
    MobileCarousel.prototype.renderChildren = function () {
        var slideWidth = this.state.slideWidth;
        var _a = this.props, getHeight = _a.getHeight, children = _a.children;
        if (!slideWidth) {
            return null;
        }
        return React.Children.map(children, function (elem) {
            return React.cloneElement(elem, {
                width: slideWidth,
                height: getHeight(elem, slideWidth),
            });
        });
    };
    MobileCarousel.prototype.render = function () {
        var trackProps = {
            cssEase: this.props.cssEase,
            speed: this.props.speed,
            currentSlide: this._currentSlide,
            slideWidth: this.state.slideWidth,
            slidesToShow: this.props.slidesToShow,
            slideCount: this.getSlidesCount(),
            trackStyle: this.state.trackStyle,
        };
        var _a = this.props, className = _a.className, trackClassName = _a.trackClassName;
        return (React.createElement("div", { ref: this.saveWrapperRef, className: classnames(styles.container, className) },
            React.createElement("div", { ref: this.saveListRef, onMouseDown: this.swipeStart, onMouseMove: this.swipeMove, onMouseUp: this.swipeEnd, onMouseLeave: this.swipeEnd, onTouchStart: this.swipeStart, onTouchMove: this.swipeMove, onTouchEnd: this.swipeEnd, onTouchCancel: this.swipeEnd },
                React.createElement(Track, __assign({ getRef: this.saveTrackRef, className: trackClassName }, trackProps), this.renderChildren()))));
    };
    MobileCarousel.propTypes = {
        width: PropTypes.number,
        totalCount: PropTypes.number,
        slidesToShow: PropTypes.number,
        afterChange: PropTypes.func,
        children: PropTypes.any,
        className: PropTypes.string,
        touchThreshold: PropTypes.number,
        slidesToScroll: PropTypes.number,
        initialSlide: PropTypes.number,
        slidesPreviewWidth: PropTypes.number,
        cssEase: PropTypes.string,
        edgeFriction: PropTypes.number,
        speed: PropTypes.number,
        getHeight: PropTypes.func,
        beforeChange: PropTypes.func,
        waitForAnimate: PropTypes.bool,
        trackClassName: PropTypes.string,
        onUserStartedTracking: PropTypes.func,
        isRTL: PropTypes.bool,
    };
    MobileCarousel.defaultProps = {
        className: '',
        cssEase: 'ease',
        easing: 'linear',
        edgeFriction: 0.35,
        initialSlide: 0,
        slidesToScroll: 1,
        speed: 500,
        getHeight: _.constant(200),
        slidesToShow: 3,
        touchThreshold: 5,
        useCSS: true,
        waitForAnimate: true,
        afterChange: _.noop,
        beforeChange: _.noop,
        onUserStartedTracking: _.noop,
        slidesPreviewWidth: 0,
    };
    MobileCarousel = __decorate([
        withStyles(styles)
    ], MobileCarousel);
    return MobileCarousel;
}(React.Component));
export default MobileCarousel;
