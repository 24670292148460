import _ from 'lodash';
import { createAction } from 'redux-actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from '../../../../../shared/selectors/current-site-user';
import { canShowChannelCover } from '../../../../components/player-overlay/selectors';
import { openPreviewPrompt } from '../../../../../shared/redux/client/actions/modal';
import { logPlayButtonClicked } from '../../../../components/player-overlay/bi';
import * as viewModeSelectors from '../../../../selectors/view-mode';
import { createProxy } from '../../../../../shared/worker/lib';
var PLAY_SELECTED_VIDEO = 'CLIENT.PLAYER_OVERLAY.PLAY_SELECTED_VIDEO';
var playSelectedVideoAction = createAction(PLAY_SELECTED_VIDEO);
export var playSelectedVideo = createProxy('playSelectedVideo', function (_a) {
    var videoItem = _a.videoItem, _b = _a.onPlaySelectedVideo, onPlaySelectedVideo = _b === void 0 ? _.noop : _b, _c = _a.shouldSendPlayStartBi, shouldSendPlayStartBi = _c === void 0 ? true : _c, showChannelCover = _a.showChannelCover;
    return function (dispatch, getState) {
        var state = getState();
        var channelData = getChannelForWidget(state);
        var currentSiteUser = getCurrentSiteUser(state);
        var isChannelCover = canShowChannelCover(state, { showChannelCover: showChannelCover });
        var isPreview = viewModeSelectors.isPreviewMode(state);
        var isEditor = viewModeSelectors.isEditorMode(state);
        var isPreviewOrEditorMode = isPreview || isEditor;
        if (isPreviewOrEditorMode && videoItem.memberOnly) {
            dispatch(openPreviewPrompt());
            return;
        }
        if (shouldSendPlayStartBi) {
            dispatch(logPlayButtonClicked({
                channelData: channelData,
                videoItem: videoItem,
                currentSiteUser: currentSiteUser,
                isChannelCover: isChannelCover,
            }));
        }
        onPlaySelectedVideo(videoItem);
        dispatch(playSelectedVideoAction());
    };
});
