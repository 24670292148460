import { FULL_ACCESS_ACTION_NAMES } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { DEFAULT_TRANSLATIONS as PURCHASE_TRANSLATIONS } from '../../../../../../../../shared/utils/buy-button-texts/buy-button-texts';
import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
export default function getTranslationData(fullAccessAction, channel, video) {
    switch (fullAccessAction) {
        case FULL_ACCESS_ACTION_NAMES.BUY:
            return {
                props: PURCHASE_TRANSLATIONS[fullAccessAction],
                priceModel: dealInfoHelpers.getSalePriceInfo(video.dealInfo),
            };
        case FULL_ACCESS_ACTION_NAMES.RENT:
            return {
                props: PURCHASE_TRANSLATIONS[fullAccessAction],
                priceModel: dealInfoHelpers.getRentPriceInfo(video.dealInfo),
            };
        case FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT:
            return {
                props: PURCHASE_TRANSLATIONS[fullAccessAction],
                priceModel: dealInfoHelpers.getMinPriceInfo(video.dealInfo),
            };
        case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
            return {
                props: 'widget.overlay-buttons.get-subscription',
                priceModel: dealInfoHelpers.getSubscriptionPriceInfo(channel.dealInfo),
            };
        case FULL_ACCESS_ACTION_NAMES.PRICING_PLAN:
            return {
                props: PURCHASE_TRANSLATIONS[fullAccessAction],
                priceModel: dealInfoHelpers.getPricingPlanInfo(channel.dealInfo),
            };
        default:
            return null;
    }
}
