import { __awaiter, __decorate, __extends, __generator } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWidgetSize } from '../../../widget/selectors/layout';
import * as viewModeSelectors from '../../../widget/selectors/view-mode';
import WixSDK from '../../../shared/utils/wix-sdk';
import { resizeComponent } from '../../../shared/worker/actions/resize/resize-component';
import { setWidgetHeight } from '../../../shared/worker/actions/resize/set-widget-height';
var WidgetHeightByContentSync = /** @class */ (function (_super) {
    __extends(WidgetHeightByContentSync, _super);
    function WidgetHeightByContentSync() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleIframeResize = _.debounce(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.resizeWidget()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, 300);
        _this.resizeWidget = function () { return __awaiter(_this, void 0, void 0, function () {
            var rect, _a, widgetSize, isEditor, resizeComponent, setWidgetHeight, height, width, isSizeChanged;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!WixSDK || !WixSDK.getBoundingRectAndOffsets) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, WixSDK.getBoundingRectAndOffsets()];
                    case 1:
                        rect = (_b.sent()).rect;
                        _a = this.props, widgetSize = _a.widgetSize, isEditor = _a.isEditor, resizeComponent = _a.resizeComponent, setWidgetHeight = _a.setWidgetHeight;
                        height = widgetSize.height, width = widgetSize.width;
                        isSizeChanged = rect.height !== height || rect.width !== width;
                        if (isSizeChanged) {
                            if (isEditor) {
                                resizeComponent({
                                    height: height,
                                    width: width,
                                });
                            }
                            else {
                                setWidgetHeight(height, width);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    WidgetHeightByContentSync.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.handleIframeResize()];
                    case 1:
                        _a.sent();
                        window.addEventListener('resize', this.handleIframeResize);
                        return [2 /*return*/];
                }
            });
        });
    };
    WidgetHeightByContentSync.prototype.componentDidUpdate = function (prevProps) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, height, width;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props.widgetSize, height = _a.height, width = _a.width;
                        if (!(prevProps.height !== height || prevProps.width !== width)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.resizeWidget()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    WidgetHeightByContentSync.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.handleIframeResize);
    };
    WidgetHeightByContentSync.prototype.render = function () {
        var _a = this.props, children = _a.children, windowSize = _a.windowSize;
        return (React.createElement("div", { style: { width: windowSize.width }, role: "presentation" }, children));
    };
    WidgetHeightByContentSync.propTypes = {
        windowSize: PropTypes.object.isRequired,
        widgetSize: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
        isEditor: PropTypes.bool.isRequired,
        resizeComponent: PropTypes.func.isRequired,
        setWidgetHeight: PropTypes.func.isRequired,
    };
    WidgetHeightByContentSync = __decorate([
        connect(function (state) { return ({
            // lazyVideoLists is needed to trigger height calculations
            lazyVideoLists: state.lazyVideoLists,
            windowSize: state.windowSize,
            widgetSize: getWidgetSize(state),
            isEditor: viewModeSelectors.isEditorMode(state),
        }); }, {
            resizeComponent: resizeComponent,
            setWidgetHeight: setWidgetHeight,
        })
    ], WidgetHeightByContentSync);
    return WidgetHeightByContentSync;
}(React.Component));
export default WidgetHeightByContentSync;
