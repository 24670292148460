import { BI_EVENT } from './constants';
import { getCompId } from '../../widget/redux/client/hydrated-data/hydrated-data';
import { pubSubPublish } from '../worker/actions/pub-sub/publish';
import { createProxy } from '../worker/lib';
export var sendBiEvent = createProxy('sendBiEvent', function (eventName, params) { return function (dispatch, getState) {
    dispatch(pubSubPublish(BI_EVENT, {
        eventName: eventName,
        params: params,
        targetCompId: getCompId(getState()),
    }));
}; });
