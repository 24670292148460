import { __assign } from "tslib";
import React from 'react';
import ClampText from '@wix/wix-vod-shared/dist/src/common/components/clamp-text/clamp-text';
import PropTypes from 'prop-types';
var defaultClampOptions = {
    lineClamp: 2,
};
var Title = function (_a) {
    var text = _a.text, _b = _a.clampOptions, clampOptions = _b === void 0 ? {} : _b, enforceJSClamp = _a.enforceJSClamp;
    return (React.createElement(ClampText, { text: text, dataHook: "title", wrapperTag: "h3", enforceJSClamp: enforceJSClamp, clampOptions: __assign(__assign({}, defaultClampOptions), clampOptions) }));
};
Title.propTypes = {
    text: PropTypes.string,
    clampOptions: PropTypes.object,
    enforceJSClamp: PropTypes.bool,
};
export default Title;
