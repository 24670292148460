import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import formatMessageWithPrice from '../../../../../shared/utils/format-message-with-price';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { callOnEnterPressExactOnElement } from '../../../../../shared/utils/call-on-keyboard-press';
import { isThumbnailsPreviewHover, isVideosListInfoAlwaysShown, isVideosListInfoNeverShown, } from '../../../../../shared/selectors/app-settings';
import { createGetPlayButtonConfig } from '../player-overlay/ui-selectors/video-overlay/play/button-config';
import { createGetPaidAccessButtonConfig } from '../player-overlay/ui-selectors/video-overlay/paid-access/button-config';
import { isShareOverlayOpen } from '../../../../selectors/share-overlay';
import Title from '../player-overlay/partials/title';
import PaidAccessButton from '../player-overlay/partials/paid-access-button';
import PlayButton from '../player-overlay/partials/play-button';
import VideoCover from '../../../../../shared/components/video-cover/video-cover';
import LiveLabel from '../../../../containers/live-label/live-label';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './video-thumbnail-overlay.scss';
import { getCurrency } from '../../../../selectors/currency';
// TODO: replace `getPlayButtonConfig` and `getPaidAccessButtonConfig` with `getPlayButtonText` approach
// TODO: add `currentSiteUser` selector to `@connect` and remove it from parent components props
// see https://github.com/wix-private/wix-vod/pull/2003#discussion_r148538134 for details
var VideoThumbnailOverlay = /** @class */ (function (_super) {
    __extends(VideoThumbnailOverlay, _super);
    function VideoThumbnailOverlay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VideoThumbnailOverlay.prototype.renderTitle = function () {
        var videoItem = this.props.videoItem;
        return (React.createElement("div", { className: styles['title-block'] },
            React.createElement(Title, { className: styles.title }, videoItem.title)));
    };
    VideoThumbnailOverlay.prototype.renderPlayButton = function () {
        var _a = this.props, playButtonConfig = _a.playButtonConfig, isContentFocusable = _a.isContentFocusable;
        if (!playButtonConfig) {
            return null;
        }
        return (React.createElement(PlayButton, { className: styles.button, iconClassName: styles.icon, onClick: playButtonConfig.callback, isFocusable: isContentFocusable, isIconOnly: true }, i18n.t(playButtonConfig.translationData.props)));
    };
    VideoThumbnailOverlay.prototype.renderPaidAccessButton = function () {
        var _a = this.props, paidAccessButtonConfig = _a.paidAccessButtonConfig, isContentFocusable = _a.isContentFocusable, videoItem = _a.videoItem, channelData = _a.channelData;
        if (!paidAccessButtonConfig) {
            return null;
        }
        var currency = getCurrency(videoItem, channelData);
        return (React.createElement(PaidAccessButton, { className: styles.button, iconClassName: styles.icon, onClick: paidAccessButtonConfig.callback, isFocusable: isContentFocusable, currency: currency, isIconOnly: true }, formatMessageWithPrice(paidAccessButtonConfig.translationData.props, paidAccessButtonConfig.translationData.priceModel)));
    };
    VideoThumbnailOverlay.prototype.renderLiveLabel = function () {
        var videoItem = this.props.videoItem;
        return (React.createElement(LiveLabel, { itemType: videoItem.itemType, liveVideoStatus: videoItem.liveVideoStatus, startTime: videoItem.dateStartLive, isSmall: true, noBackground: true, className: styles['live-label'], smallClassName: styles['shown-on-hover'] }));
    };
    VideoThumbnailOverlay.prototype.render = function () {
        var _a;
        var _b = this.props, videoItem = _b.videoItem, className = _b.className, isOverlayNeverShown = _b.isOverlayNeverShown, isOverlayAlwaysShown = _b.isOverlayAlwaysShown, isThumbnailsPreviewHover = _b.isThumbnailsPreviewHover, width = _b.width, height = _b.height, isContentFocusable = _b.isContentFocusable, onClick = _b.onClick, isShareOverlayOpen = _b.isShareOverlayOpen;
        if (!videoItem) {
            return null;
        }
        var containerClassNames = classnames(styles.container, (_a = {},
            _a[styles['always-hidden']] = isOverlayNeverShown,
            _a[styles['always-visible']] = isOverlayAlwaysShown,
            _a[styles['preview-hover']] = isThumbnailsPreviewHover,
            _a[styles['share-overlay-open']] = isShareOverlayOpen,
            _a));
        return (React.createElement("section", { className: classnames(styles.wrapper, className), onClick: onClick, onKeyDown: memoizedPartial(callOnEnterPressExactOnElement, onClick), "data-hook": "video-list-thumb-wrapper", style: { height: height, width: width }, "aria-hidden": !isContentFocusable, tabIndex: isContentFocusable ? 0 : -1 },
            React.createElement(VideoCover, { videoItem: videoItem, breakpoints: [{ min: 0, width: width, height: height }] }),
            React.createElement("div", { "data-hook": "player-overlay", className: containerClassNames },
                React.createElement("div", { className: styles.content },
                    React.createElement("div", { className: styles['top-content'] },
                        this.renderTitle(),
                        React.createElement("div", { className: styles['buttons-wrapper'] },
                            this.renderPlayButton(),
                            this.renderPaidAccessButton(),
                            this.renderLiveLabel()))))));
    };
    VideoThumbnailOverlay.propTypes = {
        channelData: PropTypes.object.isRequired,
        videoItem: PropTypes.object.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        isThumbnailsPreviewHover: PropTypes.bool.isRequired,
        isOverlayAlwaysShown: PropTypes.bool.isRequired,
        isOverlayNeverShown: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        onPlayRequest: PropTypes.func.isRequired,
        onPlayMemberOnlyRequest: PropTypes.func.isRequired,
        onPlayWithoutPreviewRequest: PropTypes.func.isRequired,
        onSubscriptionRequest: PropTypes.func.isRequired,
        onPurchaseRequest: PropTypes.func.isRequired,
        className: PropTypes.string,
        currentSiteUser: PropTypes.object,
        playButtonConfig: PropTypes.object,
        paidAccessButtonConfig: PropTypes.object,
        withFallbackColor: PropTypes.bool,
        isContentFocusable: PropTypes.bool,
        isShareOverlayOpen: PropTypes.bool,
    };
    VideoThumbnailOverlay.defaultProps = {
        isContentFocusable: true,
        showChannelCover: false,
        onPlayRequest: _.noop,
        onPlayMemberOnlyRequest: _.noop,
        onPlayWithoutPreviewRequest: _.noop,
        onPurchaseRequest: _.noop,
        onSubscriptionRequest: _.noop,
    };
    VideoThumbnailOverlay = __decorate([
        connect(function () {
            var getPlayButtonConfig = createGetPlayButtonConfig();
            var getPaidAccessButtonConfig = createGetPaidAccessButtonConfig();
            return function (state, ownProps) { return ({
                playButtonConfig: getPlayButtonConfig(state, ownProps),
                paidAccessButtonConfig: getPaidAccessButtonConfig(state, ownProps),
                isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
                isOverlayAlwaysShown: isVideosListInfoAlwaysShown(state),
                isOverlayNeverShown: isVideosListInfoNeverShown(state),
                isShareOverlayOpen: isShareOverlayOpen(state),
            }); };
        }),
        withStyles(styles)
    ], VideoThumbnailOverlay);
    return VideoThumbnailOverlay;
}(React.Component));
export default VideoThumbnailOverlay;
