import _ from 'lodash';
import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { canShowChannelCategories as isCategoriesSettingEnabled } from '../../shared/selectors/app-settings';
import { isYouTubeFeed } from '../../shared/utils/channel-helpers';
export var getChannelCategories = createSelector(getChannelForWidget, function (channel) { return _.get(channel, 'statsInfo.categories', []); });
export var getChannelCategoryOptions = createSelector(getChannelCategories, function (categories) { return _.map(categories, function (_a) {
    var value = _a.value;
    return ({ value: value, label: value });
}); });
export var canShowChannelCategories = createSelector(isCategoriesSettingEnabled, getChannelForWidget, function (isEnabled, channel) { return isEnabled && !isYouTubeFeed(channel); });
