import { __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getWidth } from '../../../shared/utils/node';
var Measure = /** @class */ (function (_super) {
    __extends(Measure, _super);
    function Measure(props) {
        var _this = _super.call(this, props) || this;
        _this._node = null;
        return _this;
    }
    Measure.prototype.componentDidMount = function () {
        this.measure();
    };
    Measure.prototype.componentDidUpdate = function () {
        if (this.props.shouldMeasure) {
            this.measure();
        }
    };
    Measure.prototype.measure = function () {
        if (!this.props.shouldMeasure) {
            return;
        }
        this.props.onMeasure({ width: getWidth(this._node) });
    };
    Measure.prototype.render = function () {
        var _this = this;
        var children = this.props.children;
        return React.cloneElement(React.Children.only(children), {
            ref: function (ref) { return (_this._node = ref); },
        });
    };
    Measure.propTypes = {
        shouldMeasure: PropTypes.bool,
        onMeasure: PropTypes.func,
        children: PropTypes.element,
    };
    Measure.defaultProps = {
        shouldMeasure: true,
        onMeasure: _.noop,
    };
    return Measure;
}(React.Component));
export default Measure;
