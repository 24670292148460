import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import formatMessageWithPrice from '../../utils/format-message-with-price';
import { getFullAccessActionName, FULL_ACCESS_ACTION_NAMES, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { DEFAULT_TRANSLATIONS } from '../../utils/buy-button-texts/buy-button-texts';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
var PURCHASE_ACTION_NAMES = _.keys(DEFAULT_TRANSLATIONS);
/**
 * Is purchase video button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @return {Boolean}
 */
export function isVisible(_a) {
    var channel = _a.channel, video = _a.video;
    return _.includes(PURCHASE_ACTION_NAMES, getFullAccessActionName(channel, video));
}
/**
 * Purchase video button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.translations {Object} see DEFAULT_TRANSLATIONS
 * @return {React.Component}
 */
export default function renderPurchaseButtonText(_a) {
    var channel = _a.channel, video = _a.video, currentSiteUser = _a.currentSiteUser, _b = _a.translations, translations = _b === void 0 ? DEFAULT_TRANSLATIONS : _b, isVerticalLayoutMobile = _a.isVerticalLayoutMobile;
    switch (getFullAccessActionName(channel, video, currentSiteUser)) {
        case FULL_ACCESS_ACTION_NAMES.BUY:
            return formatMessageWithPrice(translations[FULL_ACCESS_ACTION_NAMES.BUY], dealInfoHelpers.getSalePriceInfo(video.dealInfo));
        case FULL_ACCESS_ACTION_NAMES.RENT:
            return formatMessageWithPrice(translations[FULL_ACCESS_ACTION_NAMES.RENT], dealInfoHelpers.getRentPriceInfo(video.dealInfo));
        case FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT:
            return formatMessageWithPrice(translations[FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT], dealInfoHelpers.getMinPriceInfo(video.dealInfo));
        case FULL_ACCESS_ACTION_NAMES.PRICING_PLAN:
            if (isVerticalLayoutMobile) {
                return i18n.t(translations[FULL_ACCESS_ACTION_NAMES.PRICING_PLAN]);
            }
            return null;
        case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
            if (isVerticalLayoutMobile) {
                return (React.createElement(FormattedMessage, { id: translations[FULL_ACCESS_ACTION_NAMES.SUBSCRIBE] }));
            }
            return null;
        default:
            return null;
    }
}
